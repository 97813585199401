import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { formatFileSize } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileIcon from 'src/views/components/FileIcon'
import Loader from 'src/views/components/Loader'
import UserPhoto from 'src/views/components/UserPhoto'

export default function StudentPortfolioWorkPage(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()

  return (
    <>
      <Helmet title={t('portfolio:portfolio')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )

  function PageContent(): JSX.Element | null {
    const headers = useAuthenticatedHeaders()
    const params = useParams()
    const { pathname } = useLocation()

    const { data: work } = useApi({
      endpoint: Api.getStudentPortfolioSectionWork,
      params: React.useMemo(
        () => ({
          headers,
          args: {
            SectionId: params.sectionId!,
            id: params.workId!,
          },
        }),
        [headers, params.sectionId, params.workId]
      ),
      suspense: false,
    })

    const breadcrumbsItems = [
      { page: `${t('portfolio:portfolio')}`, path: `/${locale}/student/portfolio` },
      { page: `${t('portfolio:work')}`, path: pathname },
    ]

    return (
      <Section title={t('portfolio:portfolio_work')} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
        <div className="text-primaryTextColor">
          {work != null && (
            <>
              <div className="mb-6">
                <p className="mb-5 text-headline">{t('portfolio:work') + ` ${work.id}`}</p>
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_description') + `: `}
                </h2>
                {work.text != null ? <span dangerouslySetInnerHTML={{ __html: work.text }} /> : <span> - </span>}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_competencies') + `: `}
                </h2>
                {work.competencies !== null ? (
                  work.competencies?.map((competency, index) => (
                    <span key={competency.id}> {(index !== 0 ? ', ' : '') + competency.name}</span>
                  ))
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:mentor') + `: `}
                </h2>
                {work.mentor != null ? (
                  <p className="text-title">
                    <UserPhoto className="scale-[0.7]" user={work.mentor} />
                    <Link
                      to={`/${locale}/users/${work.mentor?.uid}`}
                      className="ml-2 whitespace-nowrap align-middle !no-underline hover:underline"
                      data-tooltip-id={`${work.id}-lecturer`}
                    >
                      <span className="ml-2">{work.mentor?.fullName}</span>
                    </Link>
                  </p>
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_status') + `: `}
                </h2>
                {work.status != null ? (
                  <div>
                    {work.status === 'STATUS_PENDING' && <span>{t('portfolio:status_pending')}</span>}
                    {work.status === 'STATUS_EVALUATED' ? <span>{t('portfolio:status_evaluated')}</span> : null}
                    {work.status === 'STATUS_REJECTED' && <span>{t('portfolio:status_rejected')}</span>}
                  </div>
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('file:attached_files') + `: `}
                </h2>
                {work.mediaFiles?.length === 0 ? (
                  <p className="syllabus-content text-title">
                    {work.mediaFiles !== null && work.mediaFiles.length > 0 ? (
                      <div className="row-auto my-3">
                        <div className="col-auto mb-2">
                          {work.mediaFiles.map((file) =>
                            work.mediaFiles != null && work.mediaFiles.length > 0 ? (
                              <div key={file.id} className="mr-4 flex">
                                <FileIcon type={file.extension} />{' '}
                                <a
                                  href={`/${locale}/media-files/${file.id}/download`}
                                  className="mx-2 dark:text-white"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {file.originalName}
                                </a>
                                <span className="text-sm text-captionColor">({formatFileSize(file.size)})</span>
                              </div>
                            ) : (
                              <span key={file.id}> - </span>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <span> - </span>
                    )}
                  </p>
                ) : null}
              </div>
              <div className="mb-6">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_feedback') + `: `}
                </h2>
                {work.feedback != null ? (
                  <p className="syllabus-content text-title">{work.feedback}</p>
                ) : (
                  <span> - </span>
                )}
              </div>
            </>
          )}
        </div>
      </Section>
    )
  }
}
