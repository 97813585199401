export function CourseUserIcon(): JSX.Element {
  return (
    <svg id="course_on_icon" width="14" height="12" viewBox="0 0 14 12" data-testid="courseUserIcon">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3627"
            data-name="Rectangle 3627"
            width="12"
            height="6"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <circle id="Ellipse_377" data-name="Ellipse 377" cx="3" cy="3" r="3" transform="translate(3)" fill="#9ed995" />
      <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(0 6)">
        <circle id="Ellipse_378" data-name="Ellipse 378" cx="6" cy="6" r="6" fill="#9ed995" />
      </g>
      <rect
        id="Rectangle_4335"
        data-name="Rectangle 4335"
        width="5"
        height="1"
        transform="translate(9 5)"
        fill="#9ed995"
      />
      <rect
        id="Rectangle_4336"
        data-name="Rectangle 4336"
        width="5"
        height="1"
        transform="translate(12 3) rotate(90)"
        fill="#9ed995"
      />
    </svg>
  )
}
