import { format } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'

import { formatDatelocale } from 'src/helpers/fns'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import SummaryRow from './components/SummaryRow'

async function PageContentData(
  headers: Api.getStudentResumeEducationsParams
): Promise<readonly [Api.getStudentResumeEducationsOk, Api.getStudentResumeUniversitiesOk]> {
  const data1 = Api.getStudentResumeEducations(headers)
  const data2 = Api.getStudentResumeUniversities(headers)

  return await Promise.all([data1, data2])
}

export default function Educations({ countries }: EducationsProps): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const {
    data: [educations, universities],
  } = useApi({
    endpoint: PageContentData,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  if (educations.length === 0) return null
  return (
    <div className="my-2 flex w-full flex-col">
      <SummaryRow title={t('resume:education')} />
      {educations.map((education, index) => (
        <SuspenseWrapper key={index}>
          <Education
            education={education}
            university={universities.find((uni) => uni.id === education.universityId)!.name}
            country={countries.find((country) => country.id === education.countryId)!.name}
          />
        </SuspenseWrapper>
      ))}
    </div>
  )
}

function Education({ education, university, country }: EducationProps): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const { data: cities } = useApi({
    endpoint: Api.getStudentResumeCities,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            countryId: education.countryId,
          },
        },
      }),
      [education.countryId, headers]
    ),
  })

  return (
    <SummaryRow
      title={
        <>
          {format(formatDatelocale(education.startDate) as Date, 'd MMMM yyyy', { locale: dateLocale })} {' - '}
          {education.endDate != null && education.endDate !== '' ? (
            format(formatDatelocale(education.endDate) as Date, 'd MMMM yyyy', { locale: dateLocale })
          ) : (
            <span className="whitespace-nowrap">{t('common:current')}</span>
          )}
        </>
      }
      content={
        <>
          <h4>{university}</h4>
          <h4>
            {country},{cities.find((value) => value.id === education.cityId)?.name}
          </h4>
          <h4>{education.qualification}</h4>
        </>
      }
    />
  )
}

interface EducationsProps {
  readonly countries: Api.getCountriesOk
}

interface EducationProps {
  readonly education: Api.ResumeEducation
  readonly university: string
  readonly country: string
}
