import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray, useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import { DownloadIcon } from 'src/assets/icons/customIcons/Download'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatDate, formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import Checkbox from 'src/views/components/forms/formik/Checkbox'
import DateInput from 'src/views/components/forms/formik/DateInput'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Swal from 'sweetalert2'
import SwitchButton from './SwitchButton'

export default function StudentResumeTrainingsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:trainings_seminars_workshops')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="trainings" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

interface FormikValues extends Api.ResumeTraining {
  readonly localFile: {
    readonly id: string | null
    readonly uploading: boolean
  }
}

async function PageContentData(
  headers: Api.getStudentResumeTrainingsParams
): Promise<readonly [Api.getStudentResumeTrainingsOk, Api.getCountriesOk]> {
  const data1 = Api.getStudentResumeTrainings(headers)
  const data2 = Api.getCountries(headers)

  return await Promise.all([data1, data2])
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { data, mutate } = useApi({
    endpoint: PageContentData,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  const form = data?.[0]
  const countries = data?.[1]

  const t = useTranslatable()

  const onSubmit = React.useCallback(
    async (values: { data: readonly FormikValues[] }) => {
      await Api.postStudentResumeTrainings({
        headers,
        body: {
          data: values.data.map((training) => ({
            startDate: formatDate(training.startDate)!,
            endDate: formatDate(training.endDate),
            isCurrent: training.isCurrent,
            name: training.name,
            companyName: training.companyName,
            skills: training.skills,
            countryId: training.countryId,
            cityId: training.cityId,
            schedule: [
              ...training.schedule.map((time) => ({
                startHour: time.startHour!,
                endHour: time.endHour!,
              })),
            ],
            document: {
              type: training.document.type != null ? Api.ResumeDocumentTypeEncoder(training.document.type) : null,
              fileUid:
                training.localFile != null
                  ? training.localFile.id
                  : training.trainingFile != null
                    ? training.trainingFile.id
                    : null,
            },
          })),
        },
      })
      toast.success(t('person:profile_updated_successfully'))
      mutate()
    },
    [headers, mutate, t]
  )

  async function handleDelete(index: number, remove: (index: number) => void): Promise<void> {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      remove(index)
    }
  }

  return (
    <Form
      initialValues={{
        data: form.map((entity) => ({
          ...entity,
          localFile: {
            id: null,
            uploading: false,
          },
        })),
      }}
      onSubmit={onSubmit}
      classNames={{ form: 'w-full' }}
      isConfirmable
      enableReinitialize
    >
      <FormError />
      <FieldArray name="data">
        {({ remove, push, form }: any) => (
          <>
            {form.values.data.map((item: Props['item'], index: number) => (
              <div key={index} className="my-2 w-full rounded-card bg-card p-6 pt-4">
                <div className="flex w-full justify-end" title={t('common:delete')}>
                  <button
                    onClick={() => void handleDelete(index, remove)}
                    type="button"
                    className="rounded-full p-2 hover:bg-hover-icon"
                  >
                    <DeleteIcon />
                  </button>
                </div>
                <FormEntity item={item} index={index} countries={countries} />
              </div>
            ))}
            <Button
              variant="blue"
              type="button"
              className="my-4 w-full"
              onClick={() => push(newTraining)}
              disabled={false}
            >
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
          </>
        )}
      </FieldArray>
      <div className="flex w-full justify-end rounded border-0 border-primaryGray">
        <FormSubmit
          classNames={{
            root: 'flex justify-end',
          }}
        />
      </div>
    </Form>
  )
}

const newTraining = {
  name: '',
  companyName: '',
  isCurrent: false,
  startDate: null,
  endDate: null,
  skills: '',
  countryId: '',
  cityId: '',
  schedule: Array(7).fill({
    startHour: '',
    endHour: '',
  }),
  document: {
    name: null,
    type: null,
    url: null,
  },
  trainingFile: null,
  localFile: {
    id: null,
    uploading: false,
  },
}

interface Props {
  item: FormikValues
  index: number
  countries: Api.getCountriesOk | null
}

function FormEntity({ item, index, countries }: Props): JSX.Element {
  const t = useTranslatable()
  const [disabled, setDisabled] = React.useState(false)
  const formik = useFormikContext<{ data: Array<typeof newTraining> }>()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()

  const { data: cities, isValidating } = useApi({
    endpoint: Api.getStudentResumeCities,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            countryId: item.countryId,
          },
        },
      }),
      [headers, item.countryId]
    ),
    shouldFetch: item.countryId != null && item.countryId.length !== 0,
    suspense: false,
  })

  const handleDelete = React.useCallback(async (): Promise<void> => {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      void formik.setFieldValue(`data.${index}.localFile`, {
        id: null,
        uploading: false,
      })
      void formik.setFieldValue(`data.${index}.trainingFile`, null)
    }
  }, [formik, index, t])

  return (
    <React.Fragment key={index}>
      <div className="my-1 flex flex-wrap items-start">
        <div className="flex w-full flex-wrap justify-between">
          <div className="mb-3 w-full md:w-[49%]">
            <DateInput
              labelAnimation
              required
              name={`data.${index}.startDate`}
              label={t('common:start_date')}
              maxDate={formatDatelocale(item.endDate)}
            />
          </div>
          <div className="flex w-full flex-wrap md:w-[49%] md:!flex-nowrap">
            <DateInput
              labelAnimation
              required={!item.isCurrent}
              name={`data.${index}.endDate`}
              label={t('common:end_date')}
              minDate={formatDatelocale(item.startDate)}
              disabled={item.isCurrent}
            />
            <div className="ml-1 mt-2 md:!ml-3 md:!mt-3">
              <Checkbox name={`data.${index}.isCurrent`} label={t('common:current')} />
            </div>
          </div>
        </div>
      </div>
      <div className="my-1 flex flex-col items-start">
        <h4 className="mb-2 font-bold text-primaryTextColor">{t('calendar:days')}</h4>
        <div className="flex w-full flex-wrap justify-between">
          {item.schedule.map((day, i) => (
            <div className="flex w-[30%] flex-col sm:w-[13%]" key={i}>
              <p className="mb-[10px] mt-1 text-primaryTextColor">
                {i === 0 && t('calendar:monday')}
                {i === 1 && t('calendar:tuesday')}
                {i === 2 && t('calendar:wednesday')}
                {i === 3 && t('calendar:thursday')}
                {i === 4 && t('calendar:friday')}
                {i === 5 && t('calendar:saturday')}
                {i === 6 && t('calendar:sunday')}
              </p>
              <TextInput name={`data.${index}.schedule.${i}.startHour`} type="time" />
              <TextInput name={`data.${index}.schedule.${i}.endHour`} type="time" />
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <TextInput
          name={`data.${index}.name`}
          placeholder={t('resume:traning_workshop_seminar_name')}
          type="text"
          searchField
          required
        />
      </div>
      <div className="mb-3 w-full">
        <TextareaInput
          name={`data.${index}.skills`}
          label={t('resume:enter_gained_skills')}
          labelAnimation
          rows={3}
          required
        />
      </div>
      <div className="flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[100px] md:w-[32%] xl:!mb-3">
          <TextInput
            name={`data.${index}.companyName`}
            placeholder={t('resume:company_name')}
            type="text"
            searchField
            required
          />
        </div>
        <div className="w-full min-w-[100px] md:w-[32%]">
          <SelectInput
            name={`data.${index}.countryId`}
            options={countries?.map((item) => ({ value: item.id, label: item.name })) ?? []}
            label={t('common:country')}
            labelAnimation
            required
          />
        </div>
        <div className="w-full min-w-[100px] md:w-[32%]">
          <SelectInput
            name={`data.${index}.cityId`}
            options={cities?.map((item) => ({ value: item.id, label: item.name })) ?? []}
            label={t('common:city')}
            disabled={isValidating}
            isLoading={isValidating}
            labelAnimation
            required
          />
        </div>
      </div>

      <div className="flex w-full flex-wrap items-start justify-between">
        <div className="mb-3 mr-0 w-full min-w-[100px] text-primaryTextColor md:mb-0 md:mr-2 md:w-[49%]">
          <SelectInput
            name={`data.${index}.document.type`}
            options={Api.ResumeDocumentTypeValues?.map((item) => ({ value: item, label: item })) ?? []}
            label={t('resume:diploma_certificate')}
            labelAnimation
          />
        </div>
        <div className="flex w-full min-w-[100px] flex-wrap justify-start text-primaryTextColor md:w-[49%] md:justify-end">
          <FileInput
            disabled={disabled}
            fileText={item.trainingFile?.id != null ? t('file:change_file') : undefined}
            config={UploadConfigMap.studentResumeTrainings}
            label={t('resume:diploma_certificate_scan')}
            onChange={(files) => {
              const newFileId =
                files[0]?.remote.file != null && files[0].remote.status === 'DONE'
                  ? files[0].remote.file.id
                  : item.trainingFile?.id
              if (files[0]?.remote.status === 'IN-PROGRESS') {
                setDisabled(true)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: null,
                  uploading: true,
                })
              }
              if (files[0]?.remote.status === 'FAILED') {
                setDisabled(true)
              }
              if (item.trainingFile?.id !== newFileId) {
                setDisabled(false)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: newFileId,
                  uploading: false,
                })
              }
              if (files.length === 0) {
                setDisabled(false)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: null,
                  uploading: false,
                })
              }
            }}
          >
            {item.trainingFile != null ? (
              <div className="flex items-center">
                <a
                  href={`/${locale}/media-files/${item.trainingFile.id}/download`}
                  rel="noreferrer"
                  target="_blank"
                  style={{ zIndex: 0 }}
                  title={t('common:download')}
                >
                  <Button type="button" variant="blue" className="flex items-center">
                    <DownloadIcon color="white" />
                  </Button>
                </a>
                <Button
                  title={t('common:delete')}
                  variant="red"
                  className="ml-1 flex items-center"
                  type="button"
                  onClick={() => void handleDelete()}
                  style={{ zIndex: 0 }}
                >
                  <DeleteIcon color="white" />
                </Button>
              </div>
            ) : undefined}
          </FileInput>
        </div>
      </div>
    </React.Fragment>
  )
}
