export default function DownloadIcon(): JSX.Element {
  return (
    <svg
      id="download_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-testid="downloadIcon"
    >
      <rect id="Rectangle_3629" data-name="Rectangle 3629" width="24" height="24" opacity="0" />
      <rect
        id="Rectangle_3630"
        data-name="Rectangle 3630"
        width="22"
        height="22"
        transform="translate(1 1)"
        opacity="0"
      />
      <rect
        id="Rectangle_4255"
        data-name="Rectangle 4255"
        width="1"
        height="18"
        transform="translate(11.5 1)"
        fill="gray"
      />
      <rect
        id="Rectangle_4256"
        data-name="Rectangle 4256"
        width="1"
        height="11"
        transform="translate(12.707 18.889) rotate(135)"
        fill="gray"
      />
      <rect
        id="Rectangle_4257"
        data-name="Rectangle 4257"
        width="1"
        height="11"
        transform="translate(12 19.596) rotate(-135)"
        fill="gray"
      />
      <rect
        id="Rectangle_4258"
        data-name="Rectangle 4258"
        width="18"
        height="1"
        transform="translate(3 22)"
        fill="gray"
      />
    </svg>
  )
}
