export default function TypeIcon(): JSX.Element {
  return (
    <svg id="type_icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Rectangle_4284" data-name="Rectangle 4284" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
        <rect width="12" height="12" stroke="none" />
        <rect x="0.5" y="0.5" width="11" height="11" fill="none" />
      </g>
      <path
        id="Subtraction_234"
        data-name="Subtraction 234"
        d="M-9693-243h-10a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h2.5v1h-2.5v6h10v-4.5h.5v-2.368a1,1,0,0,1,.5.867v6A1,1,0,0,1-9693-243Z"
        transform="translate(9704 253)"
        fill="gray"
      />
      <path
        id="Path_25081"
        data-name="Path 25081"
        d="M1.721,2,1.387,3H4.613L4.279,2H1.721M1,1H5L6,4H0Z"
        transform="translate(3 8)"
        fill="gray"
      />
      <g id="Union_34" data-name="Union 34" transform="translate(9709 251)" fill="none">
        <path
          d="M-9704.242-244.5h-1.259a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h8a.5.5,0,0,1,.5.5v5a.5.5,0,0,1-.5.5h-4.324l-2.419,2.012Z"
          stroke="none"
        />
        <path
          d="M -9703.2421875 -244.6199798583984 L -9702.1845703125 -245.4995269775391 L -9698 -245.4995269775391 L -9698 -249.4990997314453 L -9705.0009765625 -249.4990997314453 L -9705.0009765625 -245.4995269775391 L -9703.2421875 -245.4995269775391 L -9703.2421875 -244.6199798583984 M -9704.2421875 -242.4874725341797 L -9704.2421875 -244.4995269775391 L -9705.5009765625 -244.4995269775391 C -9705.7763671875 -244.4995269775391 -9706.0009765625 -244.7236175537109 -9706.0009765625 -244.9988708496094 L -9706.0009765625 -249.999755859375 C -9706.0009765625 -250.2750091552734 -9705.7763671875 -250.4990997314453 -9705.5009765625 -250.4990997314453 L -9697.4990234375 -250.4990997314453 C -9697.2236328125 -250.4990997314453 -9697 -250.2750091552734 -9697 -249.999755859375 L -9697 -244.9988708496094 C -9697 -244.7236175537109 -9697.2236328125 -244.4995269775391 -9697.4990234375 -244.4995269775391 L -9701.8232421875 -244.4995269775391 L -9704.2421875 -242.4874725341797 Z"
          stroke="none"
          fill="gray"
        />
      </g>
      <circle
        id="Ellipse_1003"
        data-name="Ellipse 1003"
        cx="0.5"
        cy="0.5"
        r="0.5"
        transform="translate(7 3)"
        fill="gray"
      />
      <circle
        id="Ellipse_1004"
        data-name="Ellipse 1004"
        cx="0.5"
        cy="0.5"
        r="0.5"
        transform="translate(9 3)"
        fill="gray"
      />
      <circle
        id="Ellipse_1005"
        data-name="Ellipse 1005"
        cx="0.5"
        cy="0.5"
        r="0.5"
        transform="translate(5 3)"
        fill="gray"
      />
      <rect
        id="Rectangle_4288"
        data-name="Rectangle 4288"
        width="10"
        height="1"
        transform="translate(1 11)"
        fill="gray"
      />
    </svg>
  )
}
