import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { t as tt } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { AssignmentDown } from 'src/assets/icons/customIcons/AssignmentDown'
import { AssignmentUp } from 'src/assets/icons/customIcons/AssignmentUp'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { CourseGeneralIcon } from 'src/assets/icons/customIcons/course-icons/CourseGeneral'
import { CourseUserIcon } from 'src/assets/icons/customIcons/course-icons/CourseUser'
import { shortenString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import { useTheme } from 'src/state/providers/Theme'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentCoursesGeneralPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses_general_module')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const [inFlight, setInFlight] = React.useState(0)
  const theme = useTheme()

  const choices = useApi({
    endpoint: Api.getStudentAvailableGeneralChoices,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const courses = useApi({
    endpoint: Api.getStudentAvailableGeneralCourses,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  interface CourseProps {
    readonly groupName: string
    readonly courses: Api.Course[]
  }

  const [Groups_, SetGroups] = useState<CourseProps[]>([])

  useEffect(() => {
    async function newData(): Promise<void> {
      const courseGroupMap = new Map()

      for (const course of courses.data.courses) {
        const groupName = course.groupName

        if (!courseGroupMap.has(groupName)) {
          courseGroupMap.set(groupName, [])
        }
        courseGroupMap.get(groupName).push(course)
      }
      const groups_ = Array.from(courseGroupMap.entries()).map(([groupName, courses]) => {
        return { groupName, courses }
      })

      SetGroups(groups_)
    }
    void newData()
  }, [courses.data.courses])

  const ASSIGN_TO_PROGRAM = React.useCallback(
    async (params: { uid: string; programId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesProgramsAssign({
          headers,
          args: {
            uid: params.uid,
            program_id: params.programId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, choices, t]
  )

  const REMOVE_ASSIGNMENT = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesRemoveAssignment({
          headers,
          args: {
            uid: choiceId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((n) => n - 1)
    },
    [headers, choices, t]
  )

  const ASSIGN_TO_FREE_CREDITS = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesFreeCreditsAssign({
          headers,
          args: {
            uid: choiceId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((n) => n - 1)
    },
    [headers, choices, t]
  )

  if (userState.data == null) return null

  return (
    <div className="w-full px-0">
      <div className="flex text-primaryTextColor">
        <div className="mr-3 flex items-center">
          <CourseGeneralIcon />
          <span className="ml-2">
            {t('course:general_module')} {choices.data.creditsAll}
          </span>
        </div>
      </div>

      <div className="flex text-primaryTextColor">
        <div className="mr-3 flex items-center">
          <CourseCompletedIcon />
          <span className="ml-2">{t('course:successfully_finished')}</span>
        </div>
        <div className="mr-3 flex items-center">
          <CourseChosenIcon />
          <span className="ml-2">{tt('course:chosen_credits', { credits: choices.data.creditsCurrent })}</span>
        </div>
        <div className="mr-3 flex items-center">
          <CourseFailedIcon />
          <span className="ml-2">{t('course:failed')}</span>
        </div>
      </div>

      <div>
        <Table.Table className="mt-3">
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th />
              <Table.Th>{t('program:program_code')}</Table.Th>
              <Table.Th>{t('course:course_name')}</Table.Th>
              <Table.Th>{t('common:cred_type')}</Table.Th>
              <Table.Th>{t('common:score')}</Table.Th>
              <Table.Th>{t('common:gain_cred')}</Table.Th>
              <Table.Th>{t('course:credit')}</Table.Th>
              <Table.Th>{t('course:belong')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {choices.data.choices.map((choice) => (
              <Table.Tr key={choice.id} data-testid={`course/${choice.id}`}>
                <Table.Td>
                  <div data-tooltip-id={`${choice.id}-isGeneralTooltip`}>
                    <CourseGeneralIcon />
                  </div>
                  <Tooltip
                    id={`${choice.id}-isGeneralTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    <p className="mb-0">{t('course:general_course')}</p>
                  </Tooltip>
                </Table.Td>
                <Table.Td className="text-center align-middle">
                  {choice.status === 'PASSED' && (
                    <div className="text-primaryGreen" data-tooltip-id={`${choice.id}-statusTooltip`}>
                      <CourseCompletedIcon />
                    </div>
                  )}
                  {choice.status === 'CURRENT' && (
                    <div className="text-primaryYellow" data-tooltip-id={`${choice.id}-statusTooltip`}>
                      <CourseChosenIcon />
                    </div>
                  )}
                  {choice.status === 'FAILED' && (
                    <div className="text-lightSecondaryWarning" data-tooltip-id={`${choice.id}-statusTooltip`}>
                      <CourseFailedIcon />
                    </div>
                  )}
                  <Tooltip
                    id={`${choice.id}-statusTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    <p className="mb-0">
                      {choice.status === 'PASSED' && t('course:successfully_finished')}
                      {choice.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                      {choice.status === 'FAILED' && t('course:failed')}
                    </p>
                  </Tooltip>
                </Table.Td>
                <Table.Td className="whitespace-nowrap align-middle text-sm" data-testid="courseCode">
                  {shortenString(choice.courseCode, 50)}
                </Table.Td>
                <Table.Td className="w-full align-middle">
                  <Link
                    className="text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                    to={`/${locale}/student/courses/${choice.course!.id}/groups`}
                    data-testid="courseName"
                  >
                    {choice.courseName}
                  </Link>
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="creditType">
                  {choice.creditType}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="latScore">
                  {choice.isLatChoice ? choice.latScore : choice.score.toFixed(2)}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="credits">
                  {choice.isLatChoice && choice.courseCredits === 0 ? '' : choice.credits}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="courseCredits">
                  {choice.courseCredits}
                </Table.Td>
                <Table.Td className="rounded-r-lg text-center">
                  {choice.assignmentStatuses?.map((status, index) => (
                    <React.Fragment key={index}>
                      {status.action === 'ASSIGN_TO_FREE_CREDITS' && (
                        <Button
                          dataTip
                          dataFor={`${choice.id}-assignmentTooltip-${index}`}
                          isLoading={inFlight > 0}
                          onClick={() => void ASSIGN_TO_FREE_CREDITS(choice.id)}
                          label={<AssignmentUp />}
                          index={index}
                          courseId={choice.id}
                          tooltipText={t('course:assign_to_free_credits')}
                          data-testid="assignToFreeCredits"
                        />
                      )}
                      {status.action === 'ASSIGN_TO_PROGRAM' && (
                        <Button
                          dataTip
                          dataFor={`${choice.id}-assignmentTooltip-${index}`}
                          isLoading={inFlight > 0}
                          onClick={async () => void ASSIGN_TO_PROGRAM({ uid: choice.id, programId: status.programId! })}
                          label={
                            status.programId === '0' || status.programId === '-1' ? (
                              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} />
                            ) : (
                              <AssignmentUp />
                            )
                          }
                          index={index}
                          courseId={choice.id}
                          tooltipText={tt('uncategorized:assign_to', { program: status.programName })}
                          data-testid="assignToProgram"
                        />
                      )}
                      {status.action === 'REMOVE_ASSIGNMENT' && (
                        <Button
                          dataTip
                          dataFor={`${choice.id}-assignmentTooltip-${index}`}
                          isLoading={inFlight > 0}
                          onClick={() => void REMOVE_ASSIGNMENT(choice.id)}
                          label={<AssignmentDown />}
                          index={index}
                          courseId={choice.id}
                          tooltipText={t('course:remove_assignment')}
                          data-testid="removeAssignment"
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.Table>
      </div>

      <div>
        <h3 className="mb-3 text-headline xxs:text-[20px] xs:text-[20px]">{t('course:other_courses')}</h3>
        <div className="mb-3 flex items-center">
          <CourseUserIcon />
          <span className="ml-2 text-primaryTextColor">{t('course:course_offered_in_current_semester')}</span>
        </div>
      </div>

      <div>
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th />
              <Table.Th>{t('common:unique')}</Table.Th>
              <Table.Th>{t('program:program_code')}</Table.Th>
              <Table.Th>{t('course:course_name')}</Table.Th>
              <Table.Th>{t('common:credits')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {Groups_.map((group, key) => (
            <Table.Tbody key={key} data-testid={`group/${group.groupName}`}>
              {group.groupName.length > 0 && (
                <Table.Tr>
                  <Table.Td colSpan={6} className="!py-[10px]">
                    <div
                      className="rounded bg-[#E5E5E5] px-[10px] py-[15px] text-center text-bodyText dark:bg-[#25262A]"
                      data-testid={`otherCourse/${group.groupName}`}
                    >
                      {group.groupName}
                    </div>
                  </Table.Td>
                </Table.Tr>
              )}
              {group.courses.map((course) => (
                <Table.Tr key={course.id} data-testid={`otherCourse/${course.id}`}>
                  <Table.Td>
                    <div data-tooltip-id={`${course.id}-isGeneralTooltip`}>
                      <CourseGeneralIcon />
                    </div>
                    <Tooltip
                      id={`${course.id}-isGeneralTooltip`}
                      place="top"
                      variant={theme === 'dark' ? 'dark' : 'light'}
                      className="mb-1 p-1"
                    >
                      <p className="mb-0">{t('course:general_course')}</p>
                    </Tooltip>
                  </Table.Td>
                  <Table.Td className="text-center">
                    {course.isEnabledForChoose && (
                      <>
                        <div data-tooltip-id={`${course.id}-courseOffered`}>
                          <CourseUserIcon />
                        </div>
                        <Tooltip
                          id={`${course.id}-courseOffered`}
                          place="top"
                          variant={theme === 'dark' ? 'dark' : 'light'}
                          className="mb-1 p-1"
                        >
                          <p className="mb-0">{t('course:course_offered_in_current_semester')}</p>
                        </Tooltip>
                      </>
                    )}
                  </Table.Td>
                  <Table.Td className="text-center" data-testid="courseCode">
                    {course.code}
                  </Table.Td>
                  <Table.Td data-testid="programCode">{shortenString(course.programCode, 50)}</Table.Td>
                  <Table.Td className="w-full align-middle">
                    <Link
                      className="text-primaryBlueLink hover:underline"
                      to={`/${locale}/student/courses/${course.id}/groups`}
                      data-testid="courseName"
                    >
                      {course.name}
                    </Link>
                  </Table.Td>
                  <Table.Td className="rounded-r-lg !text-center">{course.credits}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ))}
        </Table.Table>
      </div>
    </div>
  )
}

interface ButtonProps {
  readonly onClick: any
  readonly label: string | React.ReactElement
  readonly isLoading: boolean
  readonly dataTip: boolean
  readonly dataFor?: string
  readonly index: number
  readonly courseId: string
  readonly tooltipText: string
}

function Button({
  onClick,
  label,
  isLoading,
  dataTip = false,
  dataFor,
  index,
  courseId,
  tooltipText,
  ...props
}: ButtonProps): JSX.Element {
  const theme = useTheme()

  return (
    <>
      <button
        data-tooltip-id={dataTip ? dataFor : undefined}
        className={classnames('mb-1 mr-1 p-0', {
          disabled: isLoading,
        })}
        style={{ width: '20px', height: '20px' }}
        type="button"
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={FreeSolidSvgIcons.faSpinner} spin className="m-auto flex text-sm" />
        ) : (
          label
        )}
      </button>
      <Tooltip
        id={`${courseId}-assignmentTooltip-${index}`}
        place="left"
        variant={theme === 'dark' ? 'dark' : 'light'}
        className="mb-1 p-1"
      >
        <p className="mb-0">{tooltipText}</p>
      </Tooltip>
    </>
  )
}
