import classnames from 'classnames'
import { t as tt } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { DropDownIcon } from 'src/assets/icons/customIcons/Dropdown'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import RefreshSvg from 'src/assets/icons/customIcons/Refresh.svg'
import { Unselect } from 'src/assets/icons/customIcons/Unselect'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { actionProps, errorMessage, shortenString } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useRechooseState } from 'src/hooks/rechoose'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { Form } from 'src/views/components/forms/formik/Form'
import Swal from 'sweetalert2'
import { useSWRConfig } from 'swr'
import UserPhoto from '../../../../components/UserPhoto'
import { StudentCourseGroupsScheduleModal } from './StudentCourseGroupsScheduleModal'

export default function StudentCourseGroupsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('group:groups')} />
      <React.Suspense fallback={<Loader className="m-auto flex" style={{ height: '100vh' }} />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const [selectedGroupSchedule, setSelectedGroupSchedule] = React.useState<Api.Group | null>(null)
  const { id: courseId } = useParams()

  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const groups = useApi({
    endpoint: Api.getStudentCourseGroups,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
        },
      }),
      [headers, courseId]
    ),
  })

  return (
    <>
      <div className="sticky top-0 z-10 flex w-full flex-wrap items-center justify-between bg-primaryWhite py-2 pl-1 text-primaryTextColor">
        <div className="mb-2 flex flex-wrap items-center">
          <div className="mr-4 flex items-center">
            <div className="mr-2 size-[12px] rounded-full bg-primaryGreen" />
            <span>{t('group:group_can_be_chosen')}</span>
          </div>
          <div className="mr-4 flex items-center">
            <div className="mr-2 size-[12px] min-w-[12px] rounded-full bg-primaryYellow" />
            <span>{t('schedule:group_schedule_conflicts_with_your_schedule')}</span>
          </div>
          <div className="flex items-center">
            <div className="mr-2 size-[12px] rounded-full bg-seconderyRed" />
            <span>{t('error:group_can_not_be_chosen')}</span>
          </div>
        </div>
        <Button
          variant="blue"
          className="flex w-auto items-center rounded-full px-3 py-2"
          disabled={groups.data == null}
          title={t('group:groups_refresh')}
          onClick={() => void groups.mutate()}
        >
          <img src={RefreshSvg} alt="refresh" />
          <span className="ml-2 whitespace-nowrap">{t('group:groups_refresh')}</span>
        </Button>
      </div>
      <div className="w-full flex-auto p-5">
        {(() => {
          if (groups.data != null && groups.data.length === 0) {
            return (
              <NoContent
                header={t('error:records_not_found')}
                image={Warning}
                subHeader={t('error:groups_for_selected_course_not_found')}
                marginTop="5"
              />
            )
          }
          return (
            <Table.Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  <Table.Th className="min-w-[300px] whitespace-nowrap">{t('group:groups')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('lecturer:lecturers')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('common:choose')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('choice:rechoose')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('common:remove')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {[
                  ...groups.data.filter((group) => group.choiceStatus!.isChosen),
                  ...groups.data.filter((group) => !group.choiceStatus!.isChosen),
                ].map((group) => (
                  <Group group={group} key={group.id} setSelectedGroupSchedule={setSelectedGroupSchedule} />
                ))}
              </Table.Tbody>
            </Table.Table>
          )
        })()}
      </div>
      {selectedGroupSchedule != null && (
        <Form initialValues={{ stratDate: '', endDate: '', day: '0' }} enableReinitialize onSubmit={() => {}}>
          <StudentCourseGroupsScheduleModal
            setIsModalOpen={() => setSelectedGroupSchedule(null)}
            group={selectedGroupSchedule}
          />
        </Form>
      )}
    </>
  )
}

interface GroupProps {
  readonly group: Api.Group
  readonly setSelectedGroupSchedule: React.Dispatch<React.SetStateAction<Api.Group | null>>
}

function Group({ group, setSelectedGroupSchedule }: GroupProps): JSX.Element {
  const [isSelected, setSelected] = React.useState<boolean>(false)
  const headers = useAuthenticatedHeaders()
  const theme = useTheme()

  const { id: courseId } = useParams()
  const weekSchedule: APIResponse<
    Api.getStudentCourseGroupWeekScheduleOk | null,
    Api.getStudentCourseGroupWeekScheduleErr
  > = useApi({
    endpoint: Api.getStudentCourseGroupWeekSchedule,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
          groupId: group.id,
        },
      }),
      [headers, courseId, group.id]
    ),
    shouldFetch: isSelected,
    suspense: false,
  })

  const t = useTranslatable()
  const locale = useLocale()

  const [{ inFlight }, setRechooseState] = useRechooseState()
  const setInFlight = React.useCallback(
    (fn: (state: number) => number): void => setRechooseState(({ inFlight }) => ({ inFlight: fn(inFlight) })),
    [setRechooseState]
  )
  const swr = useSWRConfig()

  const POST_RECHOOSE = React.useCallback(
    async (params: { courseId: string; groupId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesRechoose({
          headers,
          body: {
            courseId: params.courseId,
            groupId: params.groupId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void swr.mutate(Api.getStudentCourseGroupSchedule)
        void swr.mutate(Api.getStudentChoicesRechoose)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, setInFlight, swr, t]
  )

  const DELETE_RECHOOSE = React.useCallback(
    async (params: { courseId: string; groupId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.deleteStudentChoicesRechoose({
          headers,
          body: {
            courseId: params.courseId,
            groupId: params.groupId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void swr.mutate(Api.getStudentCourseGroupSchedule)
        void swr.mutate(Api.getStudentChoicesRechoose)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, setInFlight, swr, t]
  )

  const DELETE_CHOICE = React.useCallback(
    async (params: { courseId: string; groupId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.deleteStudentChoices({
          headers,
          body: {
            courseId: params.courseId,
            groupId: params.groupId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void swr.mutate(Api.getStudentCourseGroupSchedule)
        void swr.mutate(Api.getStudentChoicesRechoose)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, setInFlight, swr, t]
  )
  const POST_CHOICE = React.useCallback(
    async (params: { courseId: string; groupId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoices({
          headers,
          body: {
            courseId: params.courseId,
            groupId: params.groupId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void swr.mutate(Api.getStudentCourseGroupSchedule)
        void swr.mutate(Api.getStudentChoicesRechoose)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, setInFlight, swr, t]
  )

  return (
    <>
      <Table.Tr
        className={classnames('border-t hover:!bg-black/5', {
          '!bg-black/50': isSelected && group.choiceStatus!.isChosen,
          'bg-hover-card': isSelected,
          '!bg-primaryGreen/30': group.choiceStatus!.isChosen,
        })}
        data-testid={`group-${group.id}`}
      >
        <Table.Td className="text-center align-middle">
          {group.choiceStatus!.isChosen ? (
            <span data-tooltip-id={`${group.id}-chooseErrorTooltip`} data-testid="chosenGroupIndicator">
              <CourseCompletedIcon />
              <Tooltip
                id={`${group.id}-chooseErrorTooltip`}
                place="top"
                variant={theme === 'dark' ? 'dark' : 'light'}
                className="mb-1 p-1"
              >
                {group.choiceStatus?.chooseError}
              </Tooltip>
            </span>
          ) : (
            <>
              {!group.choiceStatus!.canChoose && (
                <span data-tooltip-id={`${group.id}-chooseErrorTooltip`} data-testid="canNotChooseIndicator">
                  <div className="mr-2 size-[12px] rounded-full bg-primaryRed" />
                  <Tooltip
                    id={`${group.id}-chooseErrorTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    {group.choiceStatus?.chooseError}
                  </Tooltip>
                </span>
              )}
              {group.choiceStatus!.canChoose && group.choiceStatus!.isScheduleInConflict && (
                <span data-tooltip-id={`${group.id}-scheduleConflictTooltip`} data-testid="scheduleConflictIndicator">
                  <div
                    className="mr-2 size-[12px] rounded-full bg-primaryYellow"
                    data-tooltip-id={`${group.id}-scheduleConflictTooltip`}
                  />
                  <Tooltip
                    id={`${group.id}-scheduleConflictTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    {group.choiceStatus!.scheduleConflictMessage}
                  </Tooltip>
                </span>
              )}
              {group.choiceStatus!.canChoose && !group.choiceStatus!.isScheduleInConflict && (
                <div className="mr-2 size-[12px] rounded-full bg-primaryGreen" data-testid="canChooseIndicator" />
              )}
            </>
          )}
        </Table.Td>
        <Table.Td className="flex w-full flex-wrap pt-[29px]">
          <a
            type="button"
            {...actionProps(() => {
              void setSelected(!isSelected)
            })}
            data-tooltip-id={`${group.id}-scheduleTooltip`}
            className="flex items-center !no-underline"
            data-testid="groupTitle"
          >
            <div className={`mr-2 mt-[3px] w-fit duration-300 ease-in-out ${isSelected ? 'rotate-90' : ''}`}>
              <div className="-rotate-90">
                <DropDownIcon />
              </div>
            </div>
            <span>{group.name}</span>
            <span className="mx-2">•</span>
            <span>{tt(`common:total_places`, { place: group.maxQuota })} </span>

            <Tooltip
              id={`${group.id}-scheduleTooltip`}
              place="top"
              variant={theme === 'dark' ? 'dark' : 'light'}
              className="mb-1 p-1"
            >
              {t('schedule:see_group_schedule')}
            </Tooltip>
          </a>
        </Table.Td>
        <Table.Td>
          <div className="flex flex-wrap">
            {group.lecturers?.map((lecturer, index) => (
              <div
                className="my-1 mr-1 min-w-[200px] whitespace-nowrap align-middle"
                key={index}
                data-tooltip-id={`${group.id}-lecturersPageTooltip`}
              >
                <UserPhoto user={lecturer} />
                <Link
                  to={`/${locale}/users/${lecturer.uid}`}
                  className="ml-2 whitespace-nowrap align-middle hover:underline"
                  data-testid={`lecturerName-${lecturer.uid}`}
                >
                  {lecturer.fullName}
                </Link>
                <Tooltip
                  id={`${group.id}-lecturersPageTooltip`}
                  place="top"
                  variant={theme === 'dark' ? 'dark' : 'light'}
                  className="mb-1 p-1"
                >
                  {t('course:lecturers_page')}
                </Tooltip>
              </div>
            ))}
          </div>
        </Table.Td>
        <Table.Td colSpan={group.choiceStatus?.isChosen ?? false ? 1 : 3}>
          <ChooseButton
            isActive={group.choiceStatus!.canChoose}
            isLoading={inFlight > 0}
            variant="blue"
            onClick={() => {
              if (!group.choiceStatus!.canChoose && group.choiceStatus?.chooseError != null) {
                toast.error(<div dangerouslySetInnerHTML={{ __html: group.choiceStatus?.chooseError }} />)
              } else {
                void POST_CHOICE({
                  groupId: group.id,
                  courseId: courseId!,
                })
              }
            }}
            icon={<Plus color="white" />}
            label={t('common:choose')}
            dataTestid="chooseButton"
          />
        </Table.Td>
        {(group.choiceStatus?.isChosen ?? false) && (
          <>
            <Table.Td>
              <ChooseButton
                isActive={
                  (group.choiceStatus?.canRechoose ?? false) || (group.choiceStatus?.canRemoveRechoose ?? false)
                }
                isLoading={inFlight > 0}
                variant="yellow"
                onClick={() => {
                  if (
                    (group.choiceStatus?.rechooseError != null && !group.choiceStatus?.canRemoveRechoose) ||
                    group.choiceStatus?.removeChoiceError != null
                  ) {
                    toast.error(group.choiceStatus?.rechooseError ?? group.choiceStatus?.removeChoiceError)
                  } else {
                    if (group.choiceStatus?.canRechoose ?? false) {
                      void POST_RECHOOSE({
                        groupId: group.id,
                        courseId: courseId!,
                      })
                    } else if (group.choiceStatus?.canRemoveRechoose ?? false) {
                      void DELETE_RECHOOSE({
                        groupId: group.id,
                        courseId: courseId!,
                      })
                    }
                  }
                }}
                icon={<Unselect color="white" />}
                label={
                  group.choiceStatus?.canRemoveRechoose ?? false ? t('choice:cancel_rechoose') : t('choice:rechoose')
                }
                dataTestid="rechooseButton"
              />
            </Table.Td>
            <Table.Td>
              <ChooseButton
                isActive={group.choiceStatus?.canRemoveChoice ?? false}
                isLoading={inFlight > 0}
                variant="red"
                onClick={async () => {
                  if (!(group.choiceStatus?.canRemoveChoice ?? false)) {
                    toast.error(group.choiceStatus?.removeChoiceError)
                  } else {
                    const alert = await Swal.fire<void>({
                      title: t('common:are_you_sure'),
                      icon: 'error',
                      showCancelButton: true,
                      confirmButtonColor: '#dc3545',
                      cancelButtonColor: '#6C757D',
                      confirmButtonText: t('common:delete_choice'),
                      cancelButtonText: t('common:cancel'),
                      cancelButtonAriaLabel: t('common:cancel'),
                      confirmButtonAriaLabel: t('common:delete_choice'),
                    })
                    if (alert.isConfirmed) {
                      void DELETE_CHOICE({
                        groupId: group.id,
                        courseId: courseId!,
                      })
                    }
                  }
                }}
                icon={<DeleteIcon color="white" />}
                label={t('common:delete')}
                dataTestid="deleteButton"
              />
            </Table.Td>
          </>
        )}
      </Table.Tr>
      {/* first week schedule dropdown */}
      {isSelected && (weekSchedule.data == null || weekSchedule.data?.length === 0) ? (
        weekSchedule.data?.length === 0 ? (
          <Table.Tr>
            <Table.Td colSpan={6}>
              <NoContent header={t('error:records_not_found')} image={Warning} marginTop="5" />
            </Table.Td>
          </Table.Tr>
        ) : weekSchedule.error == null ? (
          <Table.Tr>
            <Table.Td colSpan={6}>
              <Loader className="flex" style={{ margin: 'auto' }} />
            </Table.Td>
          </Table.Tr>
        ) : (
          <Table.Tr>
            <Table.Td colSpan={6}>
              <p>{errorMessage(weekSchedule.error)}</p>
            </Table.Td>
          </Table.Tr>
        )
      ) : (
        <Table.Tr className={`${isSelected ? 'table-row' : 'hidden'}`}>
          <Table.Td colSpan={6} className="bg-card !p-0">
            <Table.Table className="!rounded-none">
              <Table.Thead>
                <Table.Tr className="border-t-0">
                  <Table.Th className="w-[16%] !py-[10px] !pl-[75px]">{t('common:day')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:time')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:room')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:lecture_type')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('lecturer:lecturers')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:information')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {weekSchedule.data?.map((schedule, index) => (
                  <React.Fragment key={index}>
                    <Table.Tr
                      className={classnames({
                        '!bg-primaryRed/30': schedule.scheduleConflictMessage,
                      })}
                      data-tip
                      data-for={`${group.id}${index}`}
                      data-testid={`weekSchedule-${group.id}-${index}`}
                    >
                      <Table.Td className="!py-[10px] !pl-[75px]">
                        <span data-testid="day">{schedule.day}</span>
                        <span className="ml-[5px] font-normal text-captionColor" data-testid="date">
                          ({schedule.date})
                        </span>
                        {schedule.scheduleConflictMessage != null ? (
                          <Tooltip
                            id={`${group.id}${index}`}
                            place="top"
                            variant={theme === 'dark' ? 'dark' : 'light'}
                            className="mb-1 p-1"
                          >
                            {schedule.scheduleConflictMessage}
                          </Tooltip>
                        ) : null}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="time">
                        {schedule.startTime} - {schedule.endTime}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="location">
                        {schedule.locationName}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="lectureType">
                        {schedule.lectureType}
                      </Table.Td>
                      <Table.Td className="!py-[10px]">
                        {schedule.lecturers?.map((lecturer, index) => (
                          <Link
                            key={index}
                            to={`/${locale}/users/${lecturer.uid}`}
                            className="ml-2 whitespace-nowrap align-middle hover:underline"
                            data-testid={`weekSchedule-lecturerName-${lecturer.uid}`}
                          >
                            {lecturer.fullName}
                          </Link>
                        ))}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-tooltip-id="week-info" data-testid="weekInfo">
                        <Tooltip
                          id="week-info"
                          place="left"
                          noArrow
                          variant={theme === 'dark' ? 'dark' : 'light'}
                          className="max-w-[300px]"
                          opacity={100}
                        >
                          {schedule.info}
                        </Tooltip>
                        {shortenString(schedule.info ?? '', 30)}
                      </Table.Td>
                    </Table.Tr>
                  </React.Fragment>
                ))}
                <Table.Tr
                  data-tip
                  className="relative cursor-pointer"
                  onClick={() => setSelectedGroupSchedule(group)}
                  data-testid={`${group.id}-seeFullScheduleButton`}
                >
                  <Table.Td className="absolute !py-[10px] !pl-[75px] font-medium text-[#337ab7] dark:text-[#337ab7]">
                    {t('schedule:see_full_schedule')}
                  </Table.Td>
                  <Table.Td></Table.Td> <Table.Td></Table.Td>
                  <Table.Td></Table.Td>
                  <Table.Td></Table.Td>
                  <Table.Td></Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table.Table>
          </Table.Td>
        </Table.Tr>
      )}
    </>
  )
}

interface ButtonProps {
  readonly onClick: any
  readonly label: string
  readonly icon: React.ReactNode
  readonly isActive: boolean
  readonly isLoading: boolean
  readonly variant?: string
  readonly dataTestid?: string
}

function ChooseButton({ onClick, label, variant, icon, isActive, isLoading, dataTestid }: ButtonProps): JSX.Element {
  return (
    <button
      className={classnames(
        'flex items-center whitespace-nowrap rounded-[4px] px-3 py-2 text-white opacity-75 duration-300 ease-in-out hover:opacity-100',
        {
          'bg-seconderyRed': variant === 'red',
          'bg-primaryBlue': variant === 'blue',
          'bg-[#FFBA00]': variant === 'yellow',
          '!opacity-20': !isActive || isLoading,
        }
      )}
      type="button"
      disabled={!isActive || isLoading}
      onClick={onClick}
      data-testid={dataTestid}
    >
      {isLoading ? <Loader className="size-[20px]" /> : icon} <span className="ml-2">{label}</span>
    </button>
  )
}
