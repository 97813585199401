import * as React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import BackButton from 'src/views/components/BackButton'

function StudentFeedbackLayout(): React.ReactElement | null {
  const t = useTranslatable()
  const { pathname } = useLocation()
  const parsedPathname = pathname.substring(4)
  const locale = useLocale()

  return (
    <div>
      <div className="!mb-9 flex items-center justify-end">
        {parsedPathname.includes('add') ? (
          <BackButton link={`/${locale}/student/feedbacks`} />
        ) : (
          <Link to="add">
            <Button variant="blue" className="flex items-center">
              <Plus color="white" /> <span className="ml-2"> {t('feedback:add_feedback')}</span>
            </Button>
          </Link>
        )}
      </div>
      <Outlet />
    </div>
  )
}
export default React.memo(StudentFeedbackLayout)
