export function DropDownIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="Dropdown_Icon" data-name="Dropdown Icon" transform="translate(0.158)">
        <rect
          id="Rectangle_3693"
          data-name="Rectangle 3693"
          width="16"
          height="16"
          transform="translate(-0.158)"
          className="fill-primaryWhite"
          opacity="0"
        />
        <path
          id="Path_1"
          data-name="Path 1"
          d="M1826.9,36.276l4,4.111,4-4.111"
          transform="translate(-1822.899 -30)"
          fill="none"
          className="stroke-svgColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
