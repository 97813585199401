import { format } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'

import { formatDatelocale } from 'src/helpers/fns'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import SummaryRow from './components/SummaryRow'

export default function Trainings({ countries }: TrainingsProps): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const { data: trainings } = useApi({
    endpoint: Api.getStudentResumeTrainings,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  if (trainings.length === 0) return null
  return (
    <div className="my-2 flex w-full flex-col">
      <SummaryRow title={t('resume:trainings_seminars_workshops')} />
      {trainings.map((training, index) => (
        <SuspenseWrapper key={index}>
          <Training
            training={training}
            country={countries.find((country) => country.id === training.countryId)!.name}
          />
        </SuspenseWrapper>
      ))}
    </div>
  )
}

function Training({ training, country }: TrainingProps): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const { data: cities } = useApi({
    endpoint: Api.getStudentResumeCities,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            countryId: training.countryId,
          },
        },
      }),
      [headers, training.countryId]
    ),
  })

  return (
    <SummaryRow
      title={
        <>
          {format(formatDatelocale(training.startDate) as Date, 'd MMMM yyyy', { locale: dateLocale })}-{' '}
          {training.endDate != null && training.endDate !== ''
            ? format(formatDatelocale(training.endDate) as Date, 'd MMMM yyyy', { locale: dateLocale })
            : t('common:current')}
        </>
      }
      content={
        <>
          <p>{training.name}</p>
          <p>{training.companyName}</p>
          <p>
            {country}, {cities.find((value) => value.id === training.cityId)?.name}
          </p>
          <div className="flex flex-wrap items-center">
            {training.schedule.map((schedule, index) => (
              <div className="mb-3 mr-3 flex flex-col" key={index}>
                <p className="mb-0 mt-1">
                  {index === 0 && t('calendar:monday')}
                  {index === 1 && t('calendar:tuesday')}
                  {index === 2 && t('calendar:wednesday')}
                  {index === 3 && t('calendar:thursday')}
                  {index === 4 && t('calendar:friday')}
                  {index === 5 && t('calendar:saturday')}
                  {index === 6 && t('calendar:sunday')}
                </p>
                <p>
                  {schedule.startHour} - {schedule.endHour}
                </p>
              </div>
            ))}
          </div>
        </>
      }
    />
  )
}

interface TrainingsProps {
  readonly countries: Api.getCountriesOk
}

interface TrainingProps {
  readonly training: Api.ResumeTraining
  readonly country: string
}
