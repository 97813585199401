export function PrintIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="print_icon" transform="translate(1 1)">
        <rect
          id="Rectangle_4250"
          data-name="Rectangle 4250"
          width="18"
          height="18"
          transform="translate(-1 -1)"
          opacity="0"
        />
        <rect id="Rectangle_4312" data-name="Rectangle 4312" width="16" height="16" opacity="0" />
        <path
          id="Subtraction_255"
          data-name="Subtraction 255"
          d="M-10701-116h-4a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h14a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1h-4v-1h4v-6h-14v6h4v1Z"
          transform="translate(10706 129)"
          fill="gray"
        />
        <path
          id="Rectangle_4443_-_Outline"
          data-name="Rectangle 4443 - Outline"
          d="M1,1V5H7V1H1M0,0H8V5A1,1,0,0,1,7,6H1A1,1,0,0,1,0,5Z"
          transform="translate(4 10)"
          fill="gray"
        />
        <path
          id="Rectangle_4446_-_Outline"
          data-name="Rectangle 4446 - Outline"
          d="M1,1V5H11V1H1M1,0H11a1,1,0,0,1,1,1V6H0V1A1,1,0,0,1,1,0Z"
          transform="translate(2)"
          fill="gray"
        />
        <rect
          id="Rectangle_4444"
          data-name="Rectangle 4444"
          width="2"
          height="1"
          rx="0.5"
          transform="translate(12 7)"
          fill="gray"
        />
        <rect
          id="Rectangle_4445"
          data-name="Rectangle 4445"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(3 10)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
