import BillingIcon from 'src/assets/icons/customIcons/page-icons/Billing'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function StudentCourseLayout(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()

  const subBreadcrumbsItems = [
    { page: `${t('billing:financial_information')}`, path: `/${locale}/student/billing/balance` },
  ]

  return (
    <SubPageLayout
      Icon={BillingIcon}
      title={t('billing:financial_information')}
      subBreadcrubms={subBreadcrumbsItems}
      pages={[
        {
          title: t('billingLayout:balance'),
          link: 'balance',
        },
        {
          title: t('billingLayout:payment_schedule'),
          link: 'schedule',
        },
        {
          title: t('billingLayout:deposits'),
          link: 'deposits',
        },
      ]}
    />
  )
}
