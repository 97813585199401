import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { format, formatDistance, parseISO } from 'date-fns'
import * as React from 'react'
import { Tooltip } from 'react-tooltip'
import type * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import CommentIcon from 'src/assets/icons/customIcons/Comment'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useTheme } from 'src/state/providers/Theme'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Comments from './Comments'

interface Props {
  readonly post: Api.CoursePost
}

export default function Post({ post }: Props): JSX.Element {
  const t = useTranslatable()
  const localeDate = useDateLocale()
  const [clickedComment, setClickedComment] = React.useState(false)
  const theme = useTheme()
  const [commentsCount, setCommentsCount] = React.useState(post.commentsCount)

  return (
    <div key={post.id} data-testid={`discussion-${post.id}`} className="my-4 flex flex-col items-start justify-between">
      <section className="flex w-full items-start justify-between">
        <UserPhoto user={post.author} />

        <div className="ml-[12px] w-full flex-col">
          <div className="flex w-full flex-col rounded-card bg-card p-3 py-2">
            <div className="mb-2 flex w-full flex-wrap items-center justify-between">
              <h5 className="font-bold" data-testid="authorName">
                {post.author?.fullName}
              </h5>
              <h5 className="text-[#00000568]" data-testid="postNumber">
                #{post.number}
              </h5>
            </div>
            <div className="dark:text-white" dangerouslySetInnerHTML={{ __html: post.body }} />
          </div>
          <div className="mt-1 flex flex-wrap text-captionColor">
            <button
              data-tooltip-id={post.id}
              onClick={() => setClickedComment(!clickedComment)}
              className="mr-4 flex items-center"
              data-testid="commentButton"
            >
              <div
                className={classNames('flex items-center', {
                  'cursor-not-allowed opacity-50': !post.isCommentingEnabled,
                })}
                data-testid="commentContent"
              >
                <span className="mr-1">
                  <CommentIcon />
                </span>
                <span className="text-captionColor" data-testid="commentsCount">
                  {t('discussion:comments')} ({commentsCount})
                </span>
              </div>
              {!post.isCommentingEnabled && (
                <Tooltip id={post.id} place="top" variant={theme === 'dark' ? 'dark' : 'light'} className="mb-1 p-1">
                  <p className="mb-0" key={post.createdAt}>
                    {t('discussion:comments_disabled')}
                  </p>
                </Tooltip>
              )}
            </button>
            <button
              className="flex items-center"
              data-tooltip-id={post.createdAt}
              onClick={(e) => e.preventDefault()}
              data-testid="postCreationDate"
            >
              <ClockIcon />{' '}
              <span className="ml-1 text-captionColor" data-testid="postCreationDateContent">
                {formatDistance(new Date(post.createdAt), new Date(), { addSuffix: true, locale: localeDate })}
              </span>
              <Tooltip
                id={post.createdAt}
                place="top"
                variant={theme === 'dark' ? 'dark' : 'light'}
                className="mb-1 p-1"
              >
                <p className="mb-0" key={post.createdAt}>
                  {format(parseISO(post.createdAt), 'd MMMM yyyy, HH:mm', {
                    locale: localeDate,
                  })}
                </p>
              </Tooltip>
            </button>
            <div className="ml-2">
              {post.isVisibleToAll ? (
                <FontAwesomeIcon
                  icon={FreeSolidSvgIcons.faGlobeAmericas}
                  data-tooltip-id="isVisibleToAll"
                  data-testid="globalIcon"
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={FreeSolidSvgIcons.faUserGroup}
                    data-tooltip-id="!isVisibleToAll"
                    data-testid="groupIcon"
                  />
                  {post.groups.map((g) => (
                    <span key={g.id} data-testid={`group-${g.id}`} className="ml-1 rounded-md bg-card p-1 text-caption">
                      {g.name}
                    </span>
                  ))}
                </>
              )}
              <Tooltip
                id="isVisibleToAll"
                place="top"
                variant={theme === 'dark' ? 'dark' : 'light'}
                className="mb-1 p-1"
              >
                <p className="mb-0">{t('group:all_of_your_groups')}</p>
              </Tooltip>
              <Tooltip
                id="!isVisibleToAll"
                place="top"
                variant={theme === 'dark' ? 'dark' : 'light'}
                className="mb-1 p-1"
              >
                <p className="mb-0" key={post.createdAt}>
                  {t('group:only_selected_groups')}
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      </section>
      {post.isCommentingEnabled && clickedComment && (
        <SuspenseWrapper>
          <Comments post={post} setCommentsCount={setCommentsCount} />
        </SuspenseWrapper>
      )}
    </div>
  )
}
