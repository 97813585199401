import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { t as tt } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { ArrowDown } from 'src/assets/icons/customIcons/ArrowDown'
import { ArrowUp } from 'src/assets/icons/customIcons/ArrowUp'
import { AssignmentDown } from 'src/assets/icons/customIcons/AssignmentDown'
import { AssignmentLoading } from 'src/assets/icons/customIcons/AssignmentLoading'
import { AssignmentUp } from 'src/assets/icons/customIcons/AssignmentUp'
import { CircleGreen } from 'src/assets/icons/customIcons/CircleGreen'
import { CircleOrange } from 'src/assets/icons/customIcons/CircleOrange'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { CourseGeneralIcon } from 'src/assets/icons/customIcons/course-icons/CourseGeneral'
import { CourseProgramIcon } from 'src/assets/icons/customIcons/course-icons/CourseProgram'
import { CourseUserIcon } from 'src/assets/icons/customIcons/course-icons/CourseUser'
import { actionProps, shortenString } from 'src/helpers/fns'
import { useApi, useDropdown } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import { useTheme } from 'src/state/providers/Theme'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentCoursesProgramPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses_program')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const { id } = useParams()
  const headers = useAuthenticatedHeaders()

  const t = useTranslatable()
  const locale = useLocale()
  const majorProgramToggle = useDropdown<HTMLButtonElement>(false)
  const minorProgramToggle = useDropdown<HTMLButtonElement>(false)
  const userState = useUserState()
  const [inFlight, setInFlight] = React.useState(0)
  const theme = useTheme()

  const courses = useApi({
    endpoint: Api.getStudentAvailableProgramsCourses,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const choices = useApi({
    endpoint: Api.getStudentAvailableProgramsChoices,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const program = useApi({
    endpoint: Api.getStudentProgram,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const ASSIGN_TO_PROGRAM = React.useCallback(
    async (params: { uid: string; programId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesProgramsAssign({
          headers,
          args: {
            uid: params.uid,
            program_id: params.programId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, choices, t]
  )

  const REMOVE_ASSIGNMENT = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesRemoveAssignment({
          headers,
          args: {
            uid: choiceId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((n) => n - 1)
    },
    [headers, choices, t]
  )

  const ASSIGN_TO_FREE_CREDITS = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesFreeCreditsAssign({
          headers,
          args: {
            uid: choiceId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void choices.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((n) => n - 1)
    },
    [headers, choices, t]
  )

  if (userState.data == null) return null

  return (
    <div className="w-full">
      <h3 className="font-semibold text-primaryTextColor" data-testid="programTitle">
        {program.data.name} ({program.data.code})
      </h3>

      {program.data.description.length > 0 && (
        <div className="mt-3 rounded-card bg-card">
          <button
            className={classnames('w-full py-[16px] pl-[22px] font-semibold', {
              show: majorProgramToggle.show,
            })}
            style={{ boxShadow: 'none' }}
            type="button"
            id={majorProgramToggle.id}
            ref={majorProgramToggle.ref}
            aria-expanded={majorProgramToggle.show ? 'true' : 'false'}
            {...actionProps(() => void majorProgramToggle.setShow(!majorProgramToggle.show))}
            data-testid="programMajorDescriptionButton"
          >
            <div className="flex items-center">
              <span>{majorProgramToggle.show ? <ArrowUp /> : <ArrowDown />}</span>
              <span className="ml-2 text-primaryTextColor">{t('program:program_description_for_majors')}</span>
            </div>
          </button>
          <div
            className={classnames('mt-1', majorProgramToggle.show ? 'block' : 'hidden', {
              show: majorProgramToggle.show,
            })}
            aria-labelledby={majorProgramToggle.id}
          >
            <div
              data-testid="programMajorDescription"
              className="max-w-full overflow-auto rounded bg-card px-[22px] py-[16px]"
              dangerouslySetInnerHTML={{ __html: program.data.description }}
            />
          </div>
        </div>
      )}

      {program.data.minorDescription.length > 0 && (
        <div className="mt-[8px] rounded-card bg-card">
          <button
            className={classnames('w-full py-[16px] pl-[22px] font-semibold', {
              show: minorProgramToggle.show,
            })}
            style={{ boxShadow: 'none' }}
            type="button"
            id={minorProgramToggle.id}
            ref={minorProgramToggle.ref}
            aria-expanded={minorProgramToggle.show ? 'true' : 'false'}
            {...actionProps(() => void minorProgramToggle.setShow(!minorProgramToggle.show))}
            data-testid="programMinorDescriptionButton"
          >
            <span className="flex items-center text-primaryTextColor">
              {minorProgramToggle.show ? <ArrowUp /> : <ArrowDown />}
              <span className="ml-2">{t('program:program_description_for_minors')}</span>
            </span>
          </button>
          <div
            className={classnames('mt-1', minorProgramToggle.show ? 'block' : 'hidden', {
              show: minorProgramToggle.show,
            })}
            aria-labelledby={minorProgramToggle.id}
          >
            <div
              data-testid="programMinorDescription"
              className="py-[16px max-w-[100%]] overflow-auto rounded bg-card px-[22px]"
              dangerouslySetInnerHTML={{ __html: program.data.minorDescription }}
            />
          </div>
        </div>
      )}

      {choices.data.choices.length > 0 ? (
        <>
          <div className="mt-4 flex w-full flex-wrap">
            <div className="mb-[5px] mr-4 flex items-center text-primaryTextColor md:mb-0" data-testid="mandatory">
              <CourseGeneralIcon />
              <span className="ml-2 mr-1">{t('program:mandatory')}</span>
              {choices.data.choiceTypes?.find((choice) => choice.type === 'MANDATORY')?.credits}
            </div>
            <div
              className="mb-[5px] mr-3 flex items-center text-primaryTextColor md:mb-0"
              data-testid="mandatoryElective"
            >
              <div className="min-w-[12px]">
                <CircleOrange />
              </div>
              <span className="ml-2 mr-1">{t('program:mandatory_optional')}</span>
              {choices.data.choiceTypes?.find((choice) => choice.type === 'MANDATORY_ELECTIVE')?.credits}
            </div>
            <div className="mb-[5px] mr-3 flex items-center text-primaryTextColor md:mb-0" data-testid="optional">
              <CircleGreen />
              <span className="ml-2 mr-1">{t('program:optional')}</span>
              {choices.data.choiceTypes?.find((choice) => choice.type === 'ELECTIVE')?.credits}
            </div>
            <div className="mb-[5px] mr-3 flex items-center text-primaryTextColor md:mb-0" data-testid="allItems">
              <CourseProgramIcon />
              <span className="ml-2">
                {t('common:all')} {choices.data.creditsAll}
              </span>
            </div>
          </div>

          <div className="my-4 flex w-full flex-wrap">
            <div className="mb-[5px] mr-3 flex items-center md:mb-0" data-testid="successfullyFinishedStatus">
              <CourseCompletedIcon />
              <span className="ml-2 text-primaryTextColor">{t('course:successfully_finished')}</span>
            </div>
            <div className="mb-[5px] mr-3 flex items-center md:mb-0" data-testid="chosenInCurrentSemesterStatus">
              <CourseChosenIcon />
              <span className="ml-2 text-primaryTextColor">
                {tt('course:chosen_credits', { credits: choices.data.creditsCurrent })}
              </span>
            </div>
            <div className="mb-[5px] mr-3 flex items-center md:mb-0" data-testid="failedStatus">
              <CourseFailedIcon />
              <span className="ml-2 text-primaryTextColor">{t('course:failed')}</span>
            </div>
          </div>

          <div>
            <Table.Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  <Table.Th />
                  <Table.Th>{t('program:program_code')}</Table.Th>
                  <Table.Th>{t('course:course_name')}</Table.Th>
                  <Table.Th>{t('common:cred_type')}</Table.Th>
                  <Table.Th>{t('common:score')}</Table.Th>
                  <Table.Th>{t('common:gain_cred')}</Table.Th>
                  <Table.Th>{t('course:credit')}</Table.Th>
                  <Table.Th>{t('course:belong')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {choices.data.choices.map((choice) => (
                  <Table.Tr
                    key={choice.id}
                    className={
                      choice.courseChoiceType!.type === 'MANDATORY'
                        ? 'bg-lightBlue'
                        : choice.courseChoiceType!.type === 'MANDATORY_ELECTIVE'
                          ? 'bg-lightYellow'
                          : 'bg-lightGreen'
                    }
                    data-testid={`course/${choice.id}`}
                  >
                    <Table.Td>
                      <div data-tooltip-id={`${choice.id}-courseChoiceType`} data-testid="mandatoryStatusColumn">
                        {choice.courseChoiceType?.type === 'MANDATORY' && <CourseGeneralIcon />}
                        {choice.courseChoiceType?.type === 'MANDATORY_ELECTIVE' && <CircleOrange />}
                        {choice.courseChoiceType?.type === 'ELECTIVE' && <CircleGreen />}
                      </div>
                      <Tooltip
                        id={`${choice.id}-courseChoiceType`}
                        place="top"
                        variant={theme === 'dark' ? 'dark' : 'light'}
                        className="mb-1 p-1"
                      >
                        <p className="mb-0" data-testid={`mandatoryStatusTooltip/${choice.id}`}>
                          {choice.courseChoiceType!.type === 'MANDATORY'
                            ? t('program:mandatory')
                            : choice.courseChoiceType!.type === 'MANDATORY_ELECTIVE'
                              ? t('program:mandatory_elective')
                              : t('program:optional')}
                        </p>
                      </Tooltip>
                    </Table.Td>
                    <Table.Td
                      className="text-center align-middle text-primaryTextColor"
                      data-testid="completeStatusColumn"
                    >
                      {choice.status === 'PASSED' && (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`}>
                          <CourseCompletedIcon />
                        </div>
                      )}
                      {choice.status === 'CURRENT' && (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`}>
                          <CourseChosenIcon />
                        </div>
                      )}
                      {choice.status === 'FAILED' && (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`}>
                          <CourseFailedIcon />
                        </div>
                      )}
                      <Tooltip
                        id={`${choice.id}-statusTooltip`}
                        place="top"
                        variant={theme === 'dark' ? 'dark' : 'light'}
                        className="mb-1 p-1"
                      >
                        <p className="mb-0" data-testid={`statusTooltip/${choice.id}`}>
                          {choice.status === 'PASSED' && t('course:successfully_finished')}
                          {choice.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                          {choice.status === 'FAILED' && t('course:failed')}
                        </p>
                      </Tooltip>
                    </Table.Td>
                    <Table.Td className="text-primaryTextColor" data-testid="courseCode">
                      {shortenString(choice.courseCode, 50)}
                    </Table.Td>
                    <Table.Td className="table-link w-full align-middle">
                      <Link
                        className="text-primaryBlueLink hover:underline"
                        to={`/${locale}/student/courses/${choice.course!.id}/groups`}
                        data-testid="courseName"
                      >
                        {choice.courseName}
                      </Link>
                    </Table.Td>
                    <Table.Td className="text-primaryTextColor" data-testid="creditType">
                      {choice.creditType}
                    </Table.Td>
                    <Table.Td className="text-primaryTextColor" data-testid="latScore">
                      {choice.isLatChoice ? choice.latScore : choice.score.toFixed(2)}
                    </Table.Td>
                    <Table.Td className="text-primaryTextColor" data-testid="credits">
                      {choice.isLatChoice && choice.courseCredits === 0 ? '' : choice.credits}
                    </Table.Td>
                    <Table.Td className="text-primaryTextColor" data-testid="courseCredits">
                      {choice.courseCredits}
                    </Table.Td>
                    <Table.Td className="text-center">
                      {choice.assignmentStatuses?.map((status, index) => (
                        <React.Fragment key={index}>
                          {status.action === 'ASSIGN_TO_FREE_CREDITS' && (
                            <Button
                              dataTip
                              dataFor={`${choice.id}-assignmentTooltip-${index}`}
                              isLoading={inFlight > 0}
                              onClick={() => void ASSIGN_TO_FREE_CREDITS(choice.id)}
                              label={<AssignmentUp />}
                              index={index}
                              courseId={choice.id}
                              tooltipText={t('course:assign_to_free_credits')}
                              data-testid="assignToFreeCredits"
                            />
                          )}
                          {status.action === 'ASSIGN_TO_PROGRAM' && (
                            <Button
                              dataTip
                              dataFor={`${choice.id}-assignmentTooltip-${index}`}
                              isLoading={inFlight > 0}
                              onClick={() => void ASSIGN_TO_PROGRAM({ uid: choice.id, programId: status.programId! })}
                              label={
                                status.programId === '0' || status.programId === '-1' ? (
                                  <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} />
                                ) : (
                                  <AssignmentUp />
                                )
                              }
                              index={index}
                              courseId={choice.id}
                              tooltipText={tt('uncategorized:assign_to', { program: status.programName })}
                              data-testid="assignToProgram"
                            />
                          )}
                          {status.action === 'REMOVE_ASSIGNMENT' && (
                            <Button
                              dataTip
                              dataFor={`${choice.id}-assignmentTooltip-${index}`}
                              isLoading={inFlight > 0}
                              onClick={() => void REMOVE_ASSIGNMENT(choice.id)}
                              label={<AssignmentDown />}
                              index={index}
                              courseId={choice.id}
                              tooltipText={t('course:remove_assignment')}
                              data-testid="removeAssignment"
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
        </>
      ) : (
        <div className="mt-4 text-center text-primaryTextColor" role="alert">
          {t('error:courses_not_found')}
        </div>
      )}

      {courses.data.courses.length > 0 && (
        <>
          <div className="mt-2">
            <h3 className="mb-2 font-semibold">{t('course:other_courses')}</h3>
            <p className="flex">
              <CourseUserIcon /> <span className="ml-2">{t('course:course_offered_in_current_semester')}</span>
            </p>
          </div>

          <div className="mt-2 flex text-primaryTextColor xxs:block xs:block">
            <div className="mr-4 flex items-center">
              <CourseGeneralIcon />
              <span className="ml-2 mr-1 text-primaryTextColor">{t('program:mandatory')}</span>
              {choices.data.choiceTypes!.find((choice) => choice.type === 'MANDATORY')?.credits}
            </div>
            <div className="mr-4 flex items-center">
              <div className="min-w-[12px]">
                <CircleOrange />
              </div>
              <span className="ml-2 mr-1 text-primaryTextColor">{t('program:mandatory_optional')}</span>
              {choices.data.choiceTypes!.find((choice) => choice.type === 'MANDATORY_ELECTIVE')?.credits}
            </div>
            <div className="mr-4 flex items-center">
              <CircleGreen />
              <span className="ml-2 mr-1 text-primaryTextColor">{t('program:optional')}</span>
              {choices.data.choiceTypes!.find((choice) => choice.type === 'ELECTIVE')?.credits}
            </div>
            <div className="mr-4 flex items-center">
              <CourseProgramIcon />
              <span className="ml-2 text-primaryTextColor">
                {t('common:all')} {choices.data.creditsAll}
              </span>
            </div>
          </div>

          <div className="mt-3">
            <Table.Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  <Table.Th />
                  <Table.Th className="whitespace-nowrap">{t('common:unique')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('common:code')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('course:course_name')}</Table.Th>
                  <Table.Th className="whitespace-nowrap">{t('common:credits')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {courses.data.courses.map((course) => (
                  <Table.Tr
                    key={course.id}
                    className={
                      course.courseChoiceType?.type === 'MANDATORY'
                        ? 'bg-lightBlue'
                        : course.courseChoiceType!.type === 'MANDATORY_ELECTIVE'
                          ? 'bg-lightYellow'
                          : 'bg-lightGreen'
                    }
                  >
                    <Table.Td className="pl-2 text-center align-middle text-sm">
                      <div data-tooltip-id={`${course.id}-courseChoiceType`}>
                        {course.courseChoiceType?.type === 'MANDATORY' && <CourseGeneralIcon />}
                        {course.courseChoiceType?.type === 'MANDATORY_ELECTIVE' && <CircleOrange />}
                        {course.courseChoiceType?.type === 'ELECTIVE' && <CircleGreen />}
                      </div>
                      <Tooltip
                        id={`${course.id}-courseChoiceType`}
                        place="top"
                        variant={theme === 'dark' ? 'dark' : 'light'}
                        className="mb-1 p-1"
                      >
                        <p className="mb-0">
                          {course.courseChoiceType!.type === 'MANDATORY'
                            ? t('program:mandatory')
                            : course.courseChoiceType!.type === 'MANDATORY_ELECTIVE'
                              ? t('program:mandatory_elective')
                              : t('program:optional')}
                        </p>
                      </Tooltip>
                    </Table.Td>
                    <Table.Td className="text-center align-middle text-sm">
                      {course.isEnabledForChoose && (
                        <>
                          <div data-tooltip-id={`${course.id}-courseOffered`}>
                            <CourseUserIcon />
                          </div>
                          <Tooltip
                            id={`${course.id}-courseOffered`}
                            place="top"
                            variant={theme === 'dark' ? 'dark' : 'light'}
                            className="mb-1 p-1"
                          >
                            <p className="mb-0">{t('course:course_offered_in_current_semester')}</p>
                          </Tooltip>
                        </>
                      )}
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap align-middle text-sm text-primaryTextColor">
                      {course.code}
                    </Table.Td>
                    {course.programCode.length > 50 ? (
                      <Table.Td
                        className="whitespace-nowrap align-middle text-sm text-primaryTextColor"
                        title={course.programCode}
                      >
                        {course.programCode.slice(0, 50)}...
                      </Table.Td>
                    ) : (
                      <Table.Td className="whitespace-nowrap align-middle text-sm text-primaryTextColor">
                        {course.programCode}
                      </Table.Td>
                    )}
                    <Table.Td className="table-link w-full align-middle">
                      <Link
                        className="text-primaryBlueLink dark:text-primaryTextColor"
                        to={`/${locale}/student/courses/${course.id}/groups`}
                      >
                        {course.name}
                      </Link>
                    </Table.Td>
                    <td className="text-center align-middle">{course.credits}</td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
        </>
      )}
    </div>
  )
}

interface ButtonProps {
  readonly onClick: any
  readonly label: string | React.ReactElement
  readonly isLoading: boolean
  readonly dataTip: boolean
  readonly dataFor?: string
  readonly index: number
  readonly courseId: string
  readonly tooltipText: string
}

function Button({
  onClick,
  label,
  isLoading,
  dataTip = false,
  dataFor,
  index,
  courseId,
  tooltipText,
}: ButtonProps): JSX.Element {
  const theme = useTheme()

  return (
    <>
      <button
        data-tooltip-id={dataTip ? dataFor : undefined}
        className={classnames('mb-1', {
          disabled: isLoading,
        })}
        style={{ width: '20px', height: '20px' }}
        type="button"
        onClick={onClick}
      >
        {isLoading ? <AssignmentLoading /> : label}
      </button>
      <Tooltip
        id={`${courseId}-assignmentTooltip-${index}`}
        place="left"
        variant={theme === 'dark' ? 'dark' : 'light'}
        className="mb-1 p-1"
      >
        <p className="mb-0">{tooltipText}</p>
      </Tooltip>
    </>
  )
}
