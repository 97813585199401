import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import * as Api from 'src/api'
import { PdfIcon } from 'src/assets/icons/customIcons/Pdf'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Addidtional from './Additional'
import Educations from './Educations'
import Personal from './Personal'
import Skills from './Skills'
import Trainings from './Trainings'
import Work from './Work'

export default function StudentResumePersonalPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:resume')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

async function PageContentData(
  headers: Api.getStudentResumeParams
): Promise<readonly [Api.getStudentResumeOk, Api.getCountriesOk]> {
  const data1 = Api.getStudentResume(headers)
  const data2 = Api.getCountries(headers)

  return await Promise.all([data1, data2])
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const locale = useLocale()

  const {
    data: [tabVisibility, countries],
  } = useApi({
    endpoint: PageContentData,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  return (
    <div className="resume relative flex w-full flex-col rounded-card bg-card p-[24px]">
      <div className="flex w-full justify-end">
        <a
          href={`/${locale}/student/resume/export`}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-2 flex h-fit rounded-full p-2 text-primaryTextColor hover:bg-hover-icon"
        >
          <div className="mr-1 h-fit w-[18px]">
            <PdfIcon />
          </div>
          {t('common:export')}
        </a>
      </div>

      <div className="flex flex-col justify-start">
        <SuspenseWrapper>
          <Personal show={tabVisibility.personalTabVisible} />
        </SuspenseWrapper>
        {tabVisibility.educationTabVisible && (
          <SuspenseWrapper>
            <Educations countries={countries} />
          </SuspenseWrapper>
        )}
        {tabVisibility.workTabVisible && (
          <SuspenseWrapper>
            <Work />
          </SuspenseWrapper>
        )}
        {tabVisibility.trainingsTabVisible && (
          <SuspenseWrapper>
            <Trainings countries={countries} />
          </SuspenseWrapper>
        )}
        {tabVisibility.skillsTabVisible && (
          <SuspenseWrapper>
            <Skills />
          </SuspenseWrapper>
        )}
        {tabVisibility.additionalTabVisible && (
          <SuspenseWrapper>
            <Addidtional />
          </SuspenseWrapper>
        )}
      </div>
    </div>
  )
}
