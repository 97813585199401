export function CourseProgramIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      style={{ minWidth: '12px' }}
      viewBox="0 0 12 12"
      data-testid="courseProgramIcon"
    >
      <circle id="course_program_icon" cx="6" cy="6" r="6" fill="gray" />
    </svg>
  )
}
