export function ArrowBack(): JSX.Element {
  return (
    <svg id="back_icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <rect id="Rectangle_161" data-name="Rectangle 161" width="18" height="18" fill="gray" opacity="0" />
      <rect
        id="Rectangle_304"
        data-name="Rectangle 304"
        width="16"
        height="16"
        transform="translate(1 1)"
        fill="gray"
        opacity="0"
      />
      <rect
        id="Rectangle_4220"
        data-name="Rectangle 4220"
        width="1"
        height="10"
        transform="translate(1.707 9.707) rotate(-135)"
        fill="gray"
      />
      <rect
        id="Rectangle_4222"
        data-name="Rectangle 4222"
        width="1"
        height="15"
        transform="translate(2 9.5) rotate(-90)"
        fill="gray"
      />
      <rect
        id="Rectangle_4221"
        data-name="Rectangle 4221"
        width="1"
        height="10"
        transform="translate(1 9) rotate(-45)"
        fill="gray"
      />
    </svg>
  )
}
