import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { SelectedCoursesIcon } from 'src/assets/icons/customIcons/page-icons/SelectedCourses'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function StudentCourseLayout(): JSX.Element | null {
  return (
    <React.Suspense fallback={<Loader className="m-auto flex" />}>
      <ErrorBoundary errorElement={<ErrorElement />}>
        <PageContent />
      </ErrorBoundary>
    </React.Suspense>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { id } = useParams()
  const t = useTranslatable()
  const locale = useLocale()
  const { pathname } = useLocation()

  const { data } = useApi({
    endpoint: Api.getStudentCourse,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
    shouldFetch: id != null,
  })

  const subBreadcrumbsItems = [
    { page: `${t('course:my_courses')}`, path: `/${locale}/student/courses/all` },
    { page: data?.fullName, path: pathname },
  ]

  return (
    <SubPageLayout
      Icon={SelectedCoursesIcon}
      subBreadcrubms={subBreadcrumbsItems}
      title={data?.name}
      pages={[
        {
          title: t('subPage:syllabus'),
          link: 'syllabus',
        },
        {
          title: t('subPage:groups'),
          link: 'groups',
        },
        {
          title: t('subPage:scores'),
          link: 'scores',
        },
        {
          title: t('subPage:classmates'),
          link: 'classmates',
        },
        {
          title: t('subPage:materials'),
          link: 'materials',
        },
        {
          title: t('subPage:discussion'),
          link: 'posts',
        },
      ]}
    />
  )
}
