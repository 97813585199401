import { useField } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

interface Props {
  readonly className?: string
  readonly placeholder?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly label?: string
  readonly rows?: number
  readonly cols?: number
}

export default function Checkbox({ required, ...props }: Props): React.ReactElement {
  const t = useTranslatable()
  const [{ value, ...fieldProps }, meta, helpers] = useField<boolean>({
    name: props.name,
    validate(value: boolean) {
      if (required === true) {
        if (!value) {
          return props.label !== undefined ? `${props.label} ${t('common:is_required')}` : t('common:field_is_required')
        }
      }
    },
  })
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? '!border-primaryRed' : ''
  const id = `label-${React.useId()}`

  return (
    <div className="flex flex-col">
      <div className="mr-2 flex items-center">
        <input
          type="checkbox"
          {...fieldProps}
          {...props}
          checked={value}
          onChange={() => void helpers.setValue(!value, true)}
          id={id}
          className={` ${props.className ?? ''} ${isInvalid}`}
        />
        {props.label == null ? null : (
          <label className="ml-2 text-primaryTextColor" htmlFor={id}>
            {props.label}
          </label>
        )}
      </div>
      {meta.touched && meta.error != null ? <div className="mt-2 text-sm text-errorMessages">{meta.error}</div> : null}

      {errors.map((error, i) => (
        <div key={i} className="mt-2 text-sm text-errorMessages">
          {error}
        </div>
      ))}
    </div>
  )
}
