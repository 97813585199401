import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import CoursePending from 'src/assets/icons/customIcons/course-icons/CoursePending'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentPortfolioSectionPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:personal_development')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { id } = useParams()
  const locale = useLocale()
  const { pathname } = useLocation()
  const theme = useTheme()
  const section = useApi({
    endpoint: Api.getStudentPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/student/portfolio` },
    { page: `${section.data.name}`, path: pathname },
  ]

  const { data: works, isValidating: worksPending } = useApi({
    endpoint: Api.getStudentPortfolioSectionWorks,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
    suspense: false,
  })

  return (
    <Section
      title={`${t('portfolio:portfolio')} - ${section.data.name}`}
      icon={<Portfolio />}
      breadcrubms={breadcrumbsItems}
    >
      <>
        {(() => {
          if (section.data.manage?.canSubmitWork === true) {
            return (
              <div className="!mb-9 flex items-center justify-end">
                <Link to="add">
                  <Button variant="blue" className="flex items-center">
                    <Plus color="white" /> <span className="ml-2"> {t('portfolio:add_work')}</span>
                  </Button>
                </Link>
              </div>
            )
          }
        })()}
        {(() => {
          if (works?.meta == null) return null
          if (works.data != null && works.data.length === 0) {
            return (
              <NoContent
                header={t('error:records_not_found')}
                image={Warning}
                link={`student/portfolio/sectionss/${section.data.id}/add`}
              />
            )
          }
          return (
            <div className="w-full py-3">
              {worksPending ? (
                <Loader className="flex" style={{ margin: 'auto' }} />
              ) : (
                <Table.Table className="mt-4">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th />
                      <Table.Th>{t('portfolio:work_description')}</Table.Th>
                      <Table.Th>{t('portfolio:competence')}</Table.Th>
                      <Table.Th>{t('portfolio:work_mentor')}</Table.Th>
                      <Table.Th>{t('common:date')}</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {works.data.map((work) => (
                      <Table.Tr key={work.id}>
                        <Table.Td className="px-5 text-center align-middle">
                          <div>
                            {work.status === 'STATUS_PENDING' && (
                              <div data-tooltip-id={`${work.id}-statusTooltip`}>
                                <CoursePending />
                              </div>
                            )}
                            {work.status === 'STATUS_EVALUATED' ? (
                              <div data-tooltip-id={`${work.id}-statusTooltip`}>
                                <CourseCompletedIcon />{' '}
                              </div>
                            ) : null}
                            {work.status === 'STATUS_REJECTED' && (
                              <div data-tooltip-id={`${work.id}-statusTooltip`}>
                                <CourseFailedIcon />
                              </div>
                            )}
                            <Tooltip
                              id={`${work.id}-statusTooltip`}
                              place="top"
                              variant={theme === 'dark' ? 'dark' : 'light'}
                              className="mb-1 p-1"
                            >
                              <p className="mb-0">
                                {work.status === 'STATUS_PENDING' && t('portfolio:status_pending')}
                                {work.status === 'STATUS_EVALUATED' && t('portfolio:status_evaluated')}
                                {work.status === 'STATUS_REJECTED' && t('portfolio:status_rejected')}
                              </p>
                            </Tooltip>
                          </div>
                        </Table.Td>
                        <Table.Td>
                          <Link to={`/${locale}/student/portfolio/sections/${section.data.id}/works/${work.id}`}>
                            <span className="text-primaryBlueLink" dangerouslySetInnerHTML={{ __html: work.text }} />
                          </Link>
                        </Table.Td>
                        <Table.Td>
                          {work.competencies?.map((competency, index) => (
                            <span key={competency.id}>{(index !== 0 ? ', ' : '') + competency.name}</span>
                          ))}
                        </Table.Td>
                        <Table.Td className="whitespace-nowrap pr-4 align-middle">
                          <UserPhoto className="scale-[0.7]" user={work.mentor} />{' '}
                          <Link
                            to={`/${locale}/users/${work.mentor?.uid}`}
                            className="ml-2 whitespace-nowrap align-middle hover:underline"
                            data-tooltip-id={`${work.id}-lecturer`}
                          >
                            <span className="ml-2">{work.mentor?.fullName}</span>
                          </Link>
                        </Table.Td>
                        <Table.Td className="px-5 text-center align-middle">{work.feedback}</Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table.Table>
              )}
              <Pagination pagination={works.meta.pagination} />
            </div>
          )
        })()}
      </>
    </Section>
  )
}
