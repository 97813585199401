import classnames from 'classnames'
import * as React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { Courselist } from 'src/assets/icons/customIcons/Courselist'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useTheme } from 'src/state/providers/Theme'
import { Section } from 'src/tailwind/components/Section'
import Loader from 'src/views/components/Loader'

export default function MyCoursesLayout(): React.ReactElement | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const locale = useLocale()

  const { data: tabs, isValidating } = useApi({
    endpoint: Api.getStudentAvailableTabs,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
    suspense: false,
  })

  const breadcrumbsItems = [{ page: `${t('course:my_courses')}`, path: `/${locale}/student/courses` }]

  return (
    <Section title={t('course:my_courses')} icon={<Courselist />} breadcrubms={breadcrumbsItems}>
      <div className="flex flex-col items-start">
        <div className="w-full overflow-x-scroll text-primaryTextColor">
          <ul className="relative mb-2 mt-4 flex w-full min-w-[600px] items-center" data-testid="breadcrubms">
            {isValidating || tabs == null ? (
              <Loader className="m-auto flex w-full" />
            ) : (
              tabs.map((tab, index) => {
                return (
                  <li className="z-10 text-center" style={{ width: `${100 / tabs.length}%` }} key={index}>
                    <Link tab={tab} />
                  </li>
                )
              })
            )}
            <div className="absolute bottom-0 h-[5px] w-full bg-borderGrey" />
          </ul>
        </div>
        <Outlet />
      </div>
    </Section>
  )
}

interface LinkProps {
  readonly tab: Api.AvailableCourseTab
}

const Link = ({ tab }: LinkProps): JSX.Element => {
  const locale = useLocale()
  const theme = useTheme()

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          classnames('group z-10 w-full', {
            disabled: isActive,
            active: isActive,
          })
        }
        to={`/${locale}/student/courses/${generatePath(tab)}`}
        data-tooltip-id={tab.programId?.toString()}
      >
        {({ isActive }) => (
          <div className="relative flex h-[60px] w-full flex-col justify-between">
            <div className="relative h-[30px]">
              <span
                className={`absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 font-semibold text-primaryTextColor duration-150 ease-in-out ${
                  isActive ? 'opacity-100' : 'opacity-50 group-hover:!opacity-80'
                }`}
                data-testid={`subPageLink-${tab.title}`}
              >
                {tab.title}
              </span>
            </div>
            <div
              data-testid={`subPageLink-${tab.title}-underline`}
              className={`absolute bottom-0 left-1/2 z-10 h-[5px] -translate-x-1/2 duration-300 ease-in-out group-hover:bg-[#b804349c] ${
                isActive ? 'w-full bg-[#B80433]' : 'w-0 group-hover:w-full'
              }`}
            />
          </div>
        )}
      </NavLink>
      {tab.tooltip.length > 0 && (
        <Tooltip
          id={tab.programId?.toString()}
          place="left"
          variant={theme === 'dark' ? 'dark' : 'light'}
          className="mb-1 p-1"
          positionStrategy="fixed"
        >
          <p className="mb-0">{tab.tooltip}</p>
        </Tooltip>
      )}
    </>
  )
}

function generatePath(tab: Api.AvailableCourseTab): string {
  switch (tab.type) {
    case 'ALL':
      return 'all'
    case 'GENERAL':
      return 'general'
    case 'PROGRAM':
      return `program/${tab.programId!}`
    case 'FREE':
      return 'free'
    case 'OTHER_PROGRAMS':
      return 'programs'
    default:
      return ''
  }
}
