import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentCoursesProgramsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses_other_programs')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const locale = useLocale()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const faculties = useApi({
    endpoint: Api.getStudentFacultiesPrograms,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  return (
    <>
      {faculties.data.map((faculty) => (
        <div key={faculty.id} className="w-full">
          {faculty.programs!.length > 0 && (
            <div className="flex-auto p-5 px-0">
              <div className="mb-2">
                <h5
                  className="mb-[20px] items-center text-title font-semibold text-primaryTextColor"
                  data-testid={`programName-${faculty.id}`}
                >
                  {faculty.name}
                </h5>
              </div>

              <div className="mt-2 block w-full overflow-x-auto">
                <Table.Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>{t('common:code')}</Table.Th>
                      <Table.Th>{t('program:program_name')}</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {faculty.programs?.map((program) => (
                      <Table.Tr key={program.id} data-testid={`program/${program.id}`}>
                        <Table.Td className="rounded-l-lg text-sm text-primaryTextColor" data-testid="programCode">
                          {program.code}
                        </Table.Td>
                        <Table.Td className="w-full rounded-r-lg">
                          <Link
                            to={`/${locale}/student/courses/program/${program.id}`}
                            className="text-primaryBlueLink hover:underline"
                            data-testid="programName"
                          >
                            {program.name}
                          </Link>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table.Table>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  )
}
