export function AssignmentUp(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_101_480)">
        <path
          d="M14.0002 0.000244141L2.00024 0.000244141C0.895675 0.000244141 0.000244141 0.895675 0.000244141 2.00024L0.000244141 14.0002C0.000244141 15.1048 0.895675 16.0002 2.00024 16.0002H14.0002C15.1048 16.0002 16.0002 15.1048 16.0002 14.0002V2.00024C16.0002 0.895675 15.1048 0.000244141 14.0002 0.000244141Z"
          fill="#519ED9"
        />
        <path d="M5.00024 5.00024L12.0002 5.00024V4.00024L5.00024 4.00024V5.00024Z" fill="white" />
        <path d="M12.0002 11.0002V4.00024H11.0002V11.0002H12.0002Z" fill="white" />
        <path d="M4.67222 12.0353L11.7433 4.96423L11.0362 4.25713L3.96511 11.3282L4.67222 12.0353Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_101_480">
          <rect width="16" height="16" fill="white" transform="translate(0.000244141 0.000244141)" />
        </clipPath>
      </defs>
    </svg>
  )
}
