import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentPortfolioSectionsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:general')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const fetching = useApi({
    endpoint: Api.getStudentPortfolioSections,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })
  const sections = fetching.data.data
  return (
    <>
      {sections.map((section) => (
        <div key={section.id} className="mt-2 text-primaryTextColor">
          <Link to={`/${locale}/student/portfolio/sections/${section.id}/works`}>
            <div className="mb-3 ml-3 text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]">
              {section.name}
            </div>
          </Link>
          <Table.Table className="min-w-[768px]">
            <Table.Thead>
              <Table.Tr className="w-full">
                {section.competencies?.map((competency) => (
                  <Table.Th key={competency.id} className="w-1/4 !text-center">
                    <span>{competency.name}</span>
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr key="">
                <Table.Td className="text-center">9</Table.Td>
                <Table.Td className="text-center">-</Table.Td>
                <Table.Td className="text-center">13</Table.Td>
                <Table.Td className="text-center">
                  <span className="text-primaryRed">7</span>
                </Table.Td>
                <Table.Td className="text-center">31</Table.Td>
                <Table.Td className="text-center">18</Table.Td>
                <Table.Td className="text-center">6</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.Table>
        </div>
      ))}
    </>
  )
}
