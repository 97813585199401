import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { type CoursePost } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import NoContent from 'src/views/components/NoContent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Post from './Post'

export default function StudentCourseDiscussionsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('discussion:discussion')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { id } = useParams()

  const t = useTranslatable()

  const { data: discussions } = useApi({
    endpoint: Api.getStudentCoursePosts,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  return (
    <div className="w-full">
      {discussions != null && discussions.length === 0 ? (
        <NoContent
          header={t('error:posts_not_found')}
          subHeader={t('error:discussions_not_found_for_selected_course')}
          image={Warning}
          marginTop="5"
        />
      ) : (
        discussions?.map((post: CoursePost) => <Post key={post.id} post={post} />)
      )}
    </div>
  )
}
