import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import type * as base from 'src/api/base'
import { ArrowIcon } from 'src/assets/icons/customIcons/Arrow'
import { errorsToArray } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import NoPhoto from 'src/imgs/no-photo.jpg'
import { LecturerCard } from 'src/tailwind/components/LecturerCard'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary, useErrorValue } from 'src/views/components/Error'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'

export default function StudentCoursesclassmatesPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:classmates')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const { pathname } = useLocation()
  const locale = useLocale()

  const breadcrumbsItems = [
    { page: `${t('course:my_courses')}`, path: `/${locale}/student/courses/all` },
    { page: `${t('course:classmates')}`, path: pathname },
  ]

  return (
    <Section title={t('course:my_courses')} breadcrubms={breadcrumbsItems}>
      <h4 className="mb-4 mt-2 font-bold">{t('lecturer:lecturer_s')}:</h4>
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <ChosenGroup />
        </ErrorBoundary>
      </React.Suspense>

      <h4 className="mb-4 mt-5 font-bold">{t('course:classmates')}: </h4>
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <Classmates />
        </ErrorBoundary>
      </React.Suspense>
    </Section>
  )
}

function ErrorElement(): React.ReactElement | null {
  const error = useErrorValue() as base.CommonError
  const t = useTranslatable()

  const message =
    error.type === 'ErrorsObject'
      ? error.errors.general?.[0] != null
        ? error.errors?.general?.[0]
        : 'unknown Error'
      : error.message

  return (
    <div className="my-5 w-full">
      <NoContent
        image={Warning}
        header={t('error:records_not_found')}
        subHeader={message ?? ''}
        errors={error.type === 'ErrorsObject' ? errorsToArray(error.errors) : []}
      />
    </div>
  )
}

function ChosenGroup(): JSX.Element {
  const t = useTranslatable()
  const locale = useLocale()

  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const { data: chosenGroup } = useApi({
    endpoint: Api.getStudentChosenGroup,
    params: React.useMemo(() => ({ headers, args: { id: params.id! } }), [headers, params.id]),
  })

  if (chosenGroup.lecturers!.length > 0) {
    return (
      <div className="flex w-full flex-wrap">
        {chosenGroup.lecturers!.map((lecturer) => (
          <LecturerCard
            to={`/${locale}/users/${lecturer.uid}`}
            key={lecturer.id}
            lecturer={lecturer.fullName}
            course={chosenGroup.course?.name ?? ''}
            classNames={{ topDiv: 'xl:w-[30%] xl:mr-[3%] md:w-[48%] md:mr-[2%] mr-0 w-full mb-[3%]' }}
            photo={
              <span style={{ fontSize: '1.25rem' }}>
                <img
                  src={lecturer.photoUrl != null && lecturer.photoUrl.length > 0 ? lecturer.photoUrl : NoPhoto}
                  className="rounded-full"
                  data-testid="lecturer-photo"
                />
              </span>
            }
            group={chosenGroup.name ?? ''}
            data-testid={`lecturer-${lecturer.id}`}
          />
        ))}
      </div>
    )
  }

  return <NoContent header={t('error:lecturer_s_not_found')} image={Warning} marginTop="5" />
}

function Classmates(): JSX.Element {
  const t = useTranslatable()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const { data } = useApi({
    endpoint: Api.getStudentCourseClassmates,
    params: React.useMemo(() => ({ headers, args: { id: params.id! } }), [headers, params.id]),
  })
  const classmates = data

  if (classmates.length > 0) {
    return (
      <div className="flex flex-wrap">
        {classmates.map((user: Api.User, index: number) => (
          <Link
            key={index}
            to={`/${locale}/users/${user.uid}`}
            className="hover-left-animation group mb-[18px] mr-0 flex w-full cursor-pointer flex-col rounded-card bg-card hover:bg-hover-card md:mr-[2%] md:w-[48%] xl:mr-[18px] xl:w-[30%]"
            data-testid={`classmate-${user.id}`}
          >
            <div className="flex w-full items-center justify-between p-6">
              <div className="flex items-center justify-center">
                <div className="mr-3 flex size-10 cursor-pointer items-center justify-center rounded-full bg-[#c99ff6] text-center">
                  <span style={{ fontSize: '1.25rem' }}>
                    <img
                      src={user.photoUrl != null && user.photoUrl.length > 0 ? user.photoUrl : NoPhoto}
                      className="rounded-full"
                      data-testid="classmate-photo"
                    />
                  </span>
                </div>
                <span className="font-semibold text-primaryTextColor" data-testid="name">
                  {user.fullName}
                </span>
              </div>
              <div className="duration-500 ease-in-out group-hover:mr-[-10px]">
                <ArrowIcon />
              </div>
            </div>
          </Link>
        ))}
      </div>
    )
  }

  return <NoContent header={t('error:classmates_not_found')} image={Warning} marginTop="5" />
}
