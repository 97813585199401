import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { differenceInYears } from 'date-fns'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import { MoreIcon } from 'src/assets/icons/customIcons/More'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'
import { formatDatelocale } from 'src/helpers/fns'
import { useApi, useDropdown } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth, type AuthenticatedAuthState } from 'src/hooks/auth/auth'
import { type FileState } from 'src/hooks/fileUpload'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextInput from 'src/views/components/forms/formik/TextInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import PhotoInput from '../../../../components/PhotoInput'
import SwitchButton from './SwitchButton'

interface FormikValues {
  address: string
  homePhone: string
  mobilePhone: string
  photoTitle: string
  photoUid: string | null
}

export default function StudentResumePersonalPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:resume')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="personal" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { data: personalData, mutate } = useApi({
    endpoint: Api.getStudentResumePersonal,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  const onSubmit = React.useCallback(
    async ({ photoTitle, address, homePhone, mobilePhone, photoUid }: FormikValues) => {
      await Api.postStudentResumePersonal({
        headers,
        body: {
          photoTitle,
          address,
          homePhone,
          mobilePhone,
          photoUid,
        },
      })
      toast.success(t('person:profile_updated_successfully'))
      mutate()
    },
    [headers, mutate, t]
  )

  return (
    <div className="mt-4 w-full rounded-card bg-card">
      <div className="p-9 xxs:p-2 xs:p-2">
        <div className="flex w-full flex-col rounded-xl">
          <Form
            initialValues={{
              address: personalData.address,
              homePhone: personalData.homePhone,
              mobilePhone: personalData.mobilePhone,
              photoTitle: personalData.photoTitle,
              photoUid: personalData.resumePhoto?.id ?? null,
            }}
            onSubmit={onSubmit}
            classNames={{ form: 'w-full mt-4' }}
            isConfirmable
            enableReinitialize
          >
            <FormError />
            <Header personalData={personalData} />

            <TextInput
              isHorizontal
              fixedLabelWidth
              rightAlign
              type="text"
              label={t('resume:image_title')}
              name="photoTitle"
              className="flex"
              data-testid="photoTitle"
            />
            <TextInput
              isHorizontal
              fixedLabelWidth
              rightAlign
              type="text"
              label={t('person:address')}
              name="address"
              className="flex"
              data-testid="address"
            />
            <TextInput
              isHorizontal
              fixedLabelWidth
              rightAlign
              type="tel"
              label={t('person:home_phone')}
              name="homePhone"
              className="flex"
              data-testid="homePhone"
            />
            <TextInput
              isHorizontal
              fixedLabelWidth
              rightAlign
              type="tel"
              label={t('person:mobile_phone')}
              name="mobilePhone"
              className="flex"
              data-testid="mobilePhone"
            />
            <FormSubmit
              classNames={{
                root: 'flex justify-end',
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  )
}

function Header({ personalData }: { personalData: Api.ResumePersonal }): JSX.Element {
  const t = useTranslatable()
  const [photoUrl, setPhotoUrl] = React.useState<string>('')
  const [photoLoading, setPhotoLoading] = React.useState<boolean>(false)
  const [isPhotoDeleted, setIsPhotoDeleted] = React.useState(false)
  const [files, setFiles] = React.useState<readonly FileState[]>([])
  const [isFileError, setIsFileError] = React.useState(false)
  const { show, setShow, ref, id } = useDropdown<HTMLDivElement>()

  const auth = useAuth() as AuthenticatedAuthState
  const location = useLocation()
  const formik = useFormikContext<FormikValues>()

  React.useEffect(() => void setShow(false), [location.key, setShow])

  const fileEffectState = React.useRef({ personalData, photoUid: formik.values.photoUid })
  fileEffectState.current = { personalData, photoUid: formik.values.photoUid }

  React.useEffect(() => {
    const newFileId =
      files[0]?.remote.file != null && files[0].remote.status === 'DONE'
        ? files[0].remote.file.id
        : isPhotoDeleted
          ? null
          : fileEffectState.current.personalData?.resumePhoto?.id ?? null

    if (fileEffectState.current.photoUid !== newFileId) {
      void formik.setFieldValue('photoUid', newFileId)
    }

    if (files[0]?.remote.status === 'IN-PROGRESS') {
      setPhotoLoading(true)
    }

    if (files[0]?.remote.status === 'FAILED') {
      setPhotoLoading(false)
    }

    if (files[0]?.remote.status === 'DONE') {
      setPhotoUrl(window.URL.createObjectURL(files[0]?.local.file))
      setPhotoLoading(false)
    }
  }, [isPhotoDeleted, files, formik])

  return (
    <div className="flex w-full justify-start">
      <div className="relative col-auto mb-3 flex justify-center align-middle" ref={ref}>
        <div className="h-[96px] min-w-24 max-w-24 rounded-full">
          {isPhotoDeleted ? (
            <div className="w-24">
              <NoPicture />
            </div>
          ) : isFileError ? (
            <div className="flex flex-col justify-center">
              <span className="mb-2 text-center text-primaryRed">{t('error:an_error_occurred')}</span>
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faFileArchive} style={{ height: 40 }} />
            </div>
          ) : photoUrl.length > 0 || personalData.resumePhoto?.url != null ? (
            <img
              src={photoUrl.length > 0 ? photoUrl : personalData.resumePhoto?.url ?? undefined}
              className={classnames('b-borderColor h-[96px] w-full rounded-full border bg-white object-contain', {
                'opacity-50': photoLoading,
              })}
            />
          ) : (
            <div className="w-24">
              <NoPicture />
            </div>
          )}
          {photoLoading && <FontAwesomeIcon icon={ProSolidSvgIcons.faSpinner} spin className="absolute top-5" />}
        </div>
        <div
          className={classnames('absolute left-[75px] top-[85px] z-10 inline-block rounded-card', {
            block: show,
            hidden: !show,
          })}
          style={{ boxShadow: '0px 1px 14px #00000033' }}
          aria-labelledby={id}
        >
          <div className="flex flex-col items-center justify-center rounded-card bg-primaryWhite p-2 text-primaryTextColor">
            <ul className="w-full">
              <PhotoInput
                config={UploadConfigMap.studentResumePersonal}
                setIsFileError={setIsFileError}
                setPhotoUrl={setPhotoUrl}
                setIsPhotoDeleted={setIsPhotoDeleted}
                deleteOption={!isPhotoDeleted && (photoUrl.length > 0 || personalData.resumePhoto?.url != null)}
                uploadLabel={!isPhotoDeleted && (photoUrl.length > 0 || personalData.resumePhoto?.url != null)}
                onChange={setFiles}
              />
            </ul>
          </div>
        </div>
        <div
          onClick={() => setShow(!show)}
          aria-expanded={show ? 'true' : 'false'}
          id={id}
          className="relative text-primaryTextColor"
        >
          <div className="absolute bottom-3 right-0 cursor-pointer rounded-full bg-white p-[2px] hover:bg-card">
            <MoreIcon />
          </div>
        </div>
      </div>
      <div className="ml-9 xxs:ml-2 xs:ml-2">
        <div className="mb-2 text-2xl font-bold text-primaryTextColor" data-testid="resume-fullname">
          {auth.user.firstName} {auth.user.lastName}
        </div>
        <div className="mb-2 text-title text-primaryTextColor" data-testid="resume-email">
          {auth.user.email}
        </div>
        <div className="flex text-caption">
          <span data-testid="resume-gender">{auth.user.gender === 'male' ? t('person:male') : t('person:female')}</span>
          <span className="ml-2" data-testid="resume-birthday">
            {' '}
            {differenceInYears(new Date(), formatDatelocale(auth.user.birthDate) as Date)} {t('person:years_old')}
          </span>
        </div>
      </div>
    </div>
  )
}
