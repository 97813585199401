import classnames from 'classnames'
import { t as tt } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { AssignmentDown } from 'src/assets/icons/customIcons/AssignmentDown'
import { AssignmentLoading } from 'src/assets/icons/customIcons/AssignmentLoading'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { CourseProgramIcon } from 'src/assets/icons/customIcons/course-icons/CourseProgram'
import { shortenString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'

export default function StudentCoursesFreePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses_free_credits')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const headers = useAuthenticatedHeaders()
  const [inFlight, setInFlight] = React.useState(0)
  const theme = useTheme()

  const choicesData = useApi({
    endpoint: Api.getStudentAvailableFreeChoices,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const REMOVE_ASSIGNMENT = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesRemoveAssignment({
          headers,
          args: {
            uid: choiceId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void choicesData.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [choicesData, headers, t]
  )

  if (userState.data == null) return null

  return choicesData.data.choices.length > 0 ? (
    <div className="w-full px-0 text-primaryTextColor">
      <div className="mr-3 flex items-center">
        <CourseProgramIcon />
        <span className="ml-2">
          {t('course:all_credits')} {choicesData.data.creditsAll}
        </span>
      </div>

      <div className="mb-3 mt-2 flex">
        <div className="mr-3 flex items-center">
          <CourseCompletedIcon />
          <span className="ml-2">{t('course:successfully_finished')}</span>
        </div>
        <div className="mr-3 flex items-center">
          <CourseChosenIcon />
          <span className="ml-2">{tt('course:chosen_credits', { credits: choicesData.data.creditsCurrent })}</span>
        </div>
        <div className="mr-3 flex items-center">
          <CourseFailedIcon />
          <span className="ml-2">{t('course:failed')}</span>
        </div>
      </div>

      <div className="mt-2 block w-full overflow-x-auto">
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th scope="col" />
              <Table.Th scope="col" />
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('program:program_code')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('course:course_name')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('common:cred_type')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('common:score')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('common:gain_cred')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('course:credit')}
              </Table.Th>
              <Table.Th scope="col" className="whitespace-nowrap">
                {t('course:belong')}
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {choicesData.data.choices.map((course) => (
              <Table.Tr key={course.id} data-testid={`course/${course.id}`}>
                <Table.Td className="rounded-l-lg align-middle text-sm">
                  {course.isGeneral ? (
                    <div data-tooltip-id={`${course.id}-isGeneralTooltip`}>
                      <CourseProgramIcon />
                    </div>
                  ) : (
                    <div data-tooltip-id={`${course.id}-isGeneralTooltip`}>
                      <CourseProgramIcon />
                    </div>
                  )}
                  <Tooltip
                    id={`${course.id}-isGeneralTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    <p className="mb-0">{course.isGeneral ? t('course:general_course') : t('course:program_course')}</p>
                  </Tooltip>
                </Table.Td>
                <Table.Td className="text-center align-middle">
                  {course.status === 'FAILED' && (
                    <div data-tooltip-id={`${course.id}-statusTooltip`}>
                      <CourseFailedIcon />
                    </div>
                  )}
                  {course.status === 'PASSED' ? (
                    <div data-tooltip-id={`${course.id}-statusTooltip`}>
                      <CourseCompletedIcon />
                    </div>
                  ) : null}
                  {course.status === 'CURRENT' && (
                    <div data-tooltip-id={`${course.id}-statusTooltip`}>
                      <CourseChosenIcon />
                    </div>
                  )}

                  <Tooltip
                    id={`${course.id}-statusTooltip`}
                    place="top"
                    variant={theme === 'dark' ? 'dark' : 'light'}
                    className="mb-1 p-1"
                  >
                    <p className="mb-0">
                      {course.status === 'PASSED' && t('course:successfully_finished')}
                      {course.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                      {course.status === 'FAILED' && t('course:failed')}
                    </p>
                  </Tooltip>
                </Table.Td>
                <Table.Td className="whitespace-nowrap align-middle text-sm" data-testid="courseCode">
                  {shortenString(course.courseCode, 50)}
                </Table.Td>
                <Table.Td className="w-full align-middle">
                  <Link
                    className="text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                    to={`/${locale}/student/courses/${course.course!.id}/groups`}
                    data-testid="courseName"
                  >
                    {course.courseName}
                  </Link>
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="creditType">
                  {course.creditType}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="latScore">
                  {course.isLatChoice ? course.latScore : course.score.toFixed(2)}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="credits">
                  {course.isLatChoice && course.courseCredits === 0 ? '' : course.credits}
                </Table.Td>
                <Table.Td className="text-center align-middle" data-testid="courseCredits">
                  {course.courseCredits}
                </Table.Td>
                <Table.Td className="rounded-r-lg text-center">
                  {course.assignmentStatuses?.map(
                    (status, index) =>
                      status.action === 'REMOVE_ASSIGNMENT' && (
                        <React.Fragment key={index}>
                          <button
                            data-tooltip-id={`${course.id}-assignmentTooltip-${index}`}
                            className={classnames('', {
                              disabled: { inFlight },
                            })}
                            type="button"
                            onClick={() => void REMOVE_ASSIGNMENT(course.id)}
                            data-testid="removeAssignment"
                          >
                            {inFlight > 0 ? <AssignmentLoading /> : <AssignmentDown />}
                          </button>
                          <Tooltip
                            id={`${course.id}-assignmentTooltip-${index}`}
                            place="left"
                            variant={theme === 'dark' ? 'dark' : 'light'}
                            className="mb-1 p-1"
                          >
                            <p className="mb-0">{t('course:remove_assignment')}</p>
                          </Tooltip>
                        </React.Fragment>
                      )
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.Table>
      </div>
    </div>
  ) : (
    <NoContent
      header={t('error:courses_not_found')}
      subHeader={t('error:you_do_not_have_assigned_courses_to_free_credits')}
      image={Warning}
      marginTop="5"
    />
  )
}
