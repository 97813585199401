import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import Achievement from 'src/assets/icons/customIcons/page-icons/Achievement'
import { PdfIcon } from 'src/assets/icons/customIcons/Pdf'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import PrintButton from 'src/views/components/PrintButton'

export default function StudentAchievementPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('achievement:achievement_sheet')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentCard,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const cards = data
  const t = useTranslatable()
  const locale = useLocale()
  const { pathname } = useLocation()

  const breadcrumbsItems = [{ page: `${t('achievement:achievement_sheet')}`, path: pathname }]

  return (
    <Section
      title={t('achievement:achievement_sheet')}
      subTitle={cards.facultyName}
      icon={<Achievement />}
      rightElement={
        <div className="print-export col-auto flex w-full justify-end">
          <PrintButton />
          <a
            href={`/${locale}/student/card/export`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-fit rounded-full p-2 duration-150 ease-in-out hover:bg-hover-icon"
          >
            <div className="mr-1 h-fit w-[18px]">
              <PdfIcon width="18" height="18" />
            </div>
            <span className="xxs:hidden xs:hidden">{t('common:export')}</span>
          </a>
        </div>
      }
      breadcrubms={breadcrumbsItems}
    >
      <div className="size-full overflow-visible">
        <div className="mb-3 rounded-card border border-borderGrey">
          <div className="rounded-t-card bg-card px-4 py-3">
            <div dangerouslySetInnerHTML={{ __html: '&nbsp' }} />
          </div>
          <div className="overflow-x-auto">
            <table className="w-full min-w-full">
              <tbody>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('person:lastname_firstname')}
                  </td>
                  <td className="px-4 py-2 font-bold">{cards.studentName}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('person:personal_number')}
                  </td>
                  <td className="px-4 py-2">{cards.personalNumber}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('person:birth_date')}
                  </td>
                  <td className="px-4 py-2">{cards.birthDate}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('achievement:enrollment_order')}
                  </td>
                  <td className="px-4 py-2">{cards.unidocNumber}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('course:bachelor_programme_major')}
                  </td>
                  <td className="px-4 py-2">
                    {cards.majorProgramName}
                    {cards.majorProgramDirection != null && cards.majorProgramDirection.length > 0 ? (
                      <>
                        <br />
                        <b>{t('achievement:direction')}</b>
                        {cards.majorProgramDirection}
                      </>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('course:bachelor_programme_minor')}
                  </td>
                  <td className="px-4 py-2">
                    {cards.minorProgramName}
                    {cards.minorProgramDirection != null && cards.minorProgramDirection.length > 0 ? (
                      <>
                        <br />
                        <b>{t('achievement:direction')}</b>
                        {cards.minorProgramDirection}
                      </>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }} className="border-r border-borderGrey px-4 py-2">
                    {t('achievement:average_point')}
                  </td>
                  <td className="px-4 py-2">{cards.averagePoint}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {(cards.semesters?.length ?? 0) > 0 &&
          cards.semesters?.map((semester, index) => (
            <div className="mb-3 rounded-card border border-borderGrey" key={index}>
              <div className="rounded-t-card bg-card px-4 py-3">
                <h4 className="font-bold">{semester.name}</h4>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-[768px]">
                  <tbody>
                    <tr>
                      <th className="w-[4%] border-r border-borderGrey px-2" scope="col" />
                      <th scope="col" className="border-r border-borderGrey px-4">
                        {t('course:courses')}
                      </th>
                      <th scope="col" className="w-[5%] border-r border-borderGrey px-4 py-2 text-center">
                        {t('common:credits')}
                      </th>
                      <th scope="col" className="w-[5%] px-4 py-2 text-center">
                        {t('common:score')}
                      </th>
                    </tr>
                    {semester.choices?.map((choice, index) => (
                      <tr key={choice.id}>
                        <th className="border-r border-borderGrey px-4 py-2 text-center" scope="row">
                          {index + 1}
                        </th>
                        <td className="border-r border-borderGrey px-4 py-2">{choice.courseName}</td>
                        <td className="border-r border-borderGrey px-4 py-2 text-center">
                          {choice.isLatChoice && choice.courseCredits === 0 ? '' : choice.credits}
                        </td>
                        <td className="border-r border-borderGrey px-4 py-2 text-center">
                          {choice.isLatChoice ? choice.latScore : Math.round(choice.score)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2} />
                      <td className="w-[5%] border-x border-borderGrey px-4 py-2 text-center">
                        {semester.choices?.reduce((sum, choice) => sum + choice.credits, 0)}
                      </td>
                      <td />
                    </tr>
                    {cards.semesters!.length - 1 === index && (
                      <tr>
                        <td colSpan={2} />
                        <td className="w-[5%] border-x border-borderGrey px-4 py-2 text-center">
                          {cards.semesters?.reduce(
                            (sum, semester) =>
                              sum + (semester.choices?.reduce((sum, choice) => sum + choice.credits, 0) ?? 0),
                            0
                          )}
                        </td>
                        <td />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

        <div className="mb-3 rounded-card border border-borderGrey">
          <div className="rounded-t-card bg-card px-4 py-3">
            <h4 className="font-bold">{t('achievement:grading_system')}</h4>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full min-w-[768px]">
              <tbody>
                {cards.bachelorScoringSystem?.map((scoring, index) => (
                  <tr key={index}>
                    <td style={{ width: '15%' }} className="border-r border-borderGrey px-4 py-2">
                      ({scoring.name}) {scoring.range}
                    </td>
                    <td className="px-4 py-2 font-bold">{scoring.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="rounded-card bg-card px-4 py-2 text-primaryTextColor">
          <div style={{ marginTop: 30, display: 'table' }}>
            <span style={{ width: 200, display: 'table-cell' }}>{t('faculty:dean_of_faculty_school')}:</span>
            <span style={{ width: 760, display: 'table-cell' }}> / _ _ _ _ _ _ _ _ __ _ _ _ _ _ _ _ _ _ _ _ /</span>
          </div>

          <div className="text-primaryTextColor" style={{ marginTop: 30, display: 'table' }}>
            <span style={{ width: 200, display: 'table-cell' }}>{t('achievement:performer')}:</span>
            <span style={{ width: 760, display: 'table-cell' }}> / _ _ _ _ _ _ _ _ __ _ _ _ _ _ _ _ _ _ _ _ /</span>
          </div>

          <div className="text-primaryTextColor" style={{ marginTop: 30, marginBottom: 20, display: 'table' }}>
            <span style={{ width: 200, display: 'table-cell' }}>{t('common:date')}:</span>
            <span style={{ width: 760, display: 'table-cell' }} />
          </div>
        </div>
      </div>
    </Section>
  )
}
