export function UploadIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="upload_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_4202"
          data-name="Rectangle 4202"
          width="15"
          height="1"
          transform="translate(301 576)"
          fill="gray"
        />
        <rect
          id="Rectangle_4203"
          data-name="Rectangle 4203"
          width="1"
          height="10"
          transform="translate(308 564)"
          fill="gray"
        />
        <rect
          id="Rectangle_4204"
          data-name="Rectangle 4204"
          width="1"
          height="7"
          transform="translate(308.5 562.929) rotate(45)"
          fill="gray"
        />
        <rect
          id="Rectangle_4205"
          data-name="Rectangle 4205"
          width="1"
          height="7"
          transform="translate(307.793 563.636) rotate(-45)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
