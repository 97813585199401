import { Link } from 'react-router-dom'
import { ArrowIcon } from 'src/assets/icons/customIcons/Arrow'
import { shortenString } from 'src/helpers/fns'

interface Props {
  lecturer: string
  course: string
  photo: JSX.Element
  group: string
  classNames?: {
    topDiv?: string
  }
  to: string
}

export function LecturerCard({ to, group, course, lecturer, photo, classNames, ...props }: Props): JSX.Element {
  return (
    <div
      {...props}
      className={`hover-left-animation group flex h-[183px] cursor-pointer flex-col rounded-card bg-card hover:bg-hover-card ${
        classNames?.topDiv ?? ''
      }`}
    >
      <Link to={to} data-testid="lecturer-card">
        <div className="flex w-full items-center justify-between border-b-DEFAULT border-borderColor px-6 pb-3 pt-6">
          <div className="flex items-center justify-center">
            <div className="mr-3 flex size-10 cursor-pointer items-center justify-center rounded-full bg-[#c99ff6] text-center">
              {photo}
            </div>
            <span className="font-semibold text-primaryTextColor" data-testid="name">
              {lecturer}
            </span>
          </div>
          <div className="duration-500 ease-in-out group-hover:mr-[-10px]">
            <ArrowIcon />
          </div>
        </div>
        <div className="flex flex-col justify-start px-6 pb-4 pt-3 text-primaryTextColor">
          <span data-testid="course">{shortenString(course, 55)}</span>
          <span className="mt-1 text-captionColor" data-testid="group">
            {group}
          </span>
        </div>
      </Link>
    </div>
  )
}
export default LecturerCard
