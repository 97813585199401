import classnames from 'classnames'
import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import { ProgramsIcon } from 'src/assets/icons/customIcons/page-icons/Programs'
import { errorMessage } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Card } from 'src/tailwind/components/Card'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Select from 'src/views/components/Select'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader'

export default function StudentProgramsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('program:my_programs')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const [minorFacultyId, setMinorFacultyId] = React.useState('0')
  const [minorProgramId, setMinorProgramId] = React.useState('')
  const { pathname } = useLocation()
  const majorPrograms = useApi({
    endpoint: Api.getStudentPrograms,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          'filters[type]': 'major',
        },
      }),
      [headers]
    ),
  })

  const programState = useApi({
    endpoint: Api.getStudentProgramsState,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const [customProgId, setCustomProgId] = React.useState(programState.data.customProgramId)

  const minorFaculties = useApi({
    endpoint: Api.getStudentFacultiesMinors,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const minorPrograms: APIResponse<
    Api.getStudentFacultiesMinorProgramsOk | null,
    Api.getStudentFacultiesMinorProgramsErr
  > = useApi({
    endpoint: Api.getStudentFacultiesMinorPrograms,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: minorFacultyId,
        },
      }),
      [headers, minorFacultyId]
    ),
    suspense: false,
    shouldFetch: minorFacultyId != null || minorFacultyId !== undefined,
  })

  const onSubmit = React.useCallback(async (): Promise<void> => {
    try {
      await Api.patchStudentProgramsState({
        headers,
        body: {
          customProgramId: customProgId,
          minorFacultyId,
          minorProgramId,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }, [customProgId, headers, minorFacultyId, minorProgramId])

  const SaveButton = ({ className }: { readonly className?: string }): JSX.Element => {
    return (
      <button
        className={classnames(
          'h-[48px] cursor-pointer rounded-[4px] bg-seconderyRed px-[20px] text-white hover:opacity-80',
          className
        )}
        type="button"
        onClick={() => {
          void (async () => {
            const alert = await Swal.fire<void>({
              title: t('choice:review_your_choice'),
              icon: 'warning',
              html: renderToStaticMarkup(
                <>
                  <p>
                    <strong>{t('program:major_programme')}</strong>
                  </p>
                  <p>{majorPrograms.data.find((majorProgram) => majorProgram.id === customProgId.toString())!.name}</p>
                  <p>
                    <strong>{t('faculty:minor_faculty')}</strong>
                  </p>
                  <p>
                    {minorFacultyId !== '-1' && minorFacultyId !== '0' ? (
                      minorFaculties.data.find((minorFaculty) => minorFaculty.id === minorFacultyId.toString())!.name
                    ) : (
                      <span className="text-lightSecondaryWarning">{t('program:not_chosen')}</span>
                    )}
                  </p>
                  <p>
                    <strong>{t('program:minor_programme')}</strong>
                  </p>
                  <p>
                    {minorProgramId !== '-1' && minorProgramId !== '0' ? (
                      minorPrograms.data!.find((minorProgram) => minorProgram.id === minorProgramId.toString())!.name
                    ) : (
                      <span className="text-lightSecondaryWarning">{t('program:not_chosen')}</span>
                    )}
                  </p>
                </>
              ),
              showCancelButton: true,
              confirmButtonColor: '#198754',
              cancelButtonColor: '#6C757D',
              confirmButtonText: t('common:confirm'),
              cancelButtonText: t('common:cancel'),
            })
            if (alert.isConfirmed) {
              await onSubmit()
            }
          })()
        }}
      >
        {t('common:save')}
      </button>
    )
  }

  const breadcrumbsItems = [{ page: `${t('program:my_programs')}`, path: pathname }]

  return (
    <Section
      icon={<ProgramsIcon />}
      title={t('program:my_programs')}
      subTitle={t('course:choose_programmes')}
      breadcrubms={breadcrumbsItems}
    >
      <Card>
        <form className="rounded p-4">
          <h4 className="mb-6 ml-3 font-semibold text-primaryTextColor">{t('program:major_programme')}</h4>
          <div className="mb-3 flex columns-2 flex-col text-primaryTextColor sm:flex-row">
            <label
              className={classnames('flex w-1/5 justify-start py-[16px] pr-[12px] sm:justify-end')}
              htmlFor="facultyName"
            >
              {t('faculty:faculty_name')}
            </label>
            <div className="w-full text-primaryTextColor sm:w-4/5">
              <input
                type="text"
                value={programState.data.faculty.name}
                className="w-full rounded border border-borderColor bg-borderGrey px-[12px] py-[16px]"
                id="facultyName"
                disabled
              />
            </div>
          </div>

          {programState.data.facultySchool != null && (
            <div className="mb-3 flex columns-2 flex-col text-primaryTextColor sm:flex-row">
              <label
                className={classnames('flex w-1/5 justify-start py-[16px] pr-[12px] sm:justify-end')}
                htmlFor="facultySchool"
              >
                {t('faculty:faculty_school')}
              </label>
              <div className="w-full text-primaryTextColor sm:w-4/5">
                <input
                  type="text"
                  value={programState.data.facultySchool.name}
                  className="w-full rounded border border-borderColor bg-borderGrey px-[12px] py-[16px]"
                  id="facultySchool"
                  disabled
                />
              </div>
            </div>
          )}
          <div className="mb-4 flex w-full columns-2 flex-col text-primaryTextColor sm:flex-row">
            <label
              className={classnames('flex w-1/5 columns-2 justify-start py-[16px] pr-[12px] sm:justify-end')}
              htmlFor="majorProgram"
            >
              {t('program:programme')}
            </label>

            <div className="w-full text-primaryTextColor sm:w-4/5">
              {programState.data.canChooseMajor ? (
                <Select
                  id="majorProgram"
                  placeholder={t('program:select_programme')}
                  value={majorPrograms.data.filter((program) => program.id === programState.data.customProgram.id)}
                  options={majorPrograms.data}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(option) => setCustomProgId(option!.id)}
                />
              ) : (
                <input
                  type="text"
                  value={programState.data.customProgram.name}
                  className="w-full rounded border border-borderColor bg-borderGrey px-[12px] py-[16px]"
                  id="majorProgram"
                  disabled
                />
              )}
            </div>
          </div>
          {programState.data.canChooseMinor ? (
            majorPrograms.data.find((program) => program.id === customProgId.toString())!.hasFreeCredits ? (
              <>
                <h4 className="mb-6 ml-3 font-semibold">{t('program:minor_programme')}</h4>
                <div className="mb-3 flex columns-2 flex-col text-primaryTextColor sm:flex-row">
                  <label
                    className={classnames(
                      'flex w-1/5 justify-start py-[16px] pr-[12px] text-primaryTextColor sm:justify-end'
                    )}
                    htmlFor="minorFaculty"
                  >
                    {t('faculty:faculty_name')}
                  </label>

                  <div className="w-full sm:w-4/5">
                    <Select
                      id="minorFaculty"
                      placeholder={t('faculty:choose_faculty')}
                      value={minorFaculties.data.filter((program) => program.id === minorFacultyId)}
                      options={[{ name: t('program:disable_minor'), id: '-1' }, ...minorFaculties.data]}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(option) => setMinorFacultyId(option!.id)}
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row">
                  <label
                    className={classnames(
                      'flex w-1/5 columns-2 justify-start py-[16px] pr-[12px] text-primaryTextColor sm:justify-end'
                    )}
                    htmlFor="minorProgramme"
                  >
                    {t('program:programme')}
                  </label>

                  <div className="w-full sm:w-4/5">
                    {minorPrograms.data != null &&
                      (minorPrograms.error == null ? (
                        <Select
                          id="minorProgramme"
                          placeholder={t('program:choose_programme')}
                          value={minorPrograms.data.filter((program) => program.id === minorProgramId)}
                          options={minorPrograms.data}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          onChange={(option) => setMinorProgramId(option!.id)}
                        />
                      ) : (
                        <>
                          <Select
                            id="minorProgramme"
                            placeholder={t('program:choose_programme')}
                            value={minorPrograms.data.filter((program) => program.id === minorProgramId)}
                            options={minorPrograms.data}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(option) => setMinorProgramId(option!.id)}
                          />
                          <span className="text-lightSecondaryWarning">{errorMessage(minorPrograms.error)}</span>
                        </>
                      ))}
                  </div>
                </div>
                <div className="mb-3 flex columns-2 flex-col sm:flex-row">
                  <label
                    className={classnames(
                      'flex w-1/5 justify-start py-[16px] pr-[12px] text-primaryTextColor sm:justify-end'
                    )}
                  />
                  <div>
                    <Button
                      variant="red"
                      className={classnames('mb-3', {
                        disabled: minorFacultyId !== '0' && minorFacultyId !== '-1' && minorProgramId === '0',
                      })}
                    >
                      {t('common:save')}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <label className={classnames('')} />
                  <div>
                    <SaveButton />
                    <Button variant="red">{t('common:save')}</Button>
                  </div>
                </div>
                <div className="border border-red-400 bg-red-100 text-sm text-red-700" role="alert">
                  {t('course:does_not_have_free_credits')}
                </div>
              </>
            )
          ) : (
            <div className="mb-3 flex columns-2 flex-col sm:flex-row">
              <label className={classnames('flex w-1/5 justify-start sm:justify-end')} />
              <div>
                <Button variant="red" disabled={!programState.data.canChooseMajor && !programState.data.canChooseMinor}>
                  {t('common:save')}
                </Button>
              </div>
            </div>
          )}
        </form>
      </Card>
    </Section>
  )
}
