export function SelectedCoursesIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-testid="selectedCoursesIcon"
    >
      <g id="select_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_3665"
          data-name="Rectangle 3665"
          transform="translate(305 415)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="24" height="24" rx="4" stroke="none" />
          <rect x="1" y="1" width="22" height="22" rx="3" fill="none" />
        </g>
        <path
          id="Path_86"
          data-name="Path 86"
          d="M45.251,26.378l4.789,4.184,6.8-8.284"
          transform="translate(266.067 400.42)"
          fill="none"
          className="stroke-svgColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
