import React from 'react'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface SwitchButtonProps {
  tab: Api.ResumeTab
}

export default function SwitchButton({ tab }: SwitchButtonProps): JSX.Element {
  return (
    <SuspenseWrapper>
      <PageContent tab={tab} />
    </SuspenseWrapper>
  )
}

function PageContent({ tab }: SwitchButtonProps): JSX.Element {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const data = useApi({
    endpoint: Api.getStudentResume,
    params: React.useMemo(
      () => ({ headers }),

      [headers]
    ),
  })

  const toggleVisibility = React.useCallback(
    async (visibility: boolean) => {
      try {
        await Api.patchStudentResumeTabs({
          headers,
          args: {
            ResumeTab: tab,
          },
          body: {
            visibility,
          },
        })
        void data.mutate()
        toast.success(t('common:updated_successfully'))
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t, tab, data]
  )

  return (
    <div className="flex justify-end text-sm">
      <label className="relative mb-5 inline-flex cursor-pointer items-center" data-testid="toggle-visibility">
        <input
          type="checkbox"
          value=""
          className="peer sr-only"
          defaultChecked={data.data[`${tab}TabVisible`]}
          onChange={({ target }) => {
            void toggleVisibility(target.checked)
          }}
          data-testid="toggle-visibility-checkbox"
        />
        <div className="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {' '}
          {t('resume:show_block_in_summary')}
        </span>
      </label>
    </div>
  )
}
