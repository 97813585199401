export default function BillingIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="finance_icon" transform="translate(-305 -415)">
        <g
          id="Ellipse_989"
          data-name="Ellipse 989"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
        >
          <circle cx="12" cy="12" r="12" stroke="none" />
          <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Union_24"
          data-name="Union 24"
          d="M6,16V14H8v2Zm4-2V12h1a1,1,0,0,0,1-1V10h2v1h0a3,3,0,0,1-3,3ZM4,14H3a3,3,0,0,1-3-3H2a1,1,0,0,0,1,1h7v2Zm8-4a1,1,0,0,0-1-1H10V7h1a3,3,0,0,1,3,3ZM4,9H3A3,3,0,0,1,0,6V5H2V6H2A1,1,0,0,0,3,7h7V9Zm8-4a1,1,0,0,0-1-1H10V2h1a3,3,0,0,1,3,3ZM0,5A3,3,0,0,1,3,2H6V0H8V2h2V4H3A1,1,0,0,0,2,5Z"
          transform="translate(309.999 419)"
        />
      </g>
    </svg>
  )
}
