import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, FieldArray, type FormikConfig } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import Checkbox from 'src/views/components/forms/formik/Checkbox'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import SwitchButton from './SwitchButton'

export default function StudentResumeSkillsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:personal_skills')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="skills" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { data: form, mutate } = useApi({
    endpoint: Api.getStudentResumeSkills,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  type FormValues = Omit<typeof form, 'hasDriverLicence'> & { hasDriverLicence: string }
  type FormConfig = FormikConfig<FormValues>

  const initialValues = React.useMemo(() => {
    return {
      ...form,
      hasDriverLicence: form.hasDriverLicence.toString(),
    }
  }, [form])

  const onSubmit = React.useCallback<FormConfig['onSubmit']>(
    async (values) => {
      await Api.postStudentResumeSkills({
        headers,
        body: {
          hasDriverLicence: values.hasDriverLicence === 'true',
          languages: [
            ...values.languageSkills.map((skill) => ({
              name: skill.name,
              hasCertificate: skill.hasCertificate,
              isNative: skill.isNative,
              understandingLevel:
                skill.understandingLevel != null ? Api.ResumeLanguageLevelEncoder(skill.understandingLevel) : null,
              speakingLevel: skill.speakingLevel != null ? Api.ResumeLanguageLevelEncoder(skill.speakingLevel) : null,
              readingLevel: skill.readingLevel != null ? Api.ResumeLanguageLevelEncoder(skill.readingLevel) : null,
            })),
          ],
          communications: [
            ...values.communicationSkills.map((skill) => ({
              value: skill.value,
            })),
          ],
          computers: [
            ...values.computerSkills.map((skill) => ({
              value: skill.value,
            })),
          ],
          organisationals: [
            ...values.organisationalSkills.map((skill) => ({
              value: skill.value,
            })),
          ],
          others: [
            ...values.otherSkills.map((skill) => ({
              value: skill.value,
            })),
          ],
        },
      })
      toast.success(t('person:profile_updated_successfully'))
      mutate()
    },
    [headers, mutate, t]
  )

  const handleDelete = React.useCallback(
    async (callback: () => void): Promise<void> => {
      const alert = await Swal.fire({
        title: t('common:do_you_really_want_to_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D6EFD',
        cancelButtonColor: '#6C757D',
        confirmButtonText: t('common:confirm'),
        cancelButtonText: t('common:cancel'),
      })

      if (alert.isConfirmed) {
        callback()
      }
    },
    [t]
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      classNames={{ form: 'w-full' }}
      enableReinitialize
      isConfirmable
    >
      <FormError />
      <Languages handleDelete={handleDelete} />
      <TextField name="communicationSkills" title={t('resume:communication_skill')} handleDelete={handleDelete} />
      <TextField name="organisationalSkills" title={t('resume:organisational_skill')} handleDelete={handleDelete} />
      <TextField name="computerSkills" title={t('resume:computer_skill')} handleDelete={handleDelete} />
      <TextField name="otherSkills" title={t('resume:other_skill')} handleDelete={handleDelete} />
      <div className="my-4 flex w-full flex-col rounded-card bg-card">
        <h3 className="p-4 font-bold">{t('resume:driver_licence')}</h3>
        <div className="w-full border-t border-veryLightGrey p-4">
          <div role="group" aria-labelledby="my-radio-group">
            <label className="mr-4">
              <Field type="radio" name="hasDriverLicence" value="true" />
              <span className="ml-2 text-primaryTextColor">{t('common:yes')}</span>
            </label>
            <label>
              <Field type="radio" name="hasDriverLicence" value="false" />
              <span className="ml-2 text-primaryTextColor">{t('common:no')}</span>
            </label>
          </div>
        </div>
      </div>
      <FormSubmit
        classNames={{
          root: 'flex justify-end',
        }}
      />
    </Form>
  )
}

interface TextFieldProps {
  name: string
  title: string
  handleDelete: (callback: () => void) => Promise<void>
}

function TextField({ name, title, handleDelete }: TextFieldProps): JSX.Element {
  const t = useTranslatable()

  return (
    <FieldArray name={name}>
      {({ remove, push, form }: any) => {
        return (
          <div className="my-4 flex w-full flex-col rounded-card bg-card">
            <h3 className="p-4 font-bold">{title}</h3>
            {form.values[name].map((item: string, index: number) => (
              <div
                key={index}
                className="my-2 flex w-full items-start justify-between border-t border-veryLightGrey p-4"
              >
                <div className="w-[90%]">
                  <TextInput name={`${name}.${index}.value`} placeholder={title} type="text" searchField required />
                </div>
                <button
                  title={t('common:delete')}
                  onClick={() => void handleDelete(() => remove(index))}
                  type="button"
                  className="mt-[6px] rounded-full p-2 hover:bg-hover-icon"
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <div className="w-full border-t border-veryLightGrey px-4">
              <Button
                variant="blue"
                type="button"
                className="my-4 w-full"
                onClick={() => push({ value: '' })}
                disabled={false}
              >
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
              </Button>
            </div>
          </div>
        )
      }}
    </FieldArray>
  )
}

const newLanguageSkills = {
  languageName: '',
  understandingLevel: '',
  speakingLevel: '',
  readingLevel: '',
  hasCertificate: false,
  isNative: false,
}

interface LanguagesProps {
  handleDelete: (callback: () => void) => Promise<void>
}

const Languages = React.memo(function Languages({ handleDelete }: LanguagesProps) {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const languageLevel = Api.ResumeLanguageLevelValues.map((value) => ({ value, label: value }))

  const { data: languages, isValidating } = useApi({
    endpoint: Api.getStudentResumeLanguages,
    params: React.useMemo(() => ({ headers }), [headers]),
    suspense: false,
  })

  return (
    <FieldArray name="languageSkills">
      {({ remove, push, form }: any) => (
        <div className="my-4 flex w-full flex-col rounded-card bg-card">
          <h3 className="p-4 font-bold text-primaryTextColor">{t('resume:language_skills')}</h3>
          {form.values.languageSkills.map((_: string, index: number) => (
            <div key={uuidv4()} className="my-2 w-full border-t border-veryLightGrey p-4">
              <div className="flex w-full justify-end" title={t('common:delete')}>
                <button
                  onClick={() => void handleDelete(() => remove(index))}
                  type="button"
                  className="rounded-full p-2 hover:bg-hover-icon"
                >
                  <DeleteIcon />
                </button>
              </div>
              <div className="my-1 flex flex-wrap items-start">
                <div className="flex w-full flex-wrap justify-between">
                  <SelectInput
                    name={`languageSkills.${index}.name`}
                    options={
                      languages?.map((item: Api.ResumeLanguage) => ({ value: item.name, label: item.name })) ?? []
                    }
                    disabled={isValidating}
                    isLoading={isValidating}
                    label={t('resume:language')}
                    labelAnimation
                    required
                  />
                </div>
              </div>
              <div className="flex w-full flex-wrap justify-between">
                <div className="mb-0 w-full min-w-[100px] md:w-[32%] xl:!mb-3">
                  <SelectInput
                    name={`languageSkills.${index}.understandingLevel`}
                    options={languageLevel}
                    label={t('resume:listening')}
                    labelAnimation
                    required
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[32%]">
                  <SelectInput
                    name={`languageSkills.${index}.speakingLevel`}
                    options={languageLevel}
                    label={t('resume:speaking')}
                    labelAnimation
                    required
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[32%]">
                  <SelectInput
                    name={`languageSkills.${index}.readingLevel`}
                    options={languageLevel}
                    label={t('resume:reading')}
                    labelAnimation
                    required
                  />
                </div>
              </div>
              <div className="flex w-full flex-wrap sm:w-1/2">
                <div className="my-2 mr-4">
                  <Checkbox name={`languageSkills.${index}.hasCertificate`} label={t('resume:i_have_a_certificate')} />
                </div>
                <div className="my-2">
                  <Checkbox name={`languageSkills.${index}.isNative`} label={t('resume:native_language')} />
                </div>
              </div>
            </div>
          ))}
          <div className="w-full border-t border-veryLightGrey px-4">
            <Button
              variant="blue"
              type="button"
              className="my-4 w-full"
              onClick={() => push(newLanguageSkills)}
              disabled={false}
            >
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
})
