export function MoreIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="dotmenu_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <circle
          id="Ellipse_1011"
          data-name="Ellipse 1011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(48 24)"
          fill="gray"
        />
        <circle
          id="Ellipse_1012"
          data-name="Ellipse 1012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(48 20)"
          fill="gray"
        />
        <circle
          id="Ellipse_1013"
          data-name="Ellipse 1013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(48 28)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
