export function BalanceLargePositiveIcon(): JSX.Element {
  return (
    <svg id="balance_large_positive_icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <circle id="Ellipse_371" data-name="Ellipse 371" cx="24" cy="24" r="24" fill="#9ed995" />
      <path
        id="Union_24"
        data-name="Union 24"
        d="M9,24V21h3v3Zm6-3V18h1.5A1.5,1.5,0,0,0,18,16.5V15h3v1.5h0A4.5,4.5,0,0,1,16.5,21ZM6,21H4.5A4.5,4.5,0,0,1,0,16.5H3A1.5,1.5,0,0,0,4.5,18H15v3Zm12-6a1.5,1.5,0,0,0-1.5-1.5H15v-3h1.5A4.5,4.5,0,0,1,21,15ZM6,13.5H4.5A4.5,4.5,0,0,1,0,9V7.5H3V9H3a1.5,1.5,0,0,0,1.5,1.5H15v3Zm12-6A1.5,1.5,0,0,0,16.5,6H15V3h1.5A4.5,4.5,0,0,1,21,7.5ZM0,7.5A4.5,4.5,0,0,1,4.5,3H9V0h3V3h3V6H4.5A1.5,1.5,0,0,0,3,7.5Z"
        transform="translate(13.498 12)"
        fill="#fff"
      />
    </svg>
  )
}
