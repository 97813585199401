interface Props {
  color?: string
}

export function Unselect({ color = 'gray' }: Props): JSX.Element {
  return (
    <>
      {' '}
      <svg id="unselect_icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <rect id="Rectangle_161" data-name="Rectangle 161" width="18" height="18" fill={color} opacity="0" />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(1 1)"
          fill={color}
          opacity="0"
        />
        <rect
          id="Rectangle_4243"
          data-name="Rectangle 4243"
          width="1"
          height="6"
          transform="translate(1 1)"
          fill={color}
        />
        <rect
          id="Rectangle_4246"
          data-name="Rectangle 4246"
          width="1"
          height="6"
          transform="translate(17 17) rotate(180)"
          fill={color}
        />
        <rect
          id="Rectangle_4244"
          data-name="Rectangle 4244"
          width="1"
          height="6"
          transform="translate(1 7) rotate(-90)"
          fill={color}
        />
        <rect
          id="Rectangle_4245"
          data-name="Rectangle 4245"
          width="1"
          height="6"
          transform="translate(17 11) rotate(90)"
          fill={color}
        />
        <path
          id="Subtraction_231"
          data-name="Subtraction 231"
          d="M-3940.4-1463a7.992,7.992,0,0,1-5.265-1.976,8,8,0,0,1-2.656-4.885l.954-.439a6.976,6.976,0,0,0,6.966,6.3,6.98,6.98,0,0,0,6.67-4.875h1.044A8.024,8.024,0,0,1-3940.4-1463Zm6.92-9.055h0a7.044,7.044,0,0,0-6.92-5.944,7.047,7.047,0,0,0-6.539,4.5H-3948a7.986,7.986,0,0,1,7.6-5.5,8,8,0,0,1,7.862,6.513l-.94.432Z"
          transform="translate(3949.398 1480)"
          fill={color}
        />
      </svg>
    </>
  )
}
