import classnames from 'classnames'
import { t as tt } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { AssignmentDown } from 'src/assets/icons/customIcons/AssignmentDown'
import { AssignmentLoading } from 'src/assets/icons/customIcons/AssignmentLoading'
import { AssignmentUp } from 'src/assets/icons/customIcons/AssignmentUp'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { CourseGeneralIcon } from 'src/assets/icons/customIcons/course-icons/CourseGeneral'
import { CourseProgramIcon } from 'src/assets/icons/customIcons/course-icons/CourseProgram'
import { shortenString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import { useTheme } from 'src/state/providers/Theme'

import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentCoursesAllPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:my_courses')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const headers = useAuthenticatedHeaders()
  const [inFlight, setInFlight] = React.useState(0)
  const theme = useTheme()

  const courses = useApi({
    endpoint: Api.getStudentAvailableChoices,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const ASSIGN_TO_FREE_CREDITS = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesFreeCreditsAssign({
          headers,
          args: {
            uid: choiceId,
          },
        })

        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void courses.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [courses, headers, t]
  )

  const REMOVE_ASSIGNMENT = React.useCallback(
    async (choiceId: string) => {
      setInFlight((n) => n + 1)

      try {
        await Api.postStudentChoicesRemoveAssignment({
          headers,
          args: {
            uid: choiceId,
          },
        })
        window.scroll(0, 0)
        toast.success(t('common:updated_successfully'))
        void courses.mutate()
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((n) => n - 1)
    },
    [courses, headers, t]
  )

  if (userState.data == null) return null

  return (
    <div className="w-full flex-auto p-5 px-0 text-primaryTextColor">
      <div>
        <span>
          {t('course:all_credits')} {courses.data.creditsAll}
        </span>
      </div>
      <div className="mt-3 flex flex-wrap items-center justify-start gap-3 text-xs">
        <div className="flex items-center justify-center gap-1">
          <CourseProgramIcon />
          <span>{t('course:program_course')}</span>
        </div>
        <div className="flex items-center justify-center gap-1">
          <CourseGeneralIcon />
          <span>{t('course:general_course')}</span>
        </div>
        <div className="flex items-center justify-center gap-1">
          <CourseCompletedIcon />
          <span>{t('course:successfully_finished')}</span>
        </div>
        <div className="flex items-center justify-center gap-1">
          <CourseChosenIcon />
          <span>{tt('course:chosen_credits', { credits: courses.data.creditsCurrent })}</span>
        </div>
        <div className="flex items-center justify-center gap-1">
          <CourseFailedIcon />
          <span>{t('course:failed')}</span>
        </div>
      </div>
      <Table.Table className="mt-4">
        <Table.Thead>
          <Table.Tr className="border-none">
            <Table.Th />
            <Table.Th>{t('program:program_code')}</Table.Th>
            <Table.Th className="w-[325px]">{t('course:course_name')}</Table.Th>
            <Table.Th>{t('common:cred_type')}</Table.Th>
            <Table.Th>{t('common:score')}</Table.Th>
            <Table.Th>{t('common:gain_cred')}</Table.Th>
            <Table.Th>{t('course:credit')}</Table.Th>
            <Table.Th>{t('course:belong')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {courses.data.choices.map((course) => (
            <Table.Tr key={course.id} data-testid={`course/${course.id}`}>
              <Table.Td>
                <div className="flex gap-2">
                  <div>
                    {course.isGeneral ? (
                      <div data-tooltip-id={`${course.id}-isGeneralTooltip`}>
                        <CourseGeneralIcon />{' '}
                      </div>
                    ) : (
                      <div data-tooltip-id={`${course.id}-isGeneralTooltip`}>
                        <CourseProgramIcon />
                      </div>
                    )}
                    <Tooltip
                      id={`${course.id}-isGeneralTooltip`}
                      place="top"
                      variant={theme === 'dark' ? 'dark' : 'light'}
                      className="mb-1 p-1"
                    >
                      <p className="mb-0">
                        {course.isGeneral ? t('course:general_course') : t('course:program_course')}
                      </p>
                    </Tooltip>
                  </div>
                  <div>
                    {course.status === 'FAILED' && (
                      <div data-tooltip-id={`${course.id}-statusTooltip`}>
                        <CourseFailedIcon />
                      </div>
                    )}
                    {course.status === 'PASSED' ? (
                      <div data-tooltip-id={`${course.id}-statusTooltip`}>
                        <CourseCompletedIcon />{' '}
                      </div>
                    ) : null}
                    {course.status === 'CURRENT' && (
                      <div data-tooltip-id={`${course.id}-statusTooltip`}>
                        <CourseChosenIcon />
                      </div>
                    )}
                    <Tooltip
                      id={`${course.id}-statusTooltip`}
                      place="top"
                      variant={theme === 'dark' ? 'dark' : 'light'}
                      className="mb-1 p-1"
                    >
                      <p className="mb-0">
                        {course.status === 'PASSED' && t('course:successfully_finished')}
                        {course.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                        {course.status === 'FAILED' && t('course:failed')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </Table.Td>
              <Table.Td title={course.courseCode} data-testid="courseCode">
                {shortenString(course.courseCode, 50)}
              </Table.Td>
              <Table.Td className="text-primaryBlueLink hover:underline dark:text-primaryTextColor">
                <Link to={`/${locale}/student/courses/${course.course!.id}/groups`} data-testid="courseName">
                  {course.courseName}
                </Link>
              </Table.Td>
              <Table.Td data-testid="creditType">{course.creditType}</Table.Td>
              <Table.Td data-testid="latScore">
                {course.isLatChoice ? course.latScore : course.score.toFixed(2)}
              </Table.Td>
              <Table.Td data-testid="credits">
                {course.isLatChoice && course.courseCredits === 0 ? '' : course.credits}
              </Table.Td>
              <Table.Td data-testid="courseCredits">{course.courseCredits}</Table.Td>
              <Table.Td>
                {course.assignmentStatuses?.map((status, index) => (
                  <React.Fragment key={index}>
                    {status.action === 'ASSIGN_TO_FREE_CREDITS' && (
                      <Button
                        dataTip
                        dataFor={`${course.id}-assignmentTooltip-${index}`}
                        isLoading={inFlight > 0}
                        onClick={() => void ASSIGN_TO_FREE_CREDITS(course.id)}
                        label={<AssignmentUp />}
                        index={index}
                        courseId={course.id}
                        tooltipText={t('course:assign_to_free_credits')}
                        data-testid="assignToFreeCredits"
                      />
                    )}
                    {status.action === 'REMOVE_ASSIGNMENT' && (
                      <Button
                        dataTip
                        dataFor={`${course.id}-assignmentTooltip-${index}`}
                        isLoading={inFlight > 0}
                        onClick={() => void REMOVE_ASSIGNMENT(course.id)}
                        label={<AssignmentDown />}
                        index={index}
                        courseId={course.id}
                        tooltipText={t('course:remove_assignment')}
                        data-testid="removeAssignment"
                      />
                    )}
                  </React.Fragment>
                ))}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.Table>

      {/* <div className='block w-full overflow-x-auto mt-2'>
          <table className='table table-borderless table-hover table-striped mb-0'>
            <thead>
              <tr>
                <th scope='col' />
                <th scope='col' />
                <th scope='col' className='whitespace-nowrap'>{t('program:program_code')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('course:course_name')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('common:cred_type')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('common:score')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('common:gain_cred')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('course:credit')}</th>
                <th scope='col' className='whitespace-nowrap'>{t('course:belong')}</th>
              </tr>
            </thead>
            <tbody>
              {courses.data.choices.map(course => (
                <tr key={course.id}>
                  <td className='text-sm rounded-l-lg align-middle'>
                    {course.isGeneral
                      ? <FreeSolidSvgIcons.Circle className='text-sm text-primary align-baseline' data-tooltip-id={`${course.id}-isGeneralTooltip`} />
                      : <FreeSolidSvgIcons.Circle className='text-sm text-secondary align-baseline' data-tooltip-id={`${course.id}-isGeneralTooltip`} />}
                    <Tooltip
                      id={`${course.id}-isGeneralTooltip`}
                      place='top'
                      effect='solid'
                      type='light'
                      className='p-1 mb-1'
                    >
                      <p className='mb-0'>
                        {course.isGeneral
                          ? t('course:general_course')
                          : t('course:program_course')}
                      </p>
                    </Tooltip>
                  </td>
                  <td className='text-center align-middle'>
                    {course.status === 'PASSED' && <FreeSolidSvgIcons.CheckDouble className='text-primaryGreen' data-tooltip-id={`${course.id}-statusTooltip`} />}
                    {course.status === 'CURRENT' && <FreeSolidSvgIcons.PlusSquare className='text-primaryYellow' data-tooltip-id={`${course.id}-statusTooltip`} />}
                    {course.status === 'FAILED' && <FreeSolidSvgIcons.WindowClose className='text-lightSecondaryWarning' data-tooltip-id={`${course.id}-statusTooltip`} />}
                    <Tooltip
                      id={`${course.id}-statusTooltip`}
                      place='top'
                      effect='solid'
                      type='light'
                      className='p-1 mb-1'
                    >
                      <p className='mb-0'>
                        {course.status === 'PASSED' && t('course:successfully_finished')}
                        {course.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                        {course.status === 'FAILED' && t('course:failed')}
                      </p>
                    </Tooltip>
                  </td>
                  {course.courseCode.length > 20
                    ? <td className='text-sm whitespace-nowrap align-middle' title={course.courseCode}>{course.courseCode.slice(0, 20)}...</td>
                    : <td className='text-sm whitespace-nowrap align-middle'>{course.courseCode}</td>}
                  <td className='w-full align-middle'>
                    <Link className='no-underline text-link' to={`/${locale}/student/courses/${course.course!.id}/groups`}>
                      {course.courseName}
                    </Link>
                  </td>
                  <td className='text-center align-middle'>{course.creditType}</td>
                  <td className='text-center align-middle'>{course.score}</td>
                  <td className='text-center align-middle'>{course.credits}</td>
                  <td className='text-center align-middle'>{course.courseCredits}</td>
                  <td className='text-center rounded-r-lg'>
                    {course.assignmentStatuses?.map((status, index) => (
                      <React.Fragment key={index}>
                        {status.action === 'ASSIGN_TO_FREE_CREDITS' && (
                          <Button
                            dataTip
                            dataFor={`${course.id}-assignmentTooltip-${index}`}
                            isLoading={IN_FLIGHT}
                            onClick={() => void module.ASSIGN_TO_FREE_CREDITS(course.id)}
                            label={<FreeSolidSvgIcons.Share className='flex m-auto text-sm' />}
                            index={index}
                            courseId={course.id}
                            tooltipText={t('course:assign_to_free_credits')}
                          />
                        )}
                        {status.action === 'REMOVE_ASSIGNMENT' && (
                          <Button
                            dataTip
                            dataFor={`${course.id}-assignmentTooltip-${index}`}
                            isLoading={IN_FLIGHT}
                            onClick={() => void module.REMOVE_ASSIGNMENT(course.id)}
                            label={<FreeSolidSvgIcons.Times className='flex m-auto text-sm' />}
                            index={index}
                            courseId={course.id}
                            tooltipText={t('course:remove_assignment')}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
    </div>
  )
}

interface ButtonProps {
  readonly onClick: any
  readonly label: string | React.ReactElement
  readonly isLoading: boolean
  readonly dataTip: boolean
  readonly dataFor?: string
  readonly index: number
  readonly courseId: string
  readonly tooltipText: string
}

function Button({
  onClick,
  label,
  isLoading,
  dataTip = false,
  dataFor,
  index,
  courseId,
  tooltipText,
}: ButtonProps): JSX.Element {
  const theme = useTheme()

  return (
    <>
      <button
        data-tooltip-id={dataTip ? dataFor : undefined}
        className={classnames('mb-1 mr-1 flex items-center justify-center bg-[#519ED9] p-0', {
          disabled: isLoading,
        })}
        style={{ width: '20px', height: '20px' }}
        type="button"
        onClick={onClick}
      >
        {isLoading ? <AssignmentLoading /> : label}
      </button>
      <Tooltip
        id={`${courseId}-assignmentTooltip-${index}`}
        place="left"
        variant={theme === 'dark' ? 'dark' : 'light'}
        className="mb-1 p-1"
      >
        <p className="mb-0">{tooltipText}</p>
      </Tooltip>
    </>
  )
}
