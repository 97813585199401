import * as React from 'react'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { UploadIcon } from 'src/assets/icons/customIcons/upload-icons/Upload'
import { useFileUpload, type FileState } from 'src/hooks/fileUpload'
import { useTranslatable } from 'src/hooks/locale/utils'
import { type UploadConfig } from '../../api'

interface Props {
  readonly config: UploadConfig
  readonly onChange: (files: readonly FileState[]) => void
  readonly setIsFileError: (isFileError: boolean) => void
  readonly setPhotoUrl: (photoUrl: string) => void
  readonly setIsPhotoDeleted: (isPhotoDeleted: boolean) => void
  readonly deleteOption: boolean
  readonly uploadLabel: boolean
}

export default function PhotoInput({
  onChange,
  config,
  setIsFileError,
  setPhotoUrl,
  setIsPhotoDeleted,
  uploadLabel,
  deleteOption,
}: Props): React.ReactElement | null {
  const [files, { FILE_ADD, FILE_REMOVE }] = useFileUpload({
    allowed_extensions: config.allowed_extensions,
    max_chunk_size: config.max_chunk_size,
    max_upload_size: config.max_upload_size,
    url: config.url,
  })

  const t = useTranslatable()
  const ref = React.useRef<HTMLInputElement>(null)
  const onChangeRef = React.useRef(onChange)
  onChangeRef.current = onChange

  React.useEffect(() => void onChangeRef.current(files), [files])

  React.useEffect(() => {
    files.forEach((fileState) => {
      if (fileState.remote.status === 'FAILED') {
        setIsFileError(true)
      }
    })
  }, [files, setIsFileError])

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files != null) {
      FILE_REMOVE({ index: 0 })

      setIsFileError(false)

      Array.from(e.target.files).forEach((file) => {
        FILE_ADD(file)
      })

      setIsPhotoDeleted(false)
    }
  }
  const accept = config.allowed_extensions.map((ext) => '.' + ext).join(', ')
  return (
    <>
      <li className="m-auto flex h-[42px] w-[230px] items-center justify-center gap-2 rounded-card hover:bg-tabColor">
        <UploadIcon />{' '}
        <label className="relative cursor-pointer">
          {uploadLabel ? t('file:change_photo') : t('file:upload_photo')}
          <input
            className="absolute left-0 ml-[-55px] cursor-pointer opacity-0"
            type="file"
            ref={ref}
            size={120}
            accept={accept}
            onChange={handleOnChange}
          />
        </label>
      </li>
      {deleteOption && (
        <li
          className="m-auto flex h-[42px] w-[230px] cursor-pointer items-center justify-center gap-2 rounded-card hover:bg-tabColor"
          onClick={() => {
            FILE_REMOVE({ index: 0 })
            setPhotoUrl('')
            setIsPhotoDeleted(true)
          }}
        >
          <DeleteIcon /> <a>{t('file:delete_photo')}</a>
        </li>
      )}
      {/* <button
        onClick={() => {
          files.forEach(fileState => {
            files.forEach(file => {
              FILE_REMOVE({ index: 0 })
            })
          })
          setPhotoUrl('')
          setIsFileError(false)
          setIsPhotoDeleted(false)
        }}
        disabled={!((files.length > 0 || isPhotoDeleted))}
      >
        <FreeSolidSvgIcons.Undo className='mr-4' /> {' '}
        {t('file:back_photo')}
      </button>
      <button
         onClick={() => {
          setIsPhotoDeleted(true)
          files.forEach(fileState => {
            files.forEach(file => {
              FILE_REMOVE({ index: 0 })
            })
          })
          setPhotoUrl('')
        }}
        disabled={!!(isPhotoDeleted || (personalData?.resumePhoto === undefined))}
      >
        <FreeSolidSvgIcons.TrashAlt />{' '}
        {t('file:delete_photo')}
      </button>
      <label style={{ zIndex: 0 }}>
        <FreeSolidSvgIcons.Upload />{' '}
        {t('file:change_photo')}
        <input
          className='d-none'
          type='file'
          ref={ref}
          accept={accept}
          onChange={handleOnChange}
        />
      </label>
      {files.map((fileState, index) => (
        fileState.remote.status === 'FAILED' && (
          <p key={index} className='mt-2 text-lightSecondaryWarning font-bold text-center'>{fileState.remote.message}</p>
        )
      ))} */}
    </>
  )
}
