import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from '../../../../components/Loader'

export default function StudentAchievementExportPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('common:export')} />
      <React.Suspense fallback={<Loader className="m-auto flex" style={{ height: '100vh' }} />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()

  const { data } = useApi({
    endpoint: Api.getStudentCardExport,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  location.replace(data.downloadUrl)

  return <Loader className="m-auto flex" style={{ height: '100vh' }} />
}
