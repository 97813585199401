import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray, useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatDate, formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import Checkbox from 'src/views/components/forms/formik/Checkbox'
import DateInput from 'src/views/components/forms/formik/DateInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Swal from 'sweetalert2'
import SwitchButton from './SwitchButton'

type FormikValuesType = Omit<Api.ResumeWork, 'id'>

export default function StudentResumeWorkPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:work_experience')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="work" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { data: form, mutate } = useApi({
    endpoint: Api.getStudentResumeWorks,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const initialValues = React.useMemo<{ data: FormikValuesType[] } | undefined>(() => {
    if (form != null) {
      return {
        data: form.map((work) => ({
          companyName: work.companyName,
          startDate: work.startDate,
          endDate: work.endDate,
          isCurrent: work.isCurrent,
          position: work.position,
          commitment: work.commitment,
          companyAddress: work.companyAddress,
          companySector: work.companySector,
        })),
      }
    }
  }, [form])

  const onSubmit = React.useCallback(
    async (values: { data: FormikValuesType[] }) => {
      await Api.postStudentResumeWorks({
        headers,
        body: {
          data: values.data.map((item) => ({
            ...item,
            startDate: formatDate(item.startDate)!,
            endDate: formatDate(item.endDate)!,
          })),
        },
      })
      toast.success(t('person:profile_updated_successfully'))
      mutate()
    },
    [headers, mutate, t]
  )

  return (
    <Form
      initialValues={initialValues!}
      isConfirmable
      enableReinitialize
      onSubmit={onSubmit}
      classNames={{ form: 'w-full' }}
    >
      <FormError />
      <FormContent />
    </Form>
  )
}

const newWork = {
  companyName: '',
  position: '',
  isCurrent: false,
  startDate: null,
  endDate: null,
  commitment: '',
  companyAddress: '',
  companySector: '',
}

function FormContent(): JSX.Element {
  const formik = useFormikContext<{ data: FormikValuesType[] }>()
  const t = useTranslatable()

  const handleDelete = React.useCallback(
    async (callback: () => void): Promise<void> => {
      const alert = await Swal.fire({
        title: t('common:do_you_really_want_to_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D6EFD',
        cancelButtonColor: '#6C757D',
        confirmButtonText: t('common:confirm'),
        cancelButtonText: t('common:cancel'),
      })

      if (alert.isConfirmed) {
        callback()
      }
    },
    [t]
  )

  return (
    <FieldArray name="data">
      {({ remove, push }: any) => {
        return (
          <>
            {formik.values.data.map((item: FormikValuesType, index: number) => (
              <React.Fragment key={index}>
                <div className="my-2 w-full rounded-card bg-card p-6 pt-4">
                  <div className="flex w-full justify-end">
                    <button
                      onClick={() => void handleDelete(() => remove(index))}
                      type="button"
                      className="rounded-full p-2 hover:bg-hover-icon"
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                  <div className="mb-2 flex w-full justify-end" />
                  <div className="my-1 flex flex-wrap items-start">
                    <div className="flex w-full flex-wrap justify-between">
                      <div className="mb-3 w-full md:w-[49%]">
                        <DateInput
                          labelAnimation
                          required
                          name={`data.${index}.startDate`}
                          label={t('common:start_date')}
                          maxDate={formatDatelocale(item.endDate)}
                        />
                      </div>
                      <div className="flex w-full flex-wrap md:w-[49%] md:!flex-nowrap">
                        <DateInput
                          labelAnimation
                          required={!item.isCurrent}
                          name={`data.${index}.endDate`}
                          label={t('common:end_date')}
                          minDate={formatDatelocale(item.startDate)}
                          disabled={item.isCurrent}
                        />
                        <div className="ml-1 mt-2 md:!ml-3 md:!mt-3">
                          <Checkbox name={`data.${index}.isCurrent`} label={t('common:current')} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 w-full">
                    <TextInput
                      name={`data.${index}.position`}
                      placeholder={t('resume:position')}
                      type="text"
                      searchField
                      required
                    />
                  </div>
                  <div className="mb-3 w-full">
                    <TextareaInput
                      name={`data.${index}.commitment`}
                      label={t('resume:commitment')}
                      labelAnimation
                      rows={3}
                      required
                    />
                  </div>
                  <div className="mb-3 w-full">
                    <TextInput
                      name={`data.${index}.companyName`}
                      placeholder={t('resume:company_name')}
                      type="text"
                      searchField
                      required
                    />
                  </div>
                  <div className="mb-3 flex w-full flex-wrap justify-between">
                    <div className="mb-3 mr-0 w-full min-w-[100px] md:mb-0 md:mr-2 md:w-[49%]">
                      <TextareaInput
                        name={`data.${index}.companyAddress`}
                        label={t('person:address')}
                        labelAnimation
                        rows={3}
                        required
                      />
                    </div>
                    <div className="w-full min-w-[100px] md:w-[49%]">
                      <TextareaInput
                        name={`data.${index}.companySector`}
                        label={t('resume:company_sector')}
                        labelAnimation
                        rows={3}
                        required
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
            <Button variant="blue" type="button" className="my-4 w-full" onClick={() => push(newWork)}>
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
            <div className="flex w-full justify-end rounded border-0 border-primaryGray">
              <FormSubmit
                classNames={{
                  root: 'flex justify-end',
                }}
              />
            </div>
          </>
        )
      }}
    </FieldArray>
  )
}
