export function CreditsLargeIcon(): JSX.Element {
  return (
    <svg id="credits_large_icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <circle id="Ellipse_371" data-name="Ellipse 371" cx="24" cy="24" r="24" fill="#ffd76a" />
      <path
        id="Polygon_5"
        data-name="Polygon 5"
        d="M14.052,2.82a1,1,0,0,1,1.9,0l2.58,7.674a1,1,0,0,0,.921.681l7.835.211a1,1,0,0,1,.6,1.778l-6.3,5.078a1,1,0,0,0-.335,1.05l2.242,7.94a1,1,0,0,1-1.549,1.082l-6.354-4.6a1,1,0,0,0-1.172,0l-6.354,4.6a1,1,0,0,1-1.549-1.082l2.242-7.94a1,1,0,0,0-.335-1.05l-6.3-5.078a1,1,0,0,1,.6-1.778l7.835-.211a1,1,0,0,0,.921-.681Z"
        transform="translate(9 7.5)"
        fill="#fff"
      />
    </svg>
  )
}
