export function AssignmentDown(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08997 0H14.09C15.19 0 16.09 0.9 16.09 2V14C16.09 15.1 15.19 16 14.09 16H2.08997C0.989966 16 0.0899658 15.1 0.0899658 14V2C0.0899658 0.9 0.979966 0 2.08997 0Z"
        fill="#519ED9"
      />
      <path d="M4.05505 12.036H11.0551V11.036H4.05505V12.036Z" fill="white" />
      <path d="M4.05505 12.036H5.05505L5.05505 5.03601H4.05505L4.05505 12.036Z" fill="white" />
      <path d="M5.01945 11.7688L12.0905 4.69775L11.3834 3.99065L4.31235 11.0617L5.01945 11.7688Z" fill="white" />
    </svg>
  )
}
