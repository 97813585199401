export function ArrowDown(): JSX.Element {
  return (
    <svg
      id="arrow-down"
      fill="none"
      className="inline"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="arrowDown"
    >
      <path d="M1.15918 1.27588L5.15918 5.38688L9.15918 1.27588" className="stroke-svgColor" />
    </svg>
  )
}
