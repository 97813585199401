export function Courselist(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="courseList">
      <g id="courselist_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <rect
          id="Rectangle_3694"
          data-name="Rectangle 3694"
          width="20"
          height="2"
          rx="1"
          transform="translate(309 433)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3695"
          data-name="Rectangle 3695"
          width="20"
          height="2"
          rx="1"
          transform="translate(309 426)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3696"
          data-name="Rectangle 3696"
          width="20"
          height="2"
          rx="1"
          transform="translate(309 419)"
          className="fill-svgColor"
        />
        <circle
          id="Ellipse_396"
          data-name="Ellipse 396"
          cx="1"
          cy="1"
          r="1"
          transform="translate(305 419)"
          className="fill-svgColor"
        />
        <circle
          id="Ellipse_397"
          data-name="Ellipse 397"
          cx="1"
          cy="1"
          r="1"
          transform="translate(305 426)"
          className="fill-svgColor"
        />
        <circle
          id="Ellipse_398"
          data-name="Ellipse 398"
          cx="1"
          cy="1"
          r="1"
          transform="translate(305 433)"
          className="fill-svgColor"
        />
      </g>
    </svg>
  )
}
