import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'

import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'

export default function StudentBillingSchedulePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('billing:payment_schedule_financial_information')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentBillingSchedule,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const schedule = data
  if (schedule.length === 0) {
    return <NoContent header={t('error:schedule_not_found')} image={Warning} marginTop="5" />
  }

  return (
    <>
      {schedule.map((semester, key) => (
        <div key={key} className="w-full">
          <div className="my-4 text-title font-bold text-primaryTextColor">{semester.semesterName}</div>

          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <th scope="col" className="py-[20px] pl-[24px] text-left text-title font-bold text-black">
                  {t('billing:amount')}
                </th>
                <th scope="col" className="py-[20px] text-left text-title font-bold text-black">
                  {t('billing:redistributed_amount')}
                </th>
                <th scope="col" className="py-[20px] text-left text-title font-bold text-black">
                  {t('billing:redistributed_penalty')}
                </th>
                <th scope="col" className="py-[20px] text-left text-title font-bold text-black">
                  {t('common:total')}
                </th>
                <th scope="col" className="py-[20px] pr-[24px] text-left text-title font-bold text-black">
                  {t('common:date')}
                </th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {semester.contracts.length === 0 ? (
                <Table.Tr className="pointer-events-none border-t-0 text-center">
                  <Table.Td colSpan={5}>
                    <div className="mx-[24px] my-[20px] rounded-md bg-[#e5e5e5] text-title text-black">
                      <div className="py-[15px]">{t('error:contract_not_found')}</div>
                    </div>
                  </Table.Td>
                </Table.Tr>
              ) : (
                semester.contracts.map((contract, key) => (
                  <React.Fragment key={key}>
                    <Table.Tr className="pointer-events-none border-t-0">
                      <Table.Td colSpan={5} className="rounded text-center">
                        <div className="mx-[24px] rounded-md bg-[#e5e5e5] py-[15px] text-title text-black">
                          {t('billing:contract')} {contract.name} ({contract.type})
                        </div>
                      </Table.Td>
                    </Table.Tr>

                    {contract.obligations.length === 0 ? (
                      <Table.Tr className="pointer-events-none border-t-0">
                        <Table.Td colSpan={5} className="rounded text-center">
                          <div className="mx-[24px] rounded-md bg-[#e5e5e5] py-[15px] text-black">
                            {t('error:obligations_not_found')}
                          </div>
                        </Table.Td>
                      </Table.Tr>
                    ) : (
                      contract.obligations.map((obligation, key) => (
                        <Table.Tr
                          key={key}
                          className="pointer-events-none border-t border-borderColor text-black first:border-t-0"
                        >
                          <Table.Td className="text-title">
                            <div className="px-[24px] py-[20px]">{obligation.amount} ₾ </div>
                          </Table.Td>
                          <Table.Td className="text-title">{obligation.distributedAmount} ₾</Table.Td>
                          <Table.Td className="text-title">{obligation.distributedPenalty} ₾</Table.Td>
                          <Table.Td className="text-title font-bold">
                            <strong>{obligation.totalAmount} ₾</strong>
                          </Table.Td>
                          <Table.Td className="rounded text-title">{obligation.date}</Table.Td>
                        </Table.Tr>
                      ))
                    )}
                  </React.Fragment>
                ))
              )}
            </Table.Tbody>
          </Table.Table>
        </div>
      ))}
    </>
  )
}
