import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import type * as base from 'src/api/base'
import { errorsToArray } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary, useErrorValue } from 'src/views/components/Error'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import UserPhoto from '../../../../components/UserPhoto'

export default function StudentCoursesScoresPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('common:scores')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const result = useApi({
    endpoint: Api.getStudentCourseScores,
    params: React.useMemo(() => ({ headers, args: { id: params.id! } }), [headers, params.id]),
  })
  const data = result.data
  const t = useTranslatable()
  const locale = useLocale()

  return (
    <div className="w-full">
      <div className="mb-4 flex flex-wrap items-center">
        <p className="mr-2 whitespace-nowrap font-bold" data-testid="group-name">
          {data.group?.name}
        </p>
        <div className="my-1 flex flex-wrap">
          {data.group?.lecturers?.map((lecturer) => (
            <Link
              key={lecturer.id}
              className="my-1 mr-4 flex items-center whitespace-nowrap text-primaryTextColor hover:underline"
              to={`/${locale}/users/${lecturer.uid}`}
              data-testid={`lecturer-${lecturer.id}`}
            >
              <UserPhoto user={lecturer} />
              <span className="ml-2">{lecturer.fullName}</span>
            </Link>
          ))}
        </div>
      </div>
      {(data.scores?.length ?? 0) > 0 ? (
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('course:assessment_component')}</Table.Th>
              <Table.Th className="!text-center">{t('course:component')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.scores?.map((el, index) => (
              <Table.Tr key={index} data-testid={`score-${el.criteria}`}>
                <Table.Td data-testid="criteria">{el.criteria}</Table.Td>
                <Table.Td className="text-center" data-testid="score">
                  {el.score?.toFixed(2) ?? '0.00'}
                </Table.Td>
              </Table.Tr>
            ))}
            <Table.Tr>
              <Table.Td />
              <Table.Td className="text-center">
                <span className="mr-4">{t('common:credits')}</span>
                <span data-testid="credits">{data.credits}</span>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table.Table>
      ) : (
        <NoContent header={t('error:scores_not_found')} image={Warning} marginTop="5" />
      )}
    </div>
  )
}

function ErrorElement(): React.ReactElement | null {
  const error = useErrorValue() as base.CommonError
  const t = useTranslatable()

  const message =
    error.type === 'ErrorsObject'
      ? error.errors.general?.[0] != null
        ? error.errors?.general?.[0]
        : 'unknown Error'
      : error.message

  return (
    <div className="my-5 w-full">
      <NoContent
        image={Warning}
        header={t('error:records_not_found')}
        subHeader={message ?? ''}
        errors={error.type === 'ErrorsObject' ? errorsToArray(error.errors) : []}
      />
    </div>
  )
}
