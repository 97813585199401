export default function Rating(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="rateing_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Polygon_13"
          data-name="Polygon 13"
          d="M7.216.846a.3.3,0,0,1,.569,0L9.308,5.38a.3.3,0,0,0,.276.2l4.6.124a.3.3,0,0,1,.18.533L10.688,9.2a.3.3,0,0,0-.1.315L11.9,14.17a.3.3,0,0,1-.465.325l-3.76-2.721a.3.3,0,0,0-.352,0l-3.76,2.721A.3.3,0,0,1,3.1,14.17L4.412,9.519a.3.3,0,0,0-.1-.315L.635,6.241a.3.3,0,0,1,.18-.533l4.6-.124a.3.3,0,0,0,.276-.2Z"
          transform="translate(309.5 418.75)"
          className="fill-svgColor"
        />
        <g
          id="Ellipse_987"
          data-name="Ellipse 987"
          transform="translate(305 415)"
          fill="none"
          strokeWidth="2"
          className="stroke-svgColor"
        >
          <circle cx="12" cy="12" r="12" stroke="none" />
          <circle cx="12" cy="12" r="11" fill="none" />
        </g>
      </g>
    </svg>
  )
}
