import { format } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import { formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import SummaryRow from './components/SummaryRow'

export default function Work(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const { data: works } = useApi({
    endpoint: Api.getStudentResumeWorks,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  if (works.length === 0) return null

  return (
    <div className="my-2 flex w-full flex-col">
      <SummaryRow title={t('resume:working_experience')} />

      {works.map((work, index) => (
        <SummaryRow
          key={index}
          title={
            <>
              {format(formatDatelocale(work.startDate) as Date, 'd MMMM yyyy', { locale: dateLocale })} -{' '}
              {work.endDate != null && work.endDate !== '' ? (
                format(formatDatelocale(work.endDate) as Date, 'd MMMM yyyy', { locale: dateLocale })
              ) : (
                <span className="whitespace-nowrap">{t('common:current')}</span>
              )}
            </>
          }
          content={
            <div className="flex w-4/5 flex-col">
              <p>{work.position}</p>
              <p>{work.commitment}</p>
              <p>{work.companyName}</p>
              <p>{work.companySector}</p>
              <p>{work.companyAddress}</p>
            </div>
          }
        />
      ))}
    </div>
  )
}
