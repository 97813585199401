import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import { formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import SummaryRow from './components/SummaryRow'

export default function Addidtional(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const { data: additional } = useApi({
    endpoint: Api.getStudentResumeAdditional,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  return additional.fellowships.length > 0 ||
    additional.info != null ||
    additional.publications.length > 0 ||
    additional.subjects.length > 0 ||
    additional.videoLectures.length > 0 ? (
    <div className="my-2 flex flex-col">
      <SummaryRow title={t('common:additional_information')} />

      {additional.publications.length > 0 && (
        <SummaryRow
          title={t('resume:publications')}
          content={
            <>
              {additional.publications.map((p) => (
                <div key={p.id} className="mb-3">
                  <p>{p.type}</p>
                  <p>{p.name}</p>
                  <p>{p.publisherName}</p>
                  <p> {format(formatDatelocale(p.date) as Date, 'd MMMM yyyy', { locale: dateLocale })}</p>
                  <p className="overflow-hidden">
                    <FontAwesomeIcon icon={ProRegularSvgIcons.faExternalLink} />{' '}
                    <a href={p.url} className="text-primaryBlueLink" target="_blank" rel="noreferrer">
                      {p.url}
                    </a>
                  </p>
                </div>
              ))}
            </>
          }
        />
      )}
      {additional.fellowships.length > 0 && (
        <SummaryRow
          title={t('resume:fellowships')}
          content={
            <>
              {additional.fellowships.map((f) => (
                <div key={f.id} className="mb-3">
                  <p>{f.type}</p>
                  <p>{f.organisationName}</p>
                  <p />
                  <p>
                    {format(formatDatelocale(f.startDate) as Date, 'd MMMM yyyy', { locale: dateLocale })}
                    {' - '}
                    {format(formatDatelocale(f.endDate) as Date, 'd MMMM yyyy', { locale: dateLocale })}
                  </p>
                  <p>{f.cofinansist}</p>
                </div>
              ))}
            </>
          }
        />
      )}
      {additional.subjects.length > 0 && (
        <SummaryRow
          title={t('course:courses')}
          content={
            <div className="flex flex-col">
              {additional.subjects.map((s) => (
                <p key={s.id}>{s.course}</p>
              ))}
            </div>
          }
        />
      )}
      {additional.videoLectures.length > 0 && (
        <SummaryRow
          title={t('resume:video_lectures')}
          content={
            <>
              {additional.videoLectures.map((v) => (
                <div key={v.id}>
                  <p>{v.name}</p>
                  <p className="overflow-hidden">
                    <a href={v.url} className="text-primaryBlueLink" target="_blank" rel="noreferrer">
                      {v.url}
                    </a>
                  </p>
                </div>
              ))}
            </>
          }
        />
      )}
      {additional.info != null && additional.info.length > 0 && (
        <SummaryRow title={t('common:additional_information')} content={<p>{additional.info}</p>} />
      )}
    </div>
  ) : null
}
