import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSet } from 'react-use'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import PopoverComponent from 'src/views/components/PopoverComponent'
import FormikTinyMce from 'src/views/components/forms/FormikTinyMce'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface FormikValues {
  readonly mentorId: string
  readonly text: string
  readonly id: string
  readonly files: readonly FileState[]
  readonly competencies: []
}
export default function StudentPortfolioWorkAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:portfolio_add_work')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const params = useParams()

  const navigate = useNavigate()
  const initialValues: FormikValues = { id: params.id!, mentorId: '', text: '', files: [], competencies: [] }
  const { pathname } = useLocation()

  const onSubmit = React.useCallback(
    async (
      { id, mentorId, text, files, competencies }: FormikValues,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<void> => {
      try {
        await Api.postStudentPortfolioSectionsWorks({
          headers,
          body: {
            mentorId,
            text,
            competencies,
            files: files.map((e) => e.remote.file!.id),
          },
          args: {
            id,
          },
        })
        toast.success(t('portfolio:work_submitted'))
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/student/portfolio/sections/${params.id}/works`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/student/portfolio` },
    { page: `${t('portfolio:portfolio_add_work')}`, path: pathname },
  ]
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isConfirmable={!successfullySubmited}>
      <Section title={t('portfolio:portfolio_add_work')} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
        <SuspenseWrapper>
          <FormContent />
        </SuspenseWrapper>
      </Section>
    </Form>
  )
}

function FormContent(): React.ReactElement | null {
  const { id } = useParams()
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const [competencySelections, competencySelectionActions] = useSet()
  const [fileInputId] = React.useState(() => `${id}-${Date.now()}`)
  const params = useParams()
  const mentors = useApi({
    endpoint: Api.getStudentPortfolioMentros,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const sectionCompetencies = useApi({
    endpoint: Api.getStudentPortfolioCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })
  const SELECT = React.useCallback(
    async (competencyId: string) => {
      competencySelectionActions.add(competencyId)
      void formik.setFieldValue('competencies', competencySelections)
    },
    [competencySelectionActions, competencySelections, formik]
  )

  const UNSELECT = React.useCallback(
    async (competencyId: string) => {
      competencySelectionActions.remove(competencyId)
      void formik.setFieldValue('competencies', competencySelections)
    },
    [competencySelectionActions, competencySelections, formik]
  )

  return (
    <div className="w-full rounded-card bg-card px-6 py-9">
      <FormError />
      <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
        {t('portfolio:competence')}
      </div>
      {(() => {
        if (sectionCompetencies.data.data != null) {
          return (
            <div className="mb-6">
              {sectionCompetencies.data.data.map((c) => (
                <span key={c.id} className="mb-3 mr-4 flex items-center">
                  <input
                    name="competencies"
                    type="checkbox"
                    id={c.id}
                    className="border border-tableBorderColor"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        void SELECT(c.id)
                        void formik.setFieldValue('competencies', [...formik.values.competencies, c.id])
                      } else {
                        void UNSELECT(c.id)
                      }
                    }}
                  />
                  <label className="text-primaryTextColor" htmlFor={c.id}>
                    <span className="ml-2">
                      {c.name}
                      <PopoverComponent>
                        <span className="flex p-2" dangerouslySetInnerHTML={{ __html: c.description }} />
                      </PopoverComponent>
                    </span>
                  </label>
                </span>
              ))}
            </div>
          )
        }
      })()}
      <div className="mb-6">
        <SelectInput
          placeholder={t('common:choose')}
          label={t('portfolio:mentor')}
          name="mentorId"
          options={mentors.data.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m.id })) ?? []}
          required
        />
      </div>
      <div>
        <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
          {t('portfolio:add_work_description')}
        </div>
        {/* add_work_description */}
        <FormikTinyMce name="text" />
      </div>
      <div className="my-6" style={{ zIndex: 0, maxWidth: 1065 }}>
        <FileInput
          key={fileInputId}
          multiple
          config={Api.UploadConfigMap.studentPortfolio(params.id!)}
          onChange={(files) => void formik.setFieldValue('files', files)}
        />
      </div>
      <div className="flex w-full justify-end">
        <Button type="submit" variant="red" disabled={!formik.isValid || !formik.dirty}>
          {t('message:send')}
        </Button>
      </div>
      {(() => {
        if (sectionCompetencies.data.data != null) {
          return (
            <div className="mb-6">
              {sectionCompetencies.data.data.map((criterias) =>
                criterias.criterias?.map((criteria) => (
                  <div key={criteria.id} className="my-2">
                    <label className="mb-3 text-primaryTextColor">
                      <div className="ml-2">{criteria.name} </div>
                      <span className="flex p-2" dangerouslySetInnerHTML={{ __html: criteria.description }} />
                    </label>
                  </div>
                ))
              )}
            </div>
          )
        }
      })()}
    </div>
  )
}
