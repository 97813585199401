import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'

import * as Table from 'src/tailwind/components/Table'
import SummaryRow from './components/SummaryRow'

export default function Skills(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const auth = useAuth()
  const t = useTranslatable()

  const { data: skills } = useApi({
    endpoint: Api.getStudentResumeSkills,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  if (auth.state !== 'Authenticated') return null

  return skills.communicationSkills.length > 0 ||
    skills.computerSkills.length > 0 ||
    skills.languageSkills.length > 0 ||
    skills.organisationalSkills.length > 0 ||
    skills.hasDriverLicence ||
    skills.otherSkills.length > 0 ? (
    <div className="my-2 flex flex-col">
      <SummaryRow title={t('resume:skills')} />

      {skills.languageSkills.filter((l) => l.isNative).length > 0 && (
        <SummaryRow
          title={t('resume:native_language')}
          content={
            <div className="flex flex-col">
              {skills.languageSkills
                .filter((language) => language.isNative)
                .map((lang) => (
                  <p key={lang.id}>{lang.name}</p>
                ))}
            </div>
          }
        />
      )}
      {skills.languageSkills.filter((l) => !l.isNative).length > 0 && (
        <>
          <SummaryRow
            title={t('resume:foreign_languages')}
            content={
              <div className="flex flex-col">
                {skills.languageSkills
                  .filter((language) => !language.isNative)
                  .map((lang) => (
                    <p key={lang.id}>{lang.name}</p>
                  ))}
              </div>
            }
          />

          {skills.languageSkills
            .filter((language) => !language.isNative)
            .map((lang) => (
              <SummaryRow
                key={lang.id}
                title={lang.name ?? ''}
                content={
                  <Table.Table className="table text-center">
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>{t('resume:listening')}</Table.Th>
                        <Table.Th>{t('resume:speaking')}</Table.Th>
                        <Table.Th>{t('resume:reading')}</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td className="text-start">{lang.understandingLevel}</Table.Td>
                        <Table.Td className="text-start">{lang.speakingLevel}</Table.Td>
                        <Table.Td className="text-start">{lang.readingLevel}</Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table.Table>
                }
              />
            ))}
        </>
      )}
      {skills.communicationSkills.length > 0 && (
        <SummaryRow
          title={t('resume:communication_skills')}
          content={
            <div className="flex flex-col">
              {skills.communicationSkills.map((s) => (
                <p key={s.id}>{s.value}</p>
              ))}
            </div>
          }
        />
      )}
      {skills.organisationalSkills.length > 0 && (
        <SummaryRow
          title={t('resume:organisational_skills')}
          content={
            <div className="flex flex-col">
              {skills.organisationalSkills.map((s) => (
                <p key={s.id}>{s.value}</p>
              ))}
            </div>
          }
        />
      )}
      {skills.computerSkills.length > 0 && (
        <SummaryRow
          title={t('resume:computer_skills')}
          content={
            <div className="flex flex-col">
              {skills.computerSkills.map((s) => (
                <p key={s.id}>{s.value}</p>
              ))}
            </div>
          }
        />
      )}
      {skills.otherSkills.length > 0 && (
        <SummaryRow
          title={t('resume:additional_skills')}
          content={
            <div className="flex flex-col">
              {skills.otherSkills.map((s) => (
                <p key={s.id}>{s.value}</p>
              ))}
            </div>
          }
        />
      )}
      <SummaryRow
        title={t('resume:driver_licence')}
        content={<div className="flex flex-col">{skills.hasDriverLicence ? t('common:yes') : t('common:no')}</div>}
      />
    </div>
  ) : null
}
