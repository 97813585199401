import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Loader from '../../../../components/Loader'

export default function StudentResumeExportPage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('common:export')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()

  const { data } = useApi({
    endpoint: Api.getStudentResumeExport,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  location.replace(data.downloadUrl)

  return <Loader className="m-auto flex" style={{ height: '100vh' }} />
}
