export default function Resume(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-testid="resumeIcon"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4200"
            data-name="Rectangle 4200"
            width="8"
            height="5"
            transform="translate(313 422)"
            className="fill-primaryWhite"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="account_icon" className="fill-svgColor" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-svgColor"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" className="fill-svgColor" />
          <rect className="fill-svgColor" x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_4198"
          data-name="Rectangle 4198"
          transform="translate(308 415)"
          fill="none"
          className="stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="18" height="24" rx="2" stroke="none" />
          <rect x="1" y="1" width="16" height="22" rx="1" fill="none" />
        </g>
        <rect
          id="Rectangle_4191"
          data-name="Rectangle 4191"
          width="8"
          height="2"
          rx="1"
          transform="translate(313 429)"
        />
        <rect
          id="Rectangle_4192"
          data-name="Rectangle 4192"
          width="8"
          height="2"
          rx="1"
          transform="translate(313 433)"
        />
        <circle id="Ellipse_986" data-name="Ellipse 986" cx="2" cy="2" r="2" transform="translate(315 418)" />
        <g id="Mask_Group_53" data-name="Mask Group 53" clipPath="url(#clip-path)">
          <g
            id="Rectangle_4199"
            data-name="Rectangle 4199"
            transform="translate(313 422)"
            fill="none"
            className="stroke-svgColor"
            strokeWidth="2"
          >
            <path d="M3,0H5A3,3,0,0,1,8,3V9A0,0,0,0,1,8,9H0A0,0,0,0,1,0,9V3A3,3,0,0,1,3,0Z" stroke="none" />
            <path d="M3,1H5A2,2,0,0,1,7,3V7A1,1,0,0,1,6,8H2A1,1,0,0,1,1,7V3A2,2,0,0,1,3,1Z" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  )
}
