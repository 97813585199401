import React from 'react'
import { PrintIcon } from 'src/assets/icons/customIcons/Print'
import { useTranslatable } from 'src/hooks/locale/utils'

const PrintButton: React.FC = () => {
  const t = useTranslatable()
  const handlePrint = (): void => {
    window.print()
  }

  return (
    <button
      onClick={handlePrint}
      className="flex cursor-pointer rounded-full p-2 duration-150 ease-in-out hover:bg-hover-icon"
    >
      <span className="mr-1">
        {' '}
        <PrintIcon />
      </span>
      <span className="xxs:hidden xs:hidden">{t('common:print')}</span>
    </button>
  )
}

export default PrintButton
