import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentCourseSyllabusPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('syllabus:syllabus')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const result = useApi({
    endpoint: Api.getStudentCourseSyllabus,
    params: React.useMemo(() => ({ headers, args: { id: params.id! } }), [headers, params.id]),
  })
  const data = result.data
  const t = useTranslatable()

  return (
    <div className="w-full overflow-x-scroll text-primaryTextColor">
      <div className="min-w-[1070px] flex-auto p-5">
        {data.syllabusFile != null && (
          <dt className="text-end">
            {t('syllabus:syllabus_file')}:{' '}
            <a
              href={`/${locale}/media-files/${data.syllabusFile.id}/download`}
              className="text-primaryBlueLink no-underline"
              target="_blank"
              rel="noreferrer"
              data-testid="downloadSyllabusFile"
            >
              <FontAwesomeIcon icon={ProRegularSvgIcons.faDownload} /> {data.syllabusFile.title}
            </a>
          </dt>
        )}
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="duration">
            {t('course:duration')}
          </p>
          {data.duration != null ? (
            <p
              className="syllabus-content text-title"
              data-testid="durationParagraph"
              dangerouslySetInnerHTML={{ __html: data.duration ?? '' }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="hours">
            {t('course:hours')}
          </p>
          {data.hours.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="hoursParagraph"
              dangerouslySetInnerHTML={{ __html: data.hours }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="lecturers">
            {t('lecturer:lecturers')}
          </p>
          {data.lecturers.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="lecturersParagraph"
              dangerouslySetInnerHTML={{ __html: data.lecturers }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          {data.prerequisites.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="prerequisitesParagraph"
              dangerouslySetInnerHTML={{ __html: data.prerequisites }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="methods">
            {t('syllabus:methods')}
          </p>
          {data.methods.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="methodsParagraph"
              dangerouslySetInnerHTML={{ __html: data.methods }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="mission">
            {t('syllabus:mission')}
          </p>
          {data.mission.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="missionParagraph"
              dangerouslySetInnerHTML={{ __html: data.mission }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="topics">
            {t('syllabus:topics')}
          </p>
          {data.topics.length > 0 ? (
            <p className="text-title" data-testid="topicsParagraph" dangerouslySetInnerHTML={{ __html: data.topics }} />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="outcomes">
            {t('syllabus:outcomes')}
          </p>
          {data.outcomes.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="outcomesParagraph"
              dangerouslySetInnerHTML={{ __html: data.outcomes }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="evaluation">
            {t('syllabus:evaluation')}
          </p>
          {data.evaluation.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="evaluationParagraph"
              dangerouslySetInnerHTML={{ __html: data.evaluation }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="resources">
            {t('syllabus:resources')}
          </p>
          {data.resources.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="resourcesParagraph"
              dangerouslySetInnerHTML={{ __html: data.resources }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="otherResources">
            {t('syllabus:other_resources')}
          </p>
          {data.otherResources.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="otherResourcesParagraph"
              dangerouslySetInnerHTML={{ __html: data.otherResources }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
        <div className="mb-6">
          <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold" data-testid="schedule">
            {t('schedule:schedule')}
          </p>
          {data.schedule.length > 0 ? (
            <p
              className="syllabus-content text-title"
              data-testid="scheduleParagraph"
              dangerouslySetInnerHTML={{ __html: data.schedule }}
            />
          ) : (
            <span className="flex justify-center">-</span>
          )}
        </div>
      </div>
    </div>
  )
}
