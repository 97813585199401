import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import Rating from 'src/assets/icons/customIcons/page-icons/Rating'
import RatingSection from 'src/assets/icons/customIcons/RatingSection'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from '../../../../components/Loader'

export default function StudentRatingPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('person:rating')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const { pathname } = useLocation()
  const headers = useAuthenticatedHeaders()
  const result = useApi({
    endpoint: Api.getStudentRating,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const ratings = result.data

  const breadcrumbsItems = [{ page: `${t('person:rating')}`, path: pathname }]

  return (
    <Section title={t('person:rating')} icon={<Rating />} breadcrubms={breadcrumbsItems}>
      <div className="flex flex-col items-start justify-between rounded-card bg-card p-4 text-center">
        <div className="mb-3 flex items-center">
          <RatingSection />
          <div className="ml-2 flex flex-col items-start">
            <small className="text-caption">{t('person:rating')}</small>
            <h4 className="text-headline font-bold xxs:text-[20px] xs:text-[20px]">
              {ratings.averagePoint?.toFixed(2) ?? '0.00'}
            </h4>
          </div>
        </div>
        <p className="ml-2 text-caption">{t('person:current_rating')}</p>
      </div>
    </Section>
  )
}
