import { createStateContext } from 'react-use'

export interface State {
  inFlight: number
}

const [useState, StateProvider] = createStateContext<State>({
  inFlight: 0,
})

export const RechooseProvider = StateProvider
export const useRechooseState = useState
