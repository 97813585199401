import classnames from 'classnames'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { CourseIcon } from 'src/assets/icons/customIcons/Course'
import { DropDownIcon } from 'src/assets/icons/customIcons/Dropdown'
import { GroupIcon } from 'src/assets/icons/customIcons/Group'
import { InfoIcon } from 'src/assets/icons/customIcons/Info'
import { PinIcon } from 'src/assets/icons/customIcons/Pin'
import TypeIcon from 'src/assets/icons/customIcons/TypeIcon'
import { UserIcon } from 'src/assets/icons/customIcons/menu-icons/User'
import CalendarIcon from 'src/assets/icons/customIcons/page-icons/Calendar'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { MySchedule } from 'src/tailwind/components/MySchedule'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from '../../../../components/Loader'
import NoContent from '../../../../components/NoContent'
import UserPhoto from '../../../../components/UserPhoto'

export default function StudentSchedulePage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('schedule:my_schedule')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { pathname } = useLocation()
  const theme = useTheme()
  const result = useApi({
    endpoint: Api.getStudentSchedulesCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const weekdays = result.data
  const t = useTranslatable()
  const weekDays = [
    t('calendar:sunday'),
    t('calendar:monday'),
    t('calendar:tuesday'),
    t('calendar:wednesday'),
    t('calendar:thursday'),
    t('calendar:friday'),
    t('calendar:saturday'),
  ]
  const d = new Date()
  const currentDay = weekDays[d.getDay()]

  const breadcrumbsItems = [{ page: `${t('schedule:my_schedule')}`, path: pathname }]

  return (
    <Section title={t('schedule:my_schedule')} icon={<CalendarIcon />} breadcrubms={breadcrumbsItems}>
      {weekdays.length > 0 ? (
        <>
          {weekdays.map((weekday, i, whole) => (
            <MySchedule
              key={i}
              weekday={weekday.name}
              date={weekday.date}
              index={i}
              currentDay={currentDay}
              lastIndex={whole.length - 1}
            >
              <Table.Table
                className="border-separate border-spacing-y-2 px-3 py-1"
                style={{
                  backgroundColor: currentDay === weekday.name ? (theme === 'dark' ? 'rgb(44 49 68)' : '#dee4fa') : '',
                }}
              >
                <Table.Thead>
                  <Table.Tr className="border-none text-caption">
                    <Table.Th className="w-[131px] pb-3">
                      <div className="flex items-center">
                        <ClockIcon />
                        <span className="ml-2 font-standard">{t('common:time')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="pb-3">
                      <div className="flex items-center">
                        <PinIcon />
                        <span className="ml-2 font-standard">{t('common:room')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[300px] pb-3">
                      <div className="flex items-center">
                        <CourseIcon />
                        <span className="ml-2 font-standard">{t('course:course')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[120px] pb-3">
                      <div className="flex items-center">
                        <GroupIcon />
                        <span className="ml-2 font-standard">{t('group:group')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[220px] pb-3">
                      <div className="flex items-center">
                        <UserIcon />
                        <span className="ml-2 font-standard">{t('lecturer:lecturer')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="pb-3">
                      <div className="flex items-center">
                        <TypeIcon />
                        <span className="ml-2 font-standard">{t('common:type')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="pb-3">
                      <div className="flex items-center">
                        <InfoIcon />
                        <span className="ml-2 font-standard">{t('common:information')}</span>
                      </div>
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody className="align-top">
                  {weekday.schedules.map((schedule, i) => (
                    <ScheduleRow key={schedule.id} schedule={schedule} i={i} schedules={weekday.schedules} />
                  ))}
                </Table.Tbody>
              </Table.Table>
            </MySchedule>
          ))}
        </>
      ) : (
        <NoContent
          image={Warning}
          header={t('schedule:your_schedule_is_empty')}
          subHeader={t('course:you_have_no_selected_courses_in_current_semester')}
        />
      )}
    </Section>
  )
}

interface ScheduleRowProps {
  schedule: Api.Schedule
  i: number
  schedules: readonly Api.Schedule[]
}

function ScheduleRow({ schedule, i, schedules }: ScheduleRowProps): JSX.Element {
  const [show, setShow] = React.useState(false)
  const locale = useLocale()
  const t = useTranslatable()
  const theme = useTheme()

  return (
    <Table.Tr
      data-tooltip-id={schedule.id}
      className={classnames('border-none duration-150 ease-in-out hover:bg-black/5', {
        'bg-primaryRed/10 hover:!bg-primaryRed/30': schedule.duplicate != null,
      })}
    >
      <Table.Td
        className={`rounded-l-lg !py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}
      >
        {schedule.hour.times}
        {schedule.duplicate != null && (
          <Tooltip id={schedule.id} clickable variant={theme === 'dark' ? 'dark' : 'light'} className="max-w-[90%]">
            {t('course:lecture_conflict')}
            {schedules.find((scheduleItem) => scheduleItem.id === schedule.duplicate!.id)?.course.fullName}
          </Tooltip>
        )}
      </Table.Td>
      <Table.Td className={`!py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        {schedule.locationName}
      </Table.Td>
      <Table.Td className={`!py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        <Link
          className="text-m align-middle text-primaryBlueLink hover:underline dark:text-primaryTextColor"
          to={`/${locale}/student/courses/${schedule.course.id}/syllabus`}
        >
          {schedule.course.fullName}
        </Link>
      </Table.Td>
      <Table.Td className={`!py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        {schedule.group.name}
      </Table.Td>
      <Table.Td className={`!px-2 !py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        <div>
          {!show
            ? schedule.lecturers?.map(
                (lecturer, index) =>
                  index < 2 && (
                    <div className="mb-1 flex flex-nowrap items-center text-primaryTextColor" key={lecturer.id}>
                      <UserPhoto user={lecturer} />
                      <Link
                        className="text-m ml-2 whitespace-nowrap align-middle text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                        to={`/${locale}/users/${lecturer.uid}`}
                      >
                        {lecturer.fullName}
                      </Link>
                    </div>
                  )
              )
            : schedule.group.lecturers?.map((lecturer) => (
                <div className="mb-1 flex flex-nowrap items-center" key={lecturer.id}>
                  <UserPhoto user={lecturer} />
                  <Link
                    className="text-m ml-2 whitespace-nowrap align-middle text-primaryBlueLink"
                    to={`/${locale}/users/${lecturer.uid}`}
                  >
                    {lecturer.fullName}
                  </Link>
                </div>
              ))}
          {schedule.group.lecturers != null && schedule.group.lecturers?.length > 2 && (
            <button className="ml-2 flex items-center" onClick={() => setShow(!show)}>
              <div className={`duration-150 ease-in-out ${show ? 'rotate-180' : ''}`}>
                <DropDownIcon />
              </div>
              <span className="ml-1">{t('lecturer:all_lecturers')}</span>
            </button>
          )}
        </div>
      </Table.Td>
      <Table.Td className={`!py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        {schedule.lectureTypeName}
      </Table.Td>
      <Table.Td
        className={`rounded-r-lg !py-[12px] text-primaryTextColor ${schedules.length - 1 === i ? '!pb-6' : ''}`}
      >
        {schedule.info}
      </Table.Td>
    </Table.Tr>
  )
}
