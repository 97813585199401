export default function CoursePartial(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" data-testid="coursePartialIcon">
      <g id="course_partial_icon" transform="translate(-204 -1468)">
        <circle id="course_partial_icon" cx="6" cy="6" r="6" transform="translate(204 1468)" fill="#ffd76a" />
        <rect
          id="Rectangle_4223"
          data-name="Rectangle 4223"
          width="2"
          height="2"
          transform="translate(207 1471)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4224"
          data-name="Rectangle 4224"
          width="2"
          height="2"
          transform="translate(211 1475)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4225"
          data-name="Rectangle 4225"
          width="2"
          height="2"
          transform="translate(207 1475)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4226"
          data-name="Rectangle 4226"
          width="2"
          height="2"
          transform="translate(211 1471)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
