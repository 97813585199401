export function Recommendations(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="recomendations_icon" transform="translate(-305 -415)">
        <rect
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          width="24"
          height="24"
          transform="translate(305 415)"
          opacity="0"
        />
        <g id="Path_25091" data-name="Path 25091" transform="translate(305 1003)" fill="none">
          <path
            d="M9-567H4.5A4.5,4.5,0,0,1,0-571.5v-12A4.5,4.5,0,0,1,4.5-588h15a4.5,4.5,0,0,1,4.5,4.5v12a4.5,4.5,0,0,1-4.5,4.5H15l-3,3Z"
            stroke="none"
          />
          <path
            d="M 11.9998779296875 -566.8291015625 L 14.17121982574463 -569.00048828125 L 19.49916076660156 -569.00048828125 C 20.87812995910645 -569.00048828125 22 -570.1217651367188 22 -571.5 L 22 -583.5001220703125 C 22 -584.8783569335938 20.87812995910645 -585.9996337890625 19.49916076660156 -585.9996337890625 L 4.499489784240723 -585.9996337890625 C 3.121269941329956 -585.9996337890625 2 -584.8783569335938 2 -583.5001220703125 L 2 -571.5 C 2 -570.1217651367188 3.121269941329956 -569.00048828125 4.499489784240723 -569.00048828125 L 9.828140258789062 -569.00048828125 L 11.9998779296875 -566.8291015625 M 12 -564.000732421875 L 8.999799728393555 -567.00048828125 L 4.499489784240723 -567.00048828125 C 2.014179944992065 -567.00048828125 -1.77635683940025e-15 -569.0146484375 -1.77635683940025e-15 -571.5 L -1.77635683940025e-15 -583.5001220703125 C -1.77635683940025e-15 -585.9854736328125 2.014179944992065 -587.9996337890625 4.499489784240723 -587.9996337890625 L 19.49916076660156 -587.9996337890625 C 21.9844799041748 -587.9996337890625 24 -585.9854736328125 24 -583.5001220703125 L 24 -571.5 C 24 -569.0146484375 21.9844799041748 -567.00048828125 19.49916076660156 -567.00048828125 L 14.99965953826904 -567.00048828125 L 12 -564.000732421875 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
        <g id="Path_25092" data-name="Path 25092" transform="translate(309.032 1006.465)" fill="none">
          <path
            d="M2.134-580.563a3.749,3.749,0,0,1,0-5.3,3.75,3.75,0,0,1,5.3,0l.53.53.53-.53a3.75,3.75,0,0,1,5.3,0,3.751,3.751,0,0,1,0,5.3l-.531.53h0l-5.3,5.3Z"
            stroke="none"
          />
          <path
            d="M 7.967239856719971 -577.5580444335938 L 10.83752536773682 -580.4276123046875 L 10.83745193481445 -580.427734375 L 12.38679122924805 -581.9766235351562 C 13.06871128082275 -582.6592407226562 13.06872177124023 -583.7698364257812 12.38744163513184 -584.4517211914062 C 12.05679130554199 -584.7824096679688 11.61720180511475 -584.9644775390625 11.1496410369873 -584.9644775390625 C 10.68208122253418 -584.9644775390625 10.24248123168945 -584.7823486328125 9.911861419677734 -584.4517211914062 L 7.967711448669434 -582.5074462890625 L 6.023631572723389 -584.4517211914062 C 5.692971706390381 -584.7823486328125 5.253371715545654 -584.9644775390625 4.785811424255371 -584.9644775390625 C 4.318261623382568 -584.9644775390625 3.878661394119263 -584.7823486328125 3.54798150062561 -584.45166015625 C 2.865811586380005 -583.76953125 2.865821599960327 -582.6594848632812 3.547991514205933 -581.977294921875 L 7.967239856719971 -577.5580444335938 M 7.96705150604248 -574.7297973632812 L 2.133771419525146 -580.5631103515625 C 0.6690415143966675 -582.0278930664062 0.6690415143966675 -584.4011840820312 2.133771419525146 -585.8659057617188 C 3.598501682281494 -587.3306274414062 5.973121643066406 -587.3306274414062 7.437861442565918 -585.8659057617188 L 7.967721462249756 -585.3359985351562 L 8.497591972351074 -585.8659057617188 C 9.962321281433105 -587.3306274414062 12.33695220947266 -587.3306274414062 13.80168151855469 -585.8659057617188 C 15.265061378479 -584.4011840820312 15.265061378479 -582.0278930664062 13.80168151855469 -580.5631103515625 L 13.271071434021 -580.0326538085938 L 13.27113151550293 -580.0325317382812 L 7.96705150604248 -574.7297973632812 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
      </g>
    </svg>
  )
}
