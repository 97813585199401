import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import FeedbackIcon from 'src/assets/icons/customIcons/page-icons/Feedback'
import { formatFileSize } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileIcon from 'src/views/components/FileIcon'
import Loader from 'src/views/components/Loader'

export default function StudentFeedbackPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('feedback:feedback')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const locale = useLocale()
  const params = useParams()
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams()
  const semId = searchParams.get('semId')
  const { pathname } = useLocation()
  const result = useApi({
    endpoint: Api.getStudentFeedback,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
        args: {
          id: params.feedbackId!,
        },
      }),
      [headers, params.feedbackId, semId]
    ),
  })
  const feedback = result.data

  const breadcrumbsItems = [
    { page: `${t('feedback:feedback')}`, path: `/${locale}/student/feedbacks` },
    { page: `${feedback.subject.name}`, path: pathname },
  ]

  return (
    <Section title={t('feedback:feedback_message')} icon={<FeedbackIcon />} breadcrubms={breadcrumbsItems}>
      {feedback.mediaFiles != null && feedback.mediaFiles.length > 0 && (
        <div className="row-auto my-3">
          <div className="col-auto mb-2">
            <p className="mb-0 font-bold">{t('file:attached_files')}</p>
          </div>
          <div className="col-auto flex flex-wrap">
            {feedback.mediaFiles.map((file) => (
              <div key={file.id} className="mr-4 flex" data-testid={`feedbackMediaFiles/${file.id}`}>
                <FileIcon type={file.extension} />
                <a
                  href={`/${locale}/media-files/${file.id}/download`}
                  className="mx-2 dark:text-white"
                  rel="noreferrer"
                  target="_blank"
                  data-testid="originalName"
                >
                  {file.originalName}
                </a>
                <span className="mr-2 text-sm text-captionColor" data-testid="fileSize">
                  ({formatFileSize(file.size)})
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="rounded-md border border-borderGrey">
        <div className="bg-card p-2 dark:text-primaryTextColor" data-testid="subjectName">
          {feedback.subject.name}
        </div>
        <div className="p-3">
          <small className="flex text-captionColor" data-testid="date">
            <FontAwesomeIcon icon={ProRegularSvgIcons.faClock} className="mr-2 text-sm" />
            {format(parseISO(feedback.date), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
          </small>
          <p className="mt-1" data-testid="text">
            {feedback.text}
          </p>
        </div>
      </div>
      {feedback.commentDate !== null && (
        <div className="mt-3 rounded-md border border-borderGrey">
          <div className="bg-card p-2 dark:text-primaryTextColor">{t('system:administration')}</div>
          <div className="p-3">
            <small className="flex text-captionColor" data-testid="commentDate">
              <FontAwesomeIcon icon={ProRegularSvgIcons.faClock} className="mr-2 text-sm" />
              {format(parseISO(feedback.commentDate), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
            </small>
            <div dangerouslySetInnerHTML={{ __html: feedback.comment }} />
          </div>
        </div>
      )}
      <BackButton link={`/${locale}/student/feedbacks`} />
    </Section>
  )
}
