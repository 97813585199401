import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import CoursePartial from 'src/assets/icons/customIcons/course-icons/CoursePartial'
import CoursePending from 'src/assets/icons/customIcons/course-icons/CoursePending'
import Statements from 'src/assets/icons/customIcons/page-icons/Statements'
import { errorMessage, formatFileSize, shortenString } from 'src/helpers/fns'
import { useAllSearchParams, useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester, useAvailableSemesters } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileIcon from 'src/views/components/FileIcon'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'
import Select from 'src/views/components/Select'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'

export default function StudentStatementsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('statement:statements')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [searchParams, setSearchParams] = useSearchParams()
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const semesters = useAvailableSemesters()
  const allSearchParams = useAllSearchParams()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const page = searchParams.get('page')
  const query = searchParams.get('query')
  const semId = searchParams.get('semId')
  const perPage = searchParams.get('perPage')
  const activeSemester = useActiveSemester()
  const { pathname } = useLocation()
  const result = useApi({
    endpoint: Api.getStudentStatements,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            search: query != null && query.length > 2 ? query : null,
            semester: semId,
          },
          page: Number(page) ?? 1,
          perPage: Number(perPage),
        },
      }),
      [headers, page, query, semId, perPage]
    ),
  })
  const { data, meta } = result.data

  const activeSemesterId = searchParams.get('semId') ?? activeSemester?.id

  const [openStatement, setOpenStatement] = React.useState('0')

  const openStatementData: APIResponse<Api.getStudentStatementOk | null, Api.getStudentStatementErr> = useApi({
    endpoint: Api.getStudentStatement,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: openStatement,
          semId: activeSemesterId!,
        },
      }),
      [activeSemesterId, headers, openStatement]
    ),
    suspense: false,
    shouldFetch: openStatement !== '0',
  })

  if (semesters == null || activeSemester?.id == null) return null
  const currentSemester = semesters.find((semester) => semester.id === activeSemesterId)
  if (currentSemester == null) return null
  if (semesters == null || activeSemesterId == null) return null
  const pagination = <Pagination pagination={meta.pagination} />

  const breadcrumbsItems = [{ page: `${t('statement:statements')}`, path: pathname }]

  return (
    <Section
      title={t('statement:statements')}
      icon={<Statements />}
      rightElement={
        <Link to={`/${locale}/student/statements/add`} data-testid="addStatementButton">
          <Button className="flex items-center" variant="blue">
            <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} className="text-sm" />{' '}
            <span className="ml-2">{t('common:add')}</span>
          </Button>
        </Link>
      }
      breadcrubms={breadcrumbsItems}
    >
      <div className="mb-6 flex w-full justify-between border-b border-borderColor pb-6" data-testid="semesterSelect">
        <Select
          defaultValue={{ id: semId ?? activeSemester.id }}
          options={semesters.map((semester) => ({ id: semester.id }))}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ id }) => {
            const sem = semesters.find((semester) => semester.id === id)

            if (sem == null) return ''

            return sem.name + (sem.isActive ? t('common:current') : '')
          }}
          onChange={(opt) => {
            if (opt?.id == null) return

            setSearchParams({
              ...allSearchParams,
              semId: opt.id,
            })
          }}
          className="w-full"
        />
      </div>
      <Form
        initialValues={{ query: allSearchParams.query ?? '' }}
        onSubmit={async ({ query }) => {
          setSearchParams({ ...allSearchParams, query })
        }}
        classNames={{ form: 'mb-2 flex flex-wrap md:!flex-nowrap justify-between' }}
      >
        <div className="w-full">
          <TextInput
            type="text"
            name="query"
            data-testid="query"
            placeholder={t('statement:search_for_statement_number')}
            searchField
          />
        </div>
        <Button type="submit" variant="red" className="ml-0 w-[120px] md:ml-4" data-testid="searchButton">
          {t('common:search')}
        </Button>
      </Form>
      {data.length === 0 ? (
        <div className="mb-9">
          {semId === activeSemester.id || semId == null ? (
            <NoContent
              image={Warning}
              header={t('error:items_not_found')}
              subHeader={t('error:try_adjusting_your_search_or_change_the_semester')}
              marginTop="5"
              link="student/statements/add"
              linkText={t('statement:add_statement')}
            />
          ) : (
            <NoContent
              image={Warning}
              header={t('error:items_not_found')}
              subHeader={t('error:try_adjusting_your_search_or_change_the_semester')}
              marginTop="5"
            />
          )}
        </div>
      ) : (
        <>
          {pagination}
          <Table.Table className="mt-4">
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '24%' }}>{t('common:number')}</Table.Th>
                <Table.Th>{t('common:title')}</Table.Th>
                <Table.Th style={{ width: '18%' }}>{t('common:date')}</Table.Th>
                <Table.Th style={{ width: '10%' }}>{t('common:state')}</Table.Th>
                <Table.Th style={{ minWidth: '336px' }}>{t('discussion:comment')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.map((el) => (
                <Table.Tr
                  key={el.id}
                  className={
                    `border-b !border-tableBorderColor hover:bg-black/5` +
                    (el.status === 'STATUS_ACCEPTED'
                      ? 'bg-lightGreen'
                      : el.status === 'STATUS_DECLINED'
                        ? 'bg-lightRed'
                        : el.status === 'STATUS_PENDING'
                          ? 'bg-lightBlue'
                          : el.status === 'STATUS_PARTIAL'
                            ? 'bg-lightYellow'
                            : null)
                  }
                  data-testid={`statement/${el.id}`}
                >
                  <Table.Td>
                    <button
                      className="text-primaryBlueLink hover:underline"
                      onClick={() => setOpenStatement(el.id)}
                      data-testid="statementNumber"
                    >
                      {el.number}
                    </button>
                  </Table.Td>
                  <Table.Td style={{ minWidth: '300px' }} data-testid="courseCode">
                    {el.title}
                  </Table.Td>
                  <Table.Td data-testid="createdAt">
                    {format(parseISO(el.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
                  </Table.Td>
                  <Table.Td className="flex items-center" data-testid="programCode">
                    {el.status === 'STATUS_ACCEPTED' ? (
                      <CourseCompletedIcon />
                    ) : el.status === 'STATUS_DECLINED' ? (
                      <CourseFailedIcon />
                    ) : el.status === 'STATUS_PENDING' ? (
                      <CoursePending />
                    ) : (
                      <CoursePartial />
                    )}
                    <span className="ml-2" data-testid="courseStatus">
                      {el.statusText}
                    </span>
                  </Table.Td>

                  <Table.Td>
                    <StatementComment comment={el.comment} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
          {pagination}
        </>
      )}
      {openStatement !== '0' && (
        <Modal
          title={openStatementData.data?.title}
          isModalOpen={openStatement !== '0'}
          onClose={() => setOpenStatement('0')}
          height={800}
          width={1100}
          icon={<Statements />}
          testId="statementModal"
        >
          {openStatementData.data != null && openStatementData.error == null ? (
            <div className="statement-popup max-h-[57vh] overflow-y-scroll pr-[10px]">
              <div dangerouslySetInnerHTML={{ __html: openStatementData.data.body }} />
              {openStatementData.data.files != null && openStatementData.data.files.length > 0 && (
                <div className="row-auto">
                  <div className="col-auto">
                    <p className="mb-0 font-bold">{t('file:attached_files')}</p>
                  </div>
                  <div className="columns-1 pl-0">
                    {openStatementData.data?.files.map((file) => (
                      <span key={file.id} className="mr-2" data-testid={`file/${file.id}`}>
                        <FileIcon type={file.mediaFile.extension} />{' '}
                        <a
                          href={`/${locale}/media-files/${file.mediaFile.id}/download`}
                          rel="noreferrer"
                          target="_blank"
                          data-testid="downloadMaterialButton"
                        >
                          {file.mediaFile.originalName}
                        </a>{' '}
                        <span className="text-sm" data-testid="mediaFileSize">
                          ({formatFileSize(file.mediaFile.size)})
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : openStatementData.error != null ? (
            <span className="text-lightSecondaryWarning">{errorMessage(openStatementData.error)}</span>
          ) : null}
        </Modal>
      )}
    </Section>
  )
}

interface StatementProps {
  comment: string
}

const StatementComment: React.FC<StatementProps> = ({ comment }) => {
  const [more, setMore] = React.useState(false)

  return (
    <p className="mb-0 flex items-center">
      <span data-testid="comment">{more ? comment : shortenString(comment, 40)}</span>
      {comment.length > 40 && (
        <span
          className={classNames('!w-[11px] cursor-pointer', { 'rotate-[180deg]': more })}
          onClick={() => setMore(!more)}
          data-testid="commentMore"
        >
          <FontAwesomeIcon icon={ProRegularSvgIcons.faArrowCircleDown} className="ml-1" />
        </span>
      )}
    </p>
  )
}
